import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavigationBar from './NavigationBar';
import NodeView from '../components/NodeView';
import {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchContentNode,
  updateUser,
  viewingNode,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  fetchTimelineNodes,
  handleIsFirstMount,

} from '../actions';

import '../styles/style.scss';


class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingId: 1,
    };

    this.viewNode = this.viewNode.bind(this);
    this.timelineClickthrough = this.timelineClickthrough.bind(this);
    this.subscribeTimeline = this.subscribeTimeline.bind(this);
    this.renderLandingTimeline = this.renderLandingTimeline.bind(this);
    this.renderLandingTimelineHeader = this.renderLandingTimelineHeader.bind(this);
    this.renderLandingTimelineNodes = this.renderLandingTimelineNodes.bind(this);
    this.toggleLandingId = this.toggleLandingId.bind(this);
    this.handleLandingNavigation = this.handleLandingNavigation.bind(this);
  }
  componentDidMount() {
    this.props.handleIsFirstMount(true);
  }

  viewNode(node) {
    this.props.viewingNode(node);
  }

  handleLandingNavigation(navUp) {
    if (this.state.landingId > 0 && this.state.landingId <= 6) {
      if (navUp && this.state.landingId < 6) {
        this.setState({ landingId: this.state.landingId + 1 });
      } else if (!navUp && this.state.landingId > 1) {
        this.setState({ landingId: this.state.landingId - 1 });
      }
    }
  }

  subscribeTimeline() {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    updatedUser.permissionViewKeys.push(this.props.viewNodeObj._id);
    this.props.updateUser(updatedUser._id, updatedUser);
  }

  timelineClickthrough(node) {
    this.props.fetchTimelineNode(node._id);
  }
  toggleLandingId(Id) {
    this.setState({ landingId: Id });
  }

  renderLandingTimelineHeader() {
    return (
      <div className="timelineHeader">
        <div className="timelineTitleQuery">
          <p>
            What Is Record Atlas?
          </p>
        </div>
        <div className="timelineHeaderLine" />
        <div id="navigateButtonTimelineContainer">
          {this.state.landingId === 1 ?
            <button
              className="navigateButtonTimeline"
              id="arrowDisabled"
            ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
          :
            <button
              className="navigateButtonTimeline"
              onClick={() => { this.handleLandingNavigation(false); }}
            ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
          }
          {this.state.landingId === 6 ?
            <button
              className="navigateButtonTimeline"
              id="arrowDisabled"
            ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
            :
            <button
              className="navigateButtonTimeline"
              onClick={() => { this.handleLandingNavigation(true); }}
            ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
          }
        </div>
        <div id="nodePositionNavNumber">
          {this.state.landingId} of 6
        </div>

      </div>
    );
  }
  renderLandingTimelineNodes() {
    return (
      <div className="timelineNodesLanding">

        <div id="timelineNodesLeft">
          <div>
            <button
              value="Record Atlas"
              className="timelineNodePreviewButton"
              id={this.state.landingId === 1 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => this.toggleLandingId(1)}
            >
              <div className="nodeContainerContent">
                <p className="nodeContainerInfo"> Record Atlas Login</p>
                <div className="nodeContainerTitle">
                  <div > Record Atlas Login</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="landingTimelineImage"
                    src="/public/images/landingNodeContainerImages/RecordAtlasLanding.png"
                  />
                </div>

                <div
                  id="leftPreviewDate"
                  className="tNodeDate"
                >
                  <p>2018 Jun 18</p>
                </div>
              </div>

            </button>
            <div className="leftTimelineRootNodeConnectorCircle" />
            <div className="leftTimelineRootNodeConnectorDotLine" />
            <div className="leftTimelineRootNodeConnectorDotLine2" />
          </div>

          <div>
            <button
              value="Create"
              className="timelineNodePreviewButton"
              id={this.state.landingId === 3 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => this.toggleLandingId(3)}

            >
              <div className="nodeContainerContent">
                <p className="nodeContainerInfo"> Record Atlas</p>
                <div className="nodeContainerTitle">
                  <div >Create And Organize</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="landingTimelineImage"
                    src="/public/images/landingNodeContainerImages/CreateLanding.png"
                  />
                </div>

                <div
                  id="leftPreviewDate"
                  className="tNodeDate"
                >
                  <p>2018 Jun 18</p>
                </div>
              </div>

            </button>
            <div className="leftTimelineRootNodeConnectorCircle" />
            <div className="leftTimelineRootNodeConnectorDotLine" />
            <div className="leftTimelineRootNodeConnectorDotLine2" />
          </div>

          <div>
            <button
              value="Visual Essays"
              className="timelineNodePreviewButton"
              id={this.state.landingId === 5 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => this.toggleLandingId(5)}

            >
              <div className="nodeContainerContent">
                <p className="nodeContainerInfo"> Record Atlas</p>
                <div className="nodeContainerTitle">
                  <div >Visual Essays</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="landingTimelineImage"
                    src="/public/images/landingNodeContainerImages/VisualEssaysLanding.png"
                  />
                </div>

                <div
                  id="leftPreviewDate"
                  className="tNodeDate"
                >
                  <p>2018 Jun 18</p>
                </div>
              </div>

            </button>
            <div className="leftTimelineRootNodeConnectorCircle" />
            <div className="leftTimelineRootNodeConnectorDotLine" />
            <div className="leftTimelineRootNodeConnectorDotLine2" />
          </div>

        </div>
        <div id="timelineNodesRight">
          <div>
            <button
              value="Story"
              className="timelineNodePreviewButton"
              id={this.state.landingId === 2 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => this.toggleLandingId(2)}

            >
              <div className="nodeContainerContent">
                <p className="nodeContainerInfo"> Record Atlas</p>
                <div className="nodeContainerTitle">
                  <div >Story</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="landingTimelineImage"
                    src="/public/images/landingNodeContainerImages/StoryLanding.png"
                  />
                </div>

                <div
                  id="rightPreviewDate"
                  className="tNodeDate"
                >
                  <p>2018 Jun 18</p>
                </div>
              </div>
            </button>
            <div className="rightTimelineRootNodeConnectorCircle" />
            <div className="rightTimelineRootNodeConnectorDotLine" />
            <div className="rightTimelineRootNodeConnectorDotLine2" />
          </div>
          <div>
            <button
              value="Permissions"
              className="timelineNodePreviewButton"
              id={this.state.landingId === 4 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => this.toggleLandingId(4)}
            >
              <div className="nodeContainerContent">
                <p className="nodeContainerInfo"> Record Atlas</p>
                <div className="nodeContainerTitle">
                  <div >Permissions</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="landingTimelineImage"
                    src="/public/images/landingNodeContainerImages/PermissionsLanding.png"
                  />
                </div>

                <div
                  id="rightPreviewDate"
                  className="tNodeDate"
                >
                  <p>2018 Jun 18</p>
                </div>
              </div>
            </button>
            <div className="rightTimelineRootNodeConnectorCircle" />
            <div className="rightTimelineRootNodeConnectorDotLine" />
            <div className="rightTimelineRootNodeConnectorDotLine2" />
          </div>
          <div>
            <button
              value="Explore"
              className="timelineNodePreviewButton"
              id={this.state.landingId === 6 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => this.toggleLandingId(6)}
            >
              <div className="nodeContainerContent">
                <p className="nodeContainerInfo"> Record Atlas</p>
                <div className="nodeContainerTitle">
                  <div >Explore</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="landingTimelineImage"
                    src="/public/images/landingNodeContainerImages/ExploreLanding.png"
                  />
                </div>

                <div
                  id="rightPreviewDate"
                  className="tNodeDate"
                >
                  <p>2018 Jun 18</p>
                </div>
              </div>
            </button>
            <div className="rightTimelineRootNodeConnectorCircle" />
            <div className="rightTimelineRootNodeConnectorDotLine" />
            <div className="rightTimelineRootNodeConnectorDotLine2" />
          </div>
        </div>
      </div>

    );
  }

  renderLandingTimeline() {
    return (
      <div className="timelineViewBox">
        <div className="timelineRoot">
          <div className="timelineLineRectangle" />
          <div className="timelineLine" />
          <div className="timelineLineRectangle" />
        </div>
        <div className="triangleDown" />
        {this.renderLandingTimelineHeader()}
        {this.renderLandingTimelineNodes()}

      </div>
    );
  }

  render() {
    return (
      <div className="Page">
        <div className="pageGridV" >

          <div className="pageNav">
            <NavigationBar />
          </div>
          <div className="pageGridH" >

            <div className="pageGrid" >
              {this.renderLandingTimeline() }
            </div>
            <div className="pageGrid" id="pageGridMargin">
              <NodeView
                handleContentDeleteClick={this.handleContentDeleteClick}
                timelineNodeFeedDefault={this.props.timelineNodeFeedDefault}
                viewNodeObj={this.props.viewNodeObj}
                handleEditClick={this.handleEditClick}
                subscribeTimeline={this.subscribeTimeline}
                timelineNode={this.state.timelineNode !== undefined ? this.state.timelineNode : this.props.timelineNode}
                timelineClickthrough={this.timelineClickthrough}
                queryType={`Landing-${this.state.landingId}`}
                mount={this.props.mount}

              />

            </div>
          </div>
        </div>


      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    isEditing: state.nodes.isEditing,
    node: state.cNodes.node,
    viewNodeObj: state.nodes.viewNodeObj,
    timelineNode: state.tNodes.timelineNode,
    timelineNodes: state.tNodes.timelineNodes,
    mount: state.page.mount,

  }
);

export default withRouter(connect(mapStateToProps, {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchContentNode,
  viewingNode,
  updateUser,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  fetchTimelineNodes,
  handleIsFirstMount,
})(LandingPage));
