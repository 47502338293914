import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  findUsers,
  fetchUsers,
  updateTimelineNodePermissions,
} from '../../actions';
import '../../styles/style.scss';

class PermissionsNodeView extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchUsers(props.viewNodeObj.creatorKeys, 'Creator');
    this.props.fetchUsers(props.viewNodeObj.permissionAdminKeys, 'Admin');
    this.props.fetchUsers(props.viewNodeObj.permissionRequests, 'Request');
    this.props.fetchUsers(props.viewNodeObj.permissionEditKeys, 'Edit');
    this.props.fetchUsers(props.viewNodeObj.permissionViewKeys, 'View');

    this.state = {
      searchTerm: '',
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
    this.renderNodeButtonContainer = this.renderNodeButtonContainer.bind(this);
    this.renderPermissionsKeys = this.renderPermissionsKeys.bind(this);
    this.updatePermissionsView = this.updatePermissionsView.bind(this);
    this.renderPermissionsKeysView = this.renderPermissionsKeysView.bind(this);
  }


  onSearchChange(event) {
    this.setState({
      searchTerm: event.target.value,
    });
    this.props.findUsers(event.target.value);
  }

  onSearchClick(event) {
    this.props.findUsers(this.state.searchTerm);
    this.setState({
      searchTerm: '',
    });
  }

  updatePermissionsView() {
    this.props.fetchUsers(this.props.viewNodeObj.permissionAdminKeys, 'Admin');
    this.props.fetchUsers(this.props.viewNodeObj.permissionRequests, 'Request');
    this.props.fetchUsers(this.props.viewNodeObj.permissionEditKeys, 'Edit');
    this.props.fetchUsers(this.props.viewNodeObj.permissionViewKeys, 'View');
  }

  renderNodeButtonContainer(user) {
    return (
      <div id="userPermissionButtonContainer">
        <p > {user.username} </p>
        <div id="buttonContainer">
          <button id="userPermissionsButton"
            onClick={() => {
            this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Admin', user._id, user);
            this.updatePermissionsView();
        }}
          > Moderator
          </button>
          <button id="userPermissionsButton"
            onClick={() => {
            this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Edit', user._id, user);
            this.updatePermissionsView();
        }}
          > Contributor
          </button>
          <button id="userPermissionsButton"
            onClick={() => {
            this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'View', user._id, user);
            this.updatePermissionsView();
        }}
          > Viewer
          </button>
        </div>
      </div>
    );
  }


  renderPermissionsKeys() {
    return (
      <div>
        <div id="userPermissionKeyContainer">
          <p>Permissions Requests</p>
          {this.props.usersRequest.length === 0 ?
            <div id="userPermissionButtonContainer">
            There are currently 0 requests for this timeline.
            </div> :
          ''}
          {this.props.usersRequest === undefined ?
            '' :
            this.props.usersRequest.map((user) => {
            if (user !== undefined && user !== null) {
              return (
                <div>
                  {user === this.props.viewNodeObj.creatorKeys[0] ? '' :
                  <div id="userPermissionButtonContainer">
                    <p > {user.username} </p>
                    <div id="buttonContainer">
                      <button id="userPermissionsButton"
                        onClick={() => {
                        this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Admin', user._id, user);
                        this.updatePermissionsView();
                      }}
                      > Moderator
                      </button>
                      <button id="userPermissionsButton"
                        onClick={() => {
                        this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Edit', user._id, user);
                        this.updatePermissionsView();
                     }}
                      > Contributor
                      </button>
                      <button id="userPermissionsButton"
                        onClick={() => {
                        this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'View', user._id, user);
                        this.updatePermissionsView();
                     }}
                      > Viewer
                      </button>
                    </div>
                  </div>
                }
                </div>
            );
            }
            return user;
        }) }

        </div >
        <div id="userPermissionKeyContainer">
          <p>Creator</p>
          <div id="userPermissionButtonContainer"
            className="userPermissionsButtonSelected"
          >
            {this.props.usersCreator.map((user) => {
          return <p > {user.username} </p>;
        })}
          </div >

        </div >
        <div id="userPermissionKeyContainer">
          <p>Moderator</p>
          {this.props.usersAdmin.length === 0 ?
            <div id="userPermissionButtonContainer">
            There are currently 0 moderators for this timeline.
            </div> :
          ''}
          {this.props.usersAdmin.map((user) => {
            return (
              <div>
                {user._id === this.props.viewNodeObj.creatorKeys[0] ? '' :
                <div id="userPermissionButtonContainer">
                  <p > {user.username} </p>
                  <div id="buttonContainer">
                    <button id="userPermissionsButtonSelected"

                      onClick={() => {
                      this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Admin', user._id, user);
                      this.updatePermissionsView();
                  }}
                    > Moderator
                    </button>
                    <button id="userPermissionsButton"
                      onClick={() => {
                      this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Edit', user._id, user);
                      this.updatePermissionsView();
                  }}> Contributor
                    </button>
                    <button id="userPermissionsButton"
                      onClick={() => {
                      this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'View', user._id, user);
                      this.updatePermissionsView();
                  }}> Viewer
                    </button>
                  </div>
                </div>
              }
              </div>
          );
        })}
        </div >
        <div id="userPermissionKeyContainer">
          <p>Contributor</p>
          {this.props.usersEdit.length === 0 ?
            <div id="userPermissionButtonContainer">
              {this.props.viewNodeObj.openContributor ?
                <div> This is an Open Contributor Timeline. All users can contribute. </div>
              :
                <div> There are currently 0 contributors for this timeline. </div>

            }
            </div> :
          ''}
          {this.props.usersEdit.map((user) => {
            return (
              <div>
                {user._id === this.props.viewNodeObj.creatorKeys[0] ? '' :
                <div id="userPermissionButtonContainer">

                  <p > {user.username} </p>
                  <div id="buttonContainer">
                    <button id="userPermissionsButton"
                      onClick={() => {
                      this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Admin', user._id, user);
                      this.updatePermissionsView();
                  }}> Moderator
                    </button>
                    <button id="userPermissionsButtonSelected"
                      onClick={() => {
                      this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Edit', user._id, user);
                      this.updatePermissionsView();
                  }}> Contributor
                    </button>
                    <button id="userPermissionsButton"
                      onClick={() => {
                      this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'View', user._id, user);
                      this.updatePermissionsView();
                  }}> Viewer
                    </button>
                  </div>
                </div>
            }
              </div>
          );
        })}
        </div >
        <div id="userPermissionKeyContainer">
          <p>Viewer</p>
          {this.props.usersView.length === 0 ?
            <div id="userPermissionButtonContainer">
              {this.props.viewNodeObj.permissions ?
                <div>There are currently 0 allowed viewers for this timeline. </div>
              :
                <div> This is a public timeline.</div>
            }
            </div> :
          ''}

          {this.props.usersView.map((user) => {
            return (
              <div id="userPermissionButtonContainer">
                <p > {user.username} </p>
                <div id="buttonContainer">
                  <button id="userPermissionsButton"
                    onClick={() => {
                    this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Admin', user._id, user);
                    this.updatePermissionsView();
                }}> Moderator
                  </button>
                  <button id="userPermissionsButton"
                    onClick={() => {
                    this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'Edit', user._id, user);
                    this.updatePermissionsView();
                }}
                  > Contributor
                  </button>
                  <button id="userPermissionsButtonSelected"

                    onClick={() => {
                    this.props.updateTimelineNodePermissions(this.props.viewNodeObj._id, this.props.viewNodeObj, 'View', user._id, user);
                    this.updatePermissionsView();
                }}
                  > Viewer
                  </button>
                </div>
              </div>
          );
        })}
        </div >


      </div>
    );
  }

  renderPermissionsKeysView() {
    return (
      <div>
        <div id="userPermissionKeyContainer">
          <p>Creator</p>
          <div id="userPermissionButtonContainer"
            className="userPermissionsButtonSelected"
          >
            {this.props.usersCreator.map((user) => {
          return <p > {user.username} </p>;
        })}
          </div >

        </div >
        <div id="userPermissionKeyContainer">
          <p>Moderator</p>
          {this.props.usersAdmin.length === 0 ?
            <div id="userPermissionButtonContainer">
            There are currently 0 moderators for this timeline.
            </div> :
          ''}
          {this.props.usersAdmin.map((user) => {
            return (
              <div>
                <div id="userPermissionButtonContainer">
                  <p > {user.username} </p>
                  <div id="buttonContainer">
                    <div id="userPermissionsButtonSelected"> Moderator
                    </div>
                    <div id="userPermissionsButtonView"> Contributor
                    </div>
                    <div id="userPermissionsButtonView"> Viewer
                    </div>
                  </div>
                </div>
              </div>
          );
        })}
        </div >
        <div id="userPermissionKeyContainer">
          <p>Contributor</p>
          {this.props.usersEdit.length === 0 ?
            <div id="userPermissionButtonContainer">
            There are currently 0 contributors for this timeline.
            </div> :
          ''}
          {this.props.usersEdit.map((user) => {
            return (
              <div>
                <div id="userPermissionButtonContainer">

                  <p > {user.username} </p>
                  <div id="buttonContainer">
                    <div id="userPermissionsButtonView"> Moderator
                    </div>
                    <div id="userPermissionsButtonSelected"> Contributor
                    </div>
                    <div id="userPermissionsButtonView"> Viewer
                    </div>
                  </div>
                </div>
              </div>
          );
        })}
        </div >
        <div id="userPermissionKeyContainer">
          <p>Viewer</p>
          {this.props.usersView.length === 0 ?
            <div id="userPermissionButtonContainer">
              {this.props.viewNodeObj.permissions ?
                <div>There are currently 0 allowed viewers for this timeline. </div>
              :
                <div> This is a public timeline.</div>
            }
            </div> :
          ''}

          {this.props.usersView.map((user) => {
            return (
              <div id="userPermissionButtonContainer">
                <p > {user.username} </p>
                <div id="buttonContainer">
                  <div id="userPermissionsButtonView"> Moderator
                  </div>
                  <div id="userPermissionsButtonView"> Contributor
                  </div>
                  <div id="userPermissionsButtonSelected"> Viewer
                  </div>
                </div>
              </div>
          );
        })}
        </div >


      </div>
    );
  }

  renderLandingNodeViewContent() {
    const userTemp = localStorage.getItem('user');
    const updatedUser = JSON.parse(userTemp);
    let permissionsUpdate = false;
    if (this.props.viewNodeObj.permissionAdminKeys.indexOf(updatedUser._id) !== -1 || this.props.viewNodeObj.creatorKeys.indexOf(updatedUser._id) !== -1) {
      permissionsUpdate = true;
    }
    return (
      <div className="contentQueryNodeView">
        <div id="permissionsNodeViewHeaderContainer" className="permissionsNodeViewHeader">
          <div id="permissionsNodeViewHeaderDetail">01</div>
          <div id="permissionsNodeViewHeader">permissions</div>
        </div>
        {permissionsUpdate ?
          <div id="permissionsSearchContainer">
            <input id="permissionsSearchInput" placeholder="Invite User By Username ..." value={this.state.searchTerm} onChange={this.onSearchChange} />
            <button
              className="searchPermissionsIcon"
              onClick={(event) => {
                this.onSearchClick(event);
              }}
            ><img id="searchIcon" alt="search-Icon" src="/public/images/magnifying-glass.png" />
            </button>
          </div>
        : ''}
        <div id="permissionsNodeViewInfoContainer">

          <div id="permissionsNodeViewInfoContent">
            {this.props.searchedUsers.map((user) => {
             return this.renderNodeButtonContainer(user);
            })}
            {permissionsUpdate ? this.renderPermissionsKeys() : this.renderPermissionsKeysView()}

          </div>
        </div>

      </div>
    );
  }


  render() {
    return (
      <div id="PermissionsNodeViewContainer">
        <div className="line-containerPermissionsNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        <div >
          { this.renderLandingNodeViewContent()}
        </div>
      </div>
    );
  }
}

// // connects particular parts of redux state to this components props
const mapStateToProps = state => (
  {
    searchedUsers: state.user.users,
    usersRequest: state.user.usersRequest,
    usersAdmin: state.user.usersAdmin,
    usersCreator: state.user.usersCreator,
    usersEdit: state.user.usersEdit,
    usersView: state.user.usersView,
  }
);


export default withRouter(connect(mapStateToProps, {
  findUsers,
  fetchUsers,

  updateTimelineNodePermissions,

})(PermissionsNodeView));
