import { ActionTypes } from '../actions';

const initialState = {
  user: [],
  users: [],
  usersRequest: [],
  usersAdmin: [],
  usersCreator: [],
  usersEdit: [],
  usersView: [],
  userPoints: 0,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_USER:
      return { ...state, user: action.payload.cleanedUser };
    case ActionTypes.FETCH_USER_POINTS:
      return { ...state, userPoints: action.payload };
    case ActionTypes.FIND_USERS:
      return { ...state, users: action.payload };
    case ActionTypes.UPDATE_USER:
      return { ...state, user: action.payload };
    case ActionTypes.FETCH_USERS_REQUEST_TYPE:
      return { ...state, usersRequest: action.payload };
    case ActionTypes.FETCH_USERS_ADMIN_TYPE:
      return { ...state, usersAdmin: action.payload };
    case ActionTypes.FETCH_USERS_CREATOR_TYPE:
      return { ...state, usersCreator: action.payload };
    case ActionTypes.FETCH_USERS_EDIT_TYPE:
      return { ...state, usersEdit: action.payload };
    case ActionTypes.FETCH_USERS_VIEW_TYPE:
      return { ...state, usersView: action.payload };

    default:
      return state;
  }
};

export default UserReducer;
