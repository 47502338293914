import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavigationBar from './NavigationBar';
import TimelineView from '../components/TimelineView';
import NodeView from '../components/NodeView';
import NodeUpload from '../components/NodeUpload';

import {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchContentNode,
  updateUser,
  viewingNode,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  fetchTimelineNodes,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
  handleIsFirstMount,
  updateTimelineNodePermissionsRequests,
  createContentNode,
  createTimelineNode,
  updateTimelineNode,
  updateContentNode,
  tokenVote,
  editNodeCancel,
  fetchUnAuthTimelineNode,
  fetchUserPoints,


} from '../actions';

import '../styles/style.scss';


class QueryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: 0,
      permissionsNodeView: false,
      queryType: 1,
      nodeFromTimeline: 'none',
      selectedTimelinesObj: [],
      masterViewSelected: true,
      mount: props.mount,
    };
    this.handleContentDeleteClick = this.handleContentDeleteClick.bind(this);
    this.handleTimelineDeleteClick = this.handleTimelineDeleteClick.bind(this);
    this.viewNode = this.viewNode.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);

    this.timelineClickthrough = this.timelineClickthrough.bind(this);
    this.subscribeTimeline = this.subscribeTimeline.bind(this);
    this.unSubscribeTimeline = this.unSubscribeTimeline.bind(this);
    this.handleNodePosition = this.handleNodePosition.bind(this);
    this.handlePermissionsNodeView = this.handlePermissionsNodeView.bind(this);
    this.handleQueryTypeToggle = this.handleQueryTypeToggle.bind(this);
    this.createNode = this.createNode.bind(this);
    this.updateNode = this.updateNode.bind(this);
    this.createNodeFromTimeline = this.createNodeFromTimeline.bind(this);
    this.handleNodeFromTimelineCancel = this.handleNodeFromTimelineCancel.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.query === 'Profile') {
      this.props.fetchSpecificTimelineNodes('Creator');
      this.props.fetchUserPoints();
    } else if (this.props.match.params.query === 'Feed') {
      this.props.fetchSpecificTimelineNodes('View');
    }
    this.props.handleTimelineHistory(this.props.match.params.query, undefined);
  }

  componentWillReceiveProps(props) {
    this.setState({ mount: props.mount });

    const tempAlbumTNodeArray = {};
    if (props.match.params.query === 'Profile') {
      this.props.handleTimelineHistory(props.match.params.query, undefined);
      if (props.timelineNodes !== undefined) {
        tempAlbumTNodeArray.nodes = [];
        if (this.state.queryType === 5) {
          // queryType 5 is for media nodes/ contentNodes
          props.timelineNodes.map((cNode) => {
            return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: cNode }));
          });
        } else if (this.state.queryType === 4) {
          props.timelineNodes.map((tNode) => {
            return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
          });
        } else {
          props.timelineNodes.map((tNode) => {
            if (tNode.album !== undefined && tNode.album) {
              return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
            }
            return tNode._id;
          });
        }

        tempAlbumTNodeArray.title = 'Profile';
        tempAlbumTNodeArray._id = 'Profile';
        this.setState({
          timelineNode: tempAlbumTNodeArray,
        });
      }
    } else if (props.match.params.query === 'Feed') {
      this.props.handleTimelineHistory(props.match.params.query, undefined);

      if (props.timelineNodes !== undefined) {
        tempAlbumTNodeArray.nodes = [];
        props.timelineNodes.map((tNode) => {
          if (tNode.album !== undefined && tNode.album) {
            return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
          }
          return tNode._id;
        });
        tempAlbumTNodeArray.title = 'Feed';
        tempAlbumTNodeArray._id = 'Feed';
        this.setState({
          timelineNode: tempAlbumTNodeArray,
        });
      }
    } else {
      this.setState({
        timelineNode: this.props.timelineNode,
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.match.params.query !== this.props.match.params.query) {
      if (nextProps.match.params.query === 'Profile') {
        if (this.state.queryType === 1) {
          nextProps.fetchSpecificTimelineNodes('Creator');
        } else if (this.state.queryType === 4) {
          nextProps.fetchSpecificTimelineNodes('creatorTNodes');
        } else if (this.state.queryType === 5) {
          nextProps.fetchSpecificTimelineNodes('creatorCNodes');
        }
      } else if (nextProps.match.params.query === 'Feed') {
        nextProps.fetchSpecificTimelineNodes('View');
      }
    }
  }

  handlePermissionsNodeView(bool) {
    this.setState({ permissionsNodeView: bool });
  }

  handleEditClick(node) {
    this.props.editNode(node);
  }

  handleRemoveClick(node) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    let permissionsIndex;
    permissionsIndex = updatedUser.creatorKeys.indexOf(node._id);

    if (this.state.queryType === 1) {
      const permissionsIndexTNodes = updatedUser.creatorTNodes.indexOf(node._id);
      const permissionsIndexCKeys = updatedUser.creatorKeys.indexOf(node._id);

      if (permissionsIndexTNodes > -1) {
        updatedUser.creatorTNodes.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }
      if (permissionsIndexCKeys > -1) {
        updatedUser.creatorKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }
      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, true);
        this.props.viewingNode(this.props.timelineNode);
        this.props.fetchSpecificTimelineNodes('Creator');
      }
    } else if (this.state.queryType === 2) {
      permissionsIndex = updatedUser.permissionAdminKeys.indexOf(node._id);
      if (permissionsIndex > -1) {
        updatedUser.permissionAdminKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }

      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, true);
        this.props.viewingNode(this.props.timelineNode);
      }
    } else if (this.state.queryType === 3) {
      permissionsIndex = updatedUser.permissionEditKeys.indexOf(node._id);
      if (permissionsIndex > -1) {
        updatedUser.permissionEditKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }
      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, true);
        this.props.viewingNode(this.props.timelineNode);
      }
    } else if (this.state.queryType === 4) {
      const permissionsIndexTNodes = updatedUser.creatorTNodes.indexOf(node._id);
      const permissionsIndexCKeys = updatedUser.creatorKeys.indexOf(node._id);

      if (permissionsIndexTNodes > -1) {
        updatedUser.creatorTNodes.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }
      if (permissionsIndexCKeys > -1) {
        updatedUser.creatorKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }
      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, false, 4);
        this.props.viewingNode(this.props.timelineNode);
        this.props.fetchSpecificTimelineNodes('creatorTNodes');
      }
    } else if (this.state.queryType === 5) {
      const permissionsIndexCNodes = updatedUser.creatorCNodes.indexOf(node._id);

      if (permissionsIndexCNodes > -1) {
        updatedUser.creatorCNodes.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }

      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, false, 5);
        this.props.viewingNode(this.props.timelineNode);
        this.props.fetchSpecificTimelineNodes('creatorCNodes');
      }
    }
    this.props.handleIsFirstMount(true);
  }

  handleEditCancel() {
    this.props.editNodeCancel();
    this.props.fetchContentNode(this.props.viewNodeObj._id);
  }
  handleNodeFromTimelineCancel() {
    this.setState({ nodeFromTimeline: 'none' });
    this.props.fetchContentNode(this.props.viewNodeObj._id);
  }
  handleContentDeleteClick(event) {
    this.props.deleteContentNode(this.props.contentNode.id, this.props.history);
  }

  handleTimelineDeleteClick(event) {
    console.log('handleTimelineDeleteClick was called on', this.state.timelineNode.title);
  }


  viewNode(node) {
    this.props.viewingNode(node);
  }


  createNode(node) {
    if (node.type === 'Timeline') {
      this.props.createTimelineNode(node, this.props.history, this.state.selectedTimelinesObj, this.props.timelineNode._id, this.state.queryType);
    } else {
      this.props.createContentNode(node, this.props.history, this.state.selectedTimelinesObj, this.props.timelineNode._id);
    }
    this.setState({ nodeFromTimeline: 'none' });
  }

  createNodeFromTimeline(nodeType) {
    if (nodeType === 'Timeline') {
      this.setState({ nodeFromTimeline: 'Timeline' });
    } else if (nodeType === 'Content') {
      this.setState({ nodeFromTimeline: 'Content' });
    }

    const tempObj = [];
    const temp = [];
    if (!this.state.masterViewSelected) {
      temp.push(this.props.timelineNode._id);
      tempObj.push(this.props.timelineNode);
    }
    this.setState({
      selectedTimelinesObj: tempObj,
    });
  }

  updateNode(node) {
    if (this.props.eNode.permissions === true || this.props.eNode.permissions === false) {
      this.props.updateTimelineNode(this.props.eNode._id, node, null, this.props.timelineNode._id);
    } else {
      this.props.updateContentNode(this.props.eNode._id, node, this.props.timelineNode._id);
    }
    if (this.state.queryType === 1) {
      this.props.fetchSpecificTimelineNodes('Creator');
    } else if (this.state.queryType === 2) {
      this.props.fetchSpecificTimelineNodes('Admin');
    } else if (this.state.queryType === 3) {
      this.props.fetchSpecificTimelineNodes('Edit');
    }
    this.handleEditCancel();
  }

  subscribeTimeline(nodeId) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    updatedUser.permissionViewKeys.push(nodeId);
    if (this.props.match.params.query === 'Feed') {
      this.props.updateUser(updatedUser._id, updatedUser, false, true, true);
    } else {
      this.props.updateUser(updatedUser._id, updatedUser, false, false, true);
    }
  }
  unSubscribeTimeline(node) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    const permissionsIndex = updatedUser.permissionViewKeys.indexOf(node._id);
    if (permissionsIndex > -1) {
      updatedUser.permissionViewKeys.splice(permissionsIndex, 1);
      this.props.updateUser(updatedUser._id, updatedUser, false);
    }
    if (this.props.match.params.query === 'Feed') {
      this.props.updateUser(updatedUser._id, updatedUser, false, true, true);
    } else {
      this.props.updateUser(updatedUser._id, updatedUser, false, false, true);
    }
  }

  timelineClickthrough(node) {
    if (this.props.auth) {
      this.props.fetchTimelineNode(node._id);
    } else {
      this.props.fetchUnAuthTimelineNode(node._id);
    }
  }

  handleNodePosition(nodePosition) {
    this.setState({ position: nodePosition });
  }
  handleQueryTypeToggle(queryType) {
    this.setState({ queryType });
  }

  render() {
    return (
      <div className="Page">
        <div className="pageGridV" >

          <div className="pageNav">
            <NavigationBar />
          </div>
          <div className="pageGridH" >

            <div className="pageGrid" >
              <TimelineView
                timelineNode={this.state.timelineNode !== undefined ? this.state.timelineNode : this.props.timelineNode}
                handleDeleteClick={this.handleTimelineDeleteClick}
                handleEditClick={this.handleTimelineEditClick}
                viewNode={this.viewNode}
                timelineLine="timelineLine"
                timelineViewBox="timelineViewBox"
                timelineNodesBox="timelineNodes"
                page="Profile"
                uploadToggle="Hidden"
                query
                queryType={this.props.match.params.query}
                handleTimelineHistory={this.props.handleTimelineHistory}
                handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                timelineHistory={this.props.timelineHistory}
                timelineHistoryIndex={this.props.timelineHistoryIndex}
                viewNodeObj={this.props.viewNodeObj}
                contentID={this.props.match.params.contentID}
                handleIsFirstMount={this.props.handleIsFirstMount}
                mount={this.state.mount}
                handleNodePosition={this.handleNodePosition}
                nodePosition={this.state.position + 1}
                permissionsNodeView={this.state.permissionsNodeView}
                handlePermissionsNodeView={this.handlePermissionsNodeView}
                queryContributorModeratorType={this.state.queryType}
                auth={this.props.auth}
                editNodeCancel={this.props.editNodeCancel}
                createNodeFromProfileTimeline={this.createNodeFromTimeline}
              />
            </div>
            <div className="pageGrid" id="pageGridMargin">
              {this.props.isEditing || this.state.nodeFromTimeline !== 'none' ?
                <div id="uploadNodePageSection" >

                  <NodeUpload
                    createNode={this.createNode}
                    isEditing={this.props.isEditing}
                    handleEditClick={this.handleEditClick}
                    handleEditCancel={this.handleEditCancel}
                    updateNode={this.updateNode}
                    eNode={this.props.eNode}
                    nodeFromTimeline={this.state.nodeFromTimeline}
                    handleNodeFromTimelineCancel={this.handleNodeFromTimelineCancel}
                    timelineNode={this.props.timelineNode}
                    viewNode={this.props.viewingNode}
                    queryType="node"
                    masterSelected

                  />
                </div>
        :

                <NodeView
                  handleContentDeleteClick={this.handleContentDeleteClick}
                  timelineNodeFeedDefault={this.props.timelineNodeFeedDefault}
                  viewNodeObj={this.props.viewNodeObj}
                  subscribeTimeline={this.subscribeTimeline}
                  unSubscribeTimeline={this.unSubscribeTimeline}
                  timelineNode={this.state.timelineNode !== undefined ? this.state.timelineNode : this.props.timelineNode}
                  timelineClickthrough={this.timelineClickthrough}
                  queryType={this.props.match.params.query}
                  handleTimelineHistory={this.props.handleTimelineHistory}
                  handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                  mount={this.state.mount}
                  auth={this.props.auth}
                  viewing={false}
                  nodePosition={this.state.position}
                  updateTimelineNodePermissionsRequests={this.props.updateTimelineNodePermissionsRequests}
                  fetchSpecificTimelineNodes={this.props.fetchSpecificTimelineNodes}
                  permissionsNodeView={this.state.permissionsNodeView}
                  handlePermissionsNodeView={this.handlePermissionsNodeView}
                  handleQueryTypeToggle={this.handleQueryTypeToggle}
                  queryContributorModeratorType={this.state.queryType}
                  openContributorTNodeArray={this.state.tempOpenContributorTNodeArray}
                  closedContributorTNodeArray={this.state.tempClosedContributorTNodeArray}
                  tokenVote={this.props.tokenVote}
                  viewNode={this.props.viewingNode}
                  createNode={this.createNode}
                  isEditing={this.props.isEditing}
                  handleEditClick={this.handleEditClick}
                  handleEditCancel={this.handleEditCancel}
                  updateNode={this.updateNode}
                  eNode={this.props.eNode}
                  nodeFromTimeline={this.state.nodeFromTimeline}
                  handleNodeFromTimelineCancel={this.handleNodeFromTimelineCancel}
                  handleRemoveClick={this.handleRemoveClick}

                />
            }

            </div>
          </div>
        </div>


      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    isEditing: state.nodes.isEditing,
    node: state.cNodes.node,
    viewNodeObj: state.nodes.viewNodeObj,
    timelineNode: state.tNodes.timelineNode,
    timelineNodes: state.tNodes.timelineNodes,
    timelineHistory: state.tNodes.timelineHistory,
    timelineHistoryIndex: state.tNodes.timelineHistoryIndex,
    mount: state.page.mount,
    auth: state.auth.authenticated,
    user: state.user.user,
    eNode: state.nodes.eNode,


  }
);

export default withRouter(connect(mapStateToProps, {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchContentNode,
  viewingNode,
  updateUser,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  fetchTimelineNodes,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
  handleIsFirstMount,
  updateTimelineNodePermissionsRequests,
  createContentNode,
  createTimelineNode,
  updateTimelineNode,
  updateContentNode,
  tokenVote,
  editNodeCancel,
  fetchUnAuthTimelineNode,
  fetchUserPoints,

})(QueryPage));
