import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { findTNodes } from '../../actions';
import '../../styles/style.scss';

class ExploreNodeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchTerm: '',
    };
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
    this.renderExploreNodeViewContributorNode = this.renderExploreNodeViewContributorNode.bind(this);
  }

  onSearchChange(event) {
    this.setState({
      searchTerm: event.target.value,
    });
  }

  onSearchClick(event) {
    const tagList = ['Story', 'Project', 'History', 'Album', 'Documentation', 'Art'];
    const subject = tagList[this.props.exploreSubType - 1];
    this.props.findTNodes(this.state.searchTerm, subject);
    this.setState({
      searchTerm: '',
    });
  }

  renderExploreNodeViewContributorNode(node, largeBool) {
    let updatedUser = {};

    if (this.props.auth) {
      const user = localStorage.getItem('user');
      updatedUser = JSON.parse(user);
    }
    return (
      <NavLink to={`/n/${node._id}/${node._id}`}>
        <button
          id={largeBool ? 'exploreNodeViewContributorNodeLarge' : 'exploreNodeViewContributorNode'}
          value={this.props.node}
          onClick={() => {
      this.props.timelineClickthrough(node);
      this.props.handleTimelineHistoryIndex(1);
      this.props.handleTimelineHistory('node', node._id);
     }}
        >
          <div id="exploreNodeViewContributorNodeContent">
            <div id="exploreNodeViewContributorButtonContainer">
              <div id="exploreNodeViewContributorUpvoteContainer">
                <button
                  onClick={() => { this.props.tokenVote(true, node, 'Timeline'); }}
                > <img className="voteArrow" alt="nav-Arrow" src={node.upToken !== undefined && node.upToken.indexOf(updatedUser._id) > -1 ? '/public/images/nav-Arrow-Yellow.png' : '/public/images/nav-Arrow-Black.png'} />
                </button>
                {node.tokenCount !== undefined ? node.tokenCount : 0}
                <button
                  onClick={() => { this.props.tokenVote(false, node, 'Timeline'); }}
                > <img className="voteArrow" id="arrowLeft" alt="nav-Arrow" src={node.downToken !== undefined && node.downToken.indexOf(updatedUser._id) > -1 ? '/public/images/nav-Arrow-Yellow.png' : '/public/images/nav-Arrow-Black.png'} />
                </button>
              </div>
              <div id={largeBool ? 'contributorNodeLargePreview' : 'contributorNodePreview'}>
                <img id="contributorNodeImage" alt="node-src"src={node.imageFile !== '' && node.imageFile !== undefined ? node.imageFile : '/public/images/imageDefault.jpg'} />
              </div>

            </div>
            <div>
              <div id="exploreNodeViewContributorNodeInfo">
                <p>{node.title}</p>
                <div>
                  <p id="nodeUsername">{moment(node.updatedAt).fromNow()} by {node.recentContributor !== undefined ? node.recentContributor : `${node.author.username} (${node.author.cNodePoints + node.author.tNodePoints})`} </p>
                </div>
              </div>
            </div>
          </div>
          <NavLink to={`/n/${node._id}/${node._id}`}>
            <button
              className="exploreNodeViewContributorNodeClickthrough"
              value={this.props.node}
            >
              {node.openContributor ? 'Open Source' : 'View' }
            </button>
          </NavLink>
        </button>
      </NavLink>

    );
  }

  renderLandingNodeViewContent() {
    const tagList = ['Stories', 'Projects', 'Histories', 'Albums', 'Documentation', 'Art'];
    const subject = tagList[this.props.exploreSubType - 1];
    const rowSectionCount = this.props.tempAlbumTNodeArray !== undefined ? (Math.floor(this.props.tempAlbumTNodeArray.length % 9 !== 0) ? Math.floor(this.props.tempAlbumTNodeArray.length / 9) + 1 : this.props.tempAlbumTNodeArray.length / 9) : 0;
    // const rowSectionCountExcess = this.props.tempAlbumTNodeArray !== undefined ? this.props.tempAlbumTNodeArray.length % 9 : 0;


    // const rowCount = this.props.tempAlbumTNodeArray !== undefined ? (Math.floor(this.props.tempAlbumTNodeArray.length % 4 !== 0) ? (this.props.tempAlbumTNodeArray.length / 4) + 1 : this.props.tempAlbumTNodeArray.length / 4) : 0;

    // {_.times(rowCount, rowIndex => (
    //   <div id="ExploreNodesRow" key={rowIndex}>
    //     {this.props.tempAlbumTNodeArray[rowIndex * 4] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[rowIndex * 4]) : ''}
    //     {this.props.tempAlbumTNodeArray[(rowIndex * 4) + 1] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowIndex * 4) + 1]) : ''}
    //     {this.props.tempAlbumTNodeArray[(rowIndex * 4) + 2] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowIndex * 4) + 2]) : ''}
    //     {this.props.tempAlbumTNodeArray[(rowIndex * 4) + 3] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowIndex * 4) + 3]) : ''}
    //   </div>))}

    // <div id="explorePageOnboardText">
    //   Explore the {subject} created and curated by the Record Atlas community.
    // </div>
    // <div id="exploreNodeViewHeaderDescription"> Explore other subjects by clicking on the subject boxes to the left. </div>
    // <p className="ExploreNodeViewContributorNodesContainerTitle"> {subject }: Most Popular Timelines </p>

    return (
      <div className="exploreQueryNodeView">

        <div className="ExploreNodeViewContributorNodesContainer">
          <div className="ExploreNodeViewContributorNodesOverflowContainer">
            <div id="ExploreNodesQueryHeader">

              <div id="exploreNodeViewHeaderContainer">
                <div id="exploreNodeViewHeader">
                  <div id="exploreNodeViewHeaderHead">{subject}</div>
                  <div id="exploreNodeViewHeaderSub">Of Muses and Mistakes</div>

                  <div id="exploreNodeViewHeaderDesc" >
                    Record Atlas is a public knowledge project that shares the stories from different people and groups around the world. Navigate and explore Record Atlas for the stories that interest you. Explore the curious to inspire the wandering mind.
                  </div>
                  <div id="ExploreNodeViewInfoContent">
                    <div id="exploreSearchContainer">
                      <input id="exploreSearchInput" placeholder={`Search Record Atlas ${subject} By ...`} value={this.state.searchTerm} onChange={this.onSearchChange} />
                      <button
                        className="searchExploreIcon"
                        onClick={(event) => {
                           this.onSearchClick(event);
                          }}
                      ><img id="searchIcon" alt="search-Icon" src="/public/images/magnifying-glass.png" />
                      </button>
                    </div>
                  </div>
                </div>

              </div>

              <img
                id="exploreNodeViewHeaderImage"
                alt="exploreTimelineImage"
                src={`/public/images/exploreHeaderImages/ExploreHeader${subject}.jpg`}
              />

            </div>
            {_.times(rowSectionCount, rowSectionIndex => (
              <div id="ExploreNodesRowSection" key={rowSectionIndex}>
                <div id="ExploreNodesRowFour">
                  {this.props.tempAlbumTNodeArray[rowSectionIndex * 9] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[rowSectionIndex * 9]) : ''}
                  {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 1] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 1]) : ''}
                  {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 2] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 2]) : ''}
                  {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 3] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 3]) : ''}
                </div>
                <div id="ExploreNodesRowFive">
                  {rowSectionIndex % 2 !== 0 ?
                    <div id="ExploreNodesRowFiveBox">
                      <div>
                        <div id="ExploreNodesRowFiveBoxRow">
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 4] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 4]) : ''}
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 5] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 5]) : ''}
                        </div>
                        <div id="ExploreNodesRowFiveBoxRow">
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 7] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 7]) : ''}
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 8] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 8]) : ''}
                        </div>
                      </div>
                      {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 6] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 6], true) : ''}

                    </div>
                  :
                    <div id="ExploreNodesRowFiveBox">
                      {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 4] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 4], true) : ''}
                      <div>
                        <div id="ExploreNodesRowFiveBoxRow">
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 5] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 5]) : ''}
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 6] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 6]) : ''}
                        </div>
                        <div id="ExploreNodesRowFiveBoxRow">
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 7] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 7]) : ''}
                          {this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 8] !== undefined ? this.renderExploreNodeViewContributorNode(this.props.tempAlbumTNodeArray[(rowSectionIndex * 9) + 8]) : ''}
                        </div>
                      </div>

                    </div>
                }

                </div>

              </div>
            ))}
          </div>
        </div>

      </div>
    );
  }

  // <div id="explorePageOnboardText">
  // Open Souce Timelines allow any user to contribute to the knowledge base. Select a timeline you are interested in and help add to the story. You will accumulate points for the quality of your contributions.
  // </div>
  // <div className="ExploreNodeViewContributorNodesContainer">
  //   <p className="ExploreNodeViewContributorNodesContainerTitle"> {subject }: Most Popular Open Source Timelines </p>
  //   <div className="ExploreNodeViewContributorNodesOverflowContainer">
  //     {this.props.openContributorTNodeArray !== undefined ? this.props.openContributorTNodeArray.map((tNode) => {
  //       return this.renderExploreNodeViewContributorNode(tNode);
  //     })
  //     : ''}
  //   </div>
  // </div>


  //
  // <div className="line-containerExploreNodeView">
  //   <div className="lineQueryNodeView" />
  //   <div className="lineQueryNodeView" />
  //   <div className="lineQueryNodeView" />
  //   <div className="lineQueryNodeView" />
  //   <div className="lineQueryNodeView" />
  //   <div className="lineQueryNodeView" />
  //   <div className="lineQueryNodeView" />
  // </div>
  render() {
    return (
      <div className="ExploreNodeViewContainer">

        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

// // connects particular parts of redux state to this components props
const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,

    timelineNodes: state.tNodes.timelineNodes,
  }
);


export default withRouter(connect(mapStateToProps, {
  findTNodes,
})(ExploreNodeView));
