import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { ReactTypeformEmbed } from 'react-typeform-embed';

import 'react-toastify/dist/ReactToastify.css';

import '../styles/style.scss';

import {
  signoutUser,
  handleIsFirstMount,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
} from '../actions';


class NavigationBar extends Component {
  constructor(props) {
    super(props);

    // binding
    this.onSignOutClick = this.onSignOutClick.bind(this);
    this.openForm = this.openForm.bind(this);
  }

  onSignOutClick(event) {
    event.preventDefault();
    this.props.signoutUser(this.props.history);
  }
  notify = () => toast.warning('Please Sign Up Or Login To Access This Page')

  openForm() {
    this.typeformEmbed.typeform.open();
  }

  renderSignInOut() {
    if (this.props.auth) {
      return <button className="signUpOutButton" onClick={this.onSignOutClick}>Sign Out</button>;
    } else {
      return <NavLink className="signUpOutButton" id="signInButton" to="/" onClick={this.onSignOutClick} exact>Sign Up</NavLink>;
    }
  }

  // console.log(`auth is ${this.props.auth}`);

  // <NavLink to={`/Feed/${this.props.timelineNode._id}/${this.props.timelineNode._id}`} className="navBarItem" >Feed</NavLink>
  // <NavLink to={`/Profile/${this.props.timelineNode._id}/${this.props.timelineNode._id}`} className="navBarItem" >Profile</NavLink>

  render() {
    return (

      <div className="navBar">
        <div className="navLogo">
          <NavLink to="/" exact><img alt="logo-icon" src="/public/images/logoYellow.png" /></NavLink >
          <div id="atlasLogoText">
            Record Atlas
            <div id="atlasVersion">
              Beta v
              <div id="atlasVersionNumber">1.3</div>
            </div>
          </div>
        </div>
        <ReactTypeformEmbed
          popup
          url="https://recordatlas.typeform.com/to/sLqjT8"
          ref={(tf => this.typeformEmbed = tf)}
          style={{
            position: 'absolute', display: 'hidden', height: 0, width: 0,
          }}
        />
        {this.props.auth ?
          <div className="navBarCenter">
            <ToastContainer toastClassName="toast-primary-background" bodyClassName="toast-font-size" progressClassName="toast-progress-bar" />

            <NavLink onClick={() => {
              this.props.handleIsFirstMount(true);
              this.props.handleTimelineHistoryIndex(0, true);
              this.props.handleTimelineHistory('Explore');
            }}
              to="/Explore"
              className="navBarItem"
              exact
            >Explore
            </NavLink>
            <NavLink onClick={() => {
              this.props.handleIsFirstMount(true);
              this.props.handleTimelineHistoryIndex(0, true);
              this.props.handleTimelineHistory('Feed');
            }}
              to="/q/Feed"
              className="navBarItem"
              exact
            >Feed
            </NavLink>
            <NavLink onClick={() => {
              this.props.handleIsFirstMount(true);
              this.props.handleTimelineHistoryIndex(0, true);
              this.props.handleTimelineHistory('Profile');
            }}
              to="/q/Profile"
              className="navBarItem"
              exact
            >Profile
            </NavLink>
            <NavLink to="/Upload" className="navBarItem" >Upload | Create </NavLink>
          </div>
        :
          <div className="navBarCenter">
            <ToastContainer toastClassName="toast-primary-background" bodyClassName="toast-font-size" progressClassName="toast-progress-bar" />

            <NavLink onClick={() => {
              this.props.handleIsFirstMount(true);
              this.props.handleTimelineHistoryIndex(0, true);
              this.props.handleTimelineHistory('Explore');
            }}
              to="/Explore"
              className="navBarItem"
              exact
            >Explore
            </NavLink>
            <button onClick={this.notify} className="navBarItem" id="navBarItemUnAuth" >Feed</button>
            <button onClick={this.notify} className="navBarItem" id="navBarItemUnAuth" >Profile</button>
            <button onClick={this.notify} className="navBarItem" id="navBarItemUnAuth" >Upload | Create </button>
          </div>
        }
        <div id="navBarRight">
          <button className="navBarItem" id="navBarFeedback" onClick={this.openForm}>Have Feedback?</button>
          {this.renderSignInOut()}
        </div>
      </div>

    );
  }
}
// <NavLink to="/Settings" className="navBarItem" >Settings</NavLink>


const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
    viewNodeObj: state.nodes.viewNodeObj,
    timelineNode: state.tNodes.timelineNode,
  }

);

export default withRouter(connect(
  mapStateToProps,
  {
    signoutUser,
    handleIsFirstMount,
    handleTimelineHistory,
    handleTimelineHistoryIndex,
  },
)(NavigationBar));
