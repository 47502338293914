import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { fetchComments, updateComment, createComment, deleteComment, fetchComment, fetchCommentsWithChildren } from '../actions';
import '../styles/style.scss';

class Comment extends Component {
  constructor(props) {
    super(props);

    const temp = [];
    this.props.comment.commentArray.map((comment) => {
      return temp.push(false);
    });
    this.state = {
      content: '',
      expand: false,
      editing: false,
      editDelete: false,
      editDeleteArray: temp,
      editingArray: [],
      editComment: {},
      index: this.props.index,
      // commentChildren: {},
      // buttonsExpand: false,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.createComment = this.createComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.displayCommentInput = this.displayCommentInput.bind(this);
    this.toggleEditing = this.toggleEditing.bind(this);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.toggleEditDelete = this.toggleEditDelete.bind(this);
    this.toggleEditDeleteArray = this.toggleEditDeleteArray.bind(this);
    this.replyToComment = this.replyToComment.bind(this);
    // this.toggleExpandAsychHelper = this.toggleExpandAsychHelper.bind(this);
    // this.toggleButtonsExpand = this.toggleButtonsExpand.bind(this);
  }

  componentDidMount() {
    this.props.fetchCommentsWithChildren(this.props.comment._id, null, this.props.index);
  }

  onInputChange(event) {
    const { id } = event.target;
    if (id === 'content') {
      this.setState({ content: event.target.value });
    }
  }


  createComment(index) {
    this.props.createComment(Object.assign({}, this.state), this.props.comment, this.props.viewNodeObj._id, this.props.index, this.props.nodeType);

    // this.props.fetchComments(this.props.match.params.params.contentID);
    this.setState({ content: '' });
  }

  deleteComment(comment, commentParentID, index) {
    // this.props.createSubmission(Object.assign({}, this.state), this.props.viewNodeObj._id, this.props.history);
    if (index !== null) {
      this.props.deleteComment(comment._id, this.props.commentChildrenIndexed[this.props.index], this.props.viewNodeObj._id, commentParentID, this.props.index, this.props.nodeType);
    } else {
      this.props.deleteComment(comment._id, this.props.commentChildrenIndexed[this.props.index], this.props.viewNodeObj._id, commentParentID, index, this.props.nodeType);
    }
  }

  updateComment(comment, index) {
    this.props.updateComment(comment._id, Object.assign({}, this.state), this.props.viewNodeObj._id, this.props.comment._id, null, this.props.index, this.props.nodeType);


    // this.props.createSubmission(Object.assign({}, this.state), this.props.viewNodeObj._id, this.props.history);

    // this.props.fetchComments(this.props.match.params.params.contentID);
  }

  toggleEditing(comment) {
    this.setState({
      editing: !this.state.editing,
      expand: true,
      editComment: comment,
    });
  }

  toggleExpand() {
    const temp = [];
    this.props.comment.commentArray.map((comment) => {
      return temp.push(false);
    });

    this.setState({
      expand: !this.state.expand,
      editDeleteArray: temp,
      editing: false,
    });
    this.props.fetchCommentsWithChildren(this.props.comment._id, null, this.props.index);
  }


  toggleEditDeleteArray(index) {
    const temp = this.state.editDeleteArray;
    temp[index] = !temp[index];
    this.setState({
      editDeleteArray: temp,
    });
  }


  toggleEditDelete() {
    this.setState({
      editDelete: !this.state.editDelete,
    });
  }
  replyToComment(username) {
    this.setState({
      content: `@${username} `,
    });
  }

  displayCommentInput() {
    return (
      <div id="nestedCommentContainer">
        {this.props.commentChildrenIndexed[this.props.index].commentArray.length > 0 ?
          this.props.commentChildrenIndexed[this.props.index].commentArray.map((comment, index) => {
              if (comment !== null) {
                return (
                  <div key={comment._id} id="commentContentContainer">
                    <img className="icon"src="/public/images/userProfilePlaceholder.png" alt="more-icon" />

                    <div id="commentContent">
                      <NavLink to={`/u/${comment.author._id}`} >
                        <div id="username">
                          {comment.author.username}
                        </div>
                      </NavLink>
                      <div id="commentText">
                        {comment.content}
                      </div>
                    </div>
                    <div id="commentContentButtonContainer">
                      <button id="replyButton" onClick={() => this.replyToComment(comment.author.username)}> Reply </button>
                      {this.props.commentChildrenIndexed[this.props.index].commentArray[index].mine ?
                        <img className="icon" onClick={() => this.toggleEditDeleteArray(index)} src="/public/images/more.png" alt="more-icon" />
                        : ''
                      }
                      {this.state.editDeleteArray[index] && this.props.commentChildrenIndexed[this.props.index].commentArray[index].mine ?
                        <div id="editDeleteContainer">
                          <div id="editDeleteButtonContainer">
                            <button className="editDeleteButton" onClick={() => this.toggleEditing(comment)}> Edit </button>
                            <button className="editDeleteButton" onClick={() => this.deleteComment(comment, this.props.comment._id, this.state.index)}> Delete </button>
                          </div>
                        </div> : ''
                      }

                    </div>
                  </div>
                  );
              }
              return '';
             })
          : ''}

        <div id="mainSubmit" >
          <img className="icon"src="/public/images/userProfilePlaceholder.png" alt="more-icon" />

          <input autoComplete="off" onChange={this.onInputChange} id="content" value={this.state.content} placeholder="Write a reply ..." />

          {this.state.editing ?
            <button className="submitButton" onClick={() => this.updateComment(this.state.editComment, this.props.index)}> Edit Comment </button>
          :
            <button className="submitButton" onClick={() => this.createComment(this.props.index)}> Submit Comment </button>
        }
        </div>

      </div>
    );
  }

  render() {
    const date = new Date(this.props.comment.createdAt);
    let hourString = parseInt(date.getHours(), 10) > 12 ? (parseInt(date.getHours(), 10) - 12).toString() : date.getHours();
    hourString = parseInt(hourString, 10) === 0 ? '12' : hourString;
    const ampmString = parseInt(date.getHours(), 10) > 12 ? 'pm' : 'am';
    // const middleDot = '&middot;';
    const minuteString = parseInt(date.getMinutes(), 10) < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const dateString = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()} at ${hourString}:${minuteString}${ampmString}`;
    return (
      <div id="commentContainer">
        <div id="commentContentContainer">
          <img className="icon"src="/public/images/userProfilePlaceholder.png" alt="more-icon" />
          <div id="commentContentInfoContainer">
            <div id="commentContentButtonContainer">
              <div id="commentContent">
                <NavLink to={`/u/${this.props.comment.author._id}`} >
                  <div id="username">
                    {this.props.comment.author.username}
                  &middot;

                  </div>
                </NavLink>
                <div id="commentText">
                  {this.props.comment.content}
                </div>
              </div>
              <button id="replyButton" onClick={() => this.toggleExpand()}> Reply </button>

              {this.props.comments[this.props.index].mine ?
                <img className="icon" onClick={() => this.toggleEditDelete()} src="/public/images/more.png" alt="more-icon" />
                : ''
              }
              {this.state.editDelete && this.props.comments[this.props.index].mine ?
                <div id="editDeleteContainer">
                  <div id="editDeleteButtonContainer">
                    <button className="editDeleteButton" onClick={() => this.toggleEditing(this.props.comment)}> Edit </button>
                    <button className="editDeleteButton" onClick={() => this.deleteComment(this.props.comment, null, null)}> Delete </button>
                  </div>
                </div> : ''
              }
            </div>
            <div id="commentDate">
              {dateString.toString()}


            </div>

          </div>

        </div>

        <div>
          {this.props.comment.commentArray.length > 0 ?
            <div>
              {this.state.expand ?
                <button id="expandRepliesButton" onClick={() => this.toggleExpand()} >
                  <img alt="down-icon" className="icon" src="/public/images/upArrow.png" />
                Hide Replies
                </button>
              :
                <button id="expandRepliesButton" onClick={() => this.toggleExpand()} >
                  <img alt="up-icon" className="icon" src="/public/images/downArrow.png" />
                  View {this.props.comment.commentArray.length} Replies
                </button>
            }

            </div>
             : ''}

        </div>
        {this.state.expand ? this.displayCommentInput() : ''}

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    viewNodeObj: state.nodes.viewNodeObj,
    comments: state.comments.comments,
    commentNode: state.comments.comment,
    commentChildren: state.comments.commentChildren,
    commentChildrenIndexed: state.comments.commentChildrenIndex,
  }
);

// this.props.user.id
// user: state.auth.details.user


export default withRouter(connect(mapStateToProps, {
  fetchComments, updateComment, createComment, deleteComment, fetchComment, fetchCommentsWithChildren,
})(Comment));
