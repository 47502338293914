import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signupUser, signinUser } from '../../actions';

import '../../styles/style.scss';


class LandingStoryNodeView extends Component {
  constructor(props) {
    super(props);


    this.state = {
    };

    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
  }


  renderLandingNodeViewContent() {
    console.log(this.state);
    return (
      <div id="landingStoryNodeView">
        <div className="contentQueryNodeView">
          <div id="landingStoryNodeViewHeaderContainer" className="feedNodeViewHeader">
            <div id="landingStoryNodeViewHeaderDetail">02</div>
            <div id="landingStoryNodeViewHeader" >Story</div>

          </div>

          <div id="landingStoryNodeViewInfoContainer">
            <div id="landingStoryNodeViewInfoContent">
              <div id="headerText">
                A narrative or story is a report of connected events,
                real or imaginary, presented in a sequence of written
                or spoken words, or still or moving images, or both.
              </div>
              <div id="headerTextBlack">
                Record Atlas is built for storytelling.
                It is a platform to create digital
                timelines where story becomes the
                fabric between each photograph or song.
                It is a virtual atlas of stories told and archived through digital records.

              </div>
            </div>
          </div>
        </div>
        <div id="landingStoryNodeViewImageContainer">

          <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
          <div id="FeedRectTwo"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
          <div id="FeedRectThree"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
        </div>

      </div>
    );
  }

  render() {
    return (
      <div className="landingNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
        </div>
        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
  }

);

export default withRouter(connect(mapStateToProps, { signupUser, signinUser })(LandingStoryNodeView));
