import React from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';
import marked from 'marked';

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon,

} from 'react-share';


import '../styles/style.scss';


class NodeContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      buttonsExpand: false,

    };
    this.toggleButtonsExpand = this.toggleButtonsExpand.bind(this);
    this.renderRemoveModal = this.renderRemoveModal.bind(this);
  }

  toggleButtonsExpand() {
    this.setState({
      buttonsExpand: !this.state.buttonsExpand,
    });
  }

  // https://www.npmjs.com/package/react-share
  // inspired by KBS
  displayTriButton(node, nodeRoute) {
    let tempRoute;
    if (nodeRoute === 'n/Profile' || nodeRoute === 'n/Explore' || nodeRoute === 'n/Feed' || nodeRoute === 'n/User Profile') {
      tempRoute = `n/${node.node.id}`;
    } else {
      tempRoute = nodeRoute;
    }
    let contentType = true;
    if (node.node.permissions !== undefined) {
      contentType = false;
    }
    /*eslint-disable */
    return (
      <div id={contentType ? 'triButtonContainerContent': ''}>
        <div className={this.state.buttonsExpand ? 'triButtonContainerExpand' : 'triButtonContainer'}>

          <button className="triButtonIcon" onClick={() => this.toggleButtonsExpand()}><img id='triButtonShareIcon' alt='share-Icon' src="/public/images/share-Icon.png"/></button>
          <div>
            <button className="triButtonItem" id="item1"> <FacebookShareButton url={`recordatlas.com/${tempRoute}/${node.node._id}`} children={<FacebookIcon size={30} round iconBgStyle={{ fill: '#e7ae00' }} logoFillColor="#fff" />} />  </button>
            <button className="triButtonItem" id="item2"> <TwitterShareButton url={`recordatlas.com/${tempRoute}/${node.node._id}`} children={<TwitterIcon size={30} round iconBgStyle={{ fill: '#e7ae00' }} logoFillColor="#fff" />} />   </button>
            <button className="triButtonItem" id="item3"> <EmailShareButton url={`recordatlas.com/${tempRoute}/${node.node._id}`} children={<EmailIcon size={30} round iconBgStyle={{ fill: '#e7ae00' }} logoFillColor="#fff" />} />  </button>
          </div>
        </div>
      </div>
    );
    /* eslint-enable */
  }

  renderRemoveModal(position, node) {
    return (
      <div>
        <button
          id={`${position}${this.props.uploadToggle}NodeDeleteButton`}
          className="commonButton"
          onClick={() => {
            this.props.clearIndex();
            this.props.handleOpenModal(node.node);
          }}
        >
          Remove
        </button>
        <button
          onClick={() => {
            this.props.clearIndex();
        }}
        >
          <Modal
            isOpen={this.props.showModal}
            onRequestClose={() => {
            this.props.handleCloseModal();
          }}

            className="Modal"
            overlayClassName="Overlay"
            ariaHideApp={false}
          >
            <div id="removeModalHeader">  Remove <div id="title">{this.props.modalNode !== undefined ? this.props.modalNode.title : ''} </div>  from this timeline?</div>
            <p id="removeModalText">
              Are you sure you want to remove {this.props.modalNode !== undefined ? this.props.modalNode.title : ''}?
            </p>
            <div id="removeModalButtonContainer">
              <button className="modalButton"
                onClick={() => this.props.handleCloseModal()}
              >Cancel
              </button>

              <button
                className="modalButton"
                id="removeModalButton"
                onClick={() => {
              this.props.handleRemoveClick(this.props.modalNode);
              this.props.clearIndex();
              this.props.handleCloseModal();
            }}
              >
              Remove
              </button>
            </div>
          </Modal>
        </button>
      </div>
    );
  }

  renderTimelineNodeContainer(node, position) {
    let nodeType = 'Content';
    let nodeRoute = `${this.props.timelineNode._id}`;
    if (node.node !== undefined && node.node.permissions !== undefined) {
      nodeType = 'Timeline';
      nodeRoute = `${node.node._id}`;
    }
    if (this.props.query) {
      if (this.props.queryType === 'Feed' || this.props.queryType === 'Profile') {
        nodeRoute = `q/${this.props.queryType}`;
      } else if (this.props.queryType === 'Explore') {
        nodeRoute = `Explore/${this.props.queryType}`;
      } else {
        nodeRoute = `u/${this.props.userId}`;
      }
    } else {
      nodeRoute = `n/${this.props.timelineNode._id}`;
    }

    let dateString = '';
    if (node.node !== undefined && node.node.date !== undefined) {
      const date = new Date(node.node.date.toString());
      const month = [];
      month[0] = 'Jan';
      month[1] = 'Feb';
      month[2] = 'Mar';
      month[3] = 'Apr';
      month[4] = 'May';
      month[5] = 'Jun';
      month[6] = 'Jul';
      month[7] = 'Aug';
      month[8] = 'Sep';
      month[9] = 'Oct';
      month[10] = 'Nov';
      month[11] = 'Dec';
      const monthString = month[date.getMonth()];
      dateString = `${date.getFullYear()} ${monthString} ${date.getDate()} `;
    }
    let contentType = 'Image';

    if (node.node !== undefined) {
      if (node.node.contentType !== undefined) {
        ({ contentType } = node.node);
      }
    }


    if (node === '' || node === null || node === undefined || node.node === undefined || node.node === null) {
      return (
        <div >

          <button
            value={node}
            className="timelineNodePreviewButton"
            id="previewButton"
          >

            <div className={`nodeContainer${nodeType}`}>
              <div className="nodeContainerBackground1" />
              <div className="nodeContainerBackground2" />
              <div className={`nodeContainer${nodeType}Container`}>

                <p className="nodeContainerInfo" />
                <div className="nodeContainerTitle">
                  {nodeType === 'Timeline' && node.node.permissions ?
                    <img id="lockedTimelineIcon" alt="locked"src="/public/images/timelineLocked.png" />
            : ''
          }
                  <p />
                </div>
                <div
                  id={`${position}PreviewDate`}
                  className="tNodeDate"
                >
                  <p>Type 01</p>
                </div>
              </div>
            </div>

          </button>

        </div>

      );
    } else if (node.node.imageFile !== undefined && node.node.imageFile !== null && node.node.imageFile !== '') {
      return (
        <div>
          { this.props.uploadToggle === 'Hidden' ?
            <NavLink id="nodeContainer" className={this.props.uploadToggle === 'Hidden' ? '' : 'isDisabled'} key={node.node._id} to={`/${nodeRoute}/${node.node._id}`}>

              <button
                value={node}
                onDoubleClick={nodeType === 'Timeline' ?
                 (e) => {
                   window.location.assign(`/n/${node.node._id}/${node.node._id}`);
                   this.props.handleTimelineHistoryIndex(1);
                   this.props.handleTimelineHistory('node', node.node._id);
                 }
                 : ''}
                onClick={(e) => {
                  this.props.viewNode(node);
                  this.props.handleIsFirstMount(false);
                  this.props.handleNodePosition(this.props.index + 1);
                  this.props.handlePermissionsNodeView(false);
                  this.props.editNodeCancel();
                }}
                className="timelineNodePreviewButton"
                id={this.props.viewNodeObj._id === node.node._id ? 'previewButtonSelected' : 'previewButton'}
              >
                <div className={`nodeContainer${nodeType}`}>
                  <div className="nodeContainerBackground1" />
                  <div className="nodeContainerBackground2" />
                  <div className={`nodeContainer${nodeType}Container`}>
                    {this.displayTriButton(node, `n/${this.props.timelineNode._id}`)}
                    <p className="nodeContainerInfo"> {`${node.node.author.username} (${node.node.author.cNodePoints + node.node.author.tNodePoints})`}</p>
                    <div className="nodeContainerTitle">
                      {nodeType === 'Timeline' && node.node.permissions ?
                        <img id="lockedTimelineIcon" alt="locked"src="/public/images/timelineLocked.png" />
                  : ''
                }
                      <div > {node.node.title}</div>
                    </div>
                    <div
                      className="timelineNodePreview"
                    >
                      {contentType === 'Image' || nodeType === 'Timeline' ?

                        <img
                          alt="placeholder2"
                          src={node.node.imageFile}
                        />
                      :
                          contentType === 'Link' ?
                            <div id="nodeContainerLinkContainer">
                              <div id="linkTextContentNodeContainer">

                                <h3 id="linkSource">{node.node.link.linkSource}</h3>
                                <h2 id="linkHeader">{node.node.link.linkHeader}</h2>
                                <img
                                  alt="placeholder2"
                                  src={node.node.imageFile}
                                />
                                <h3 id="linkDesc"> {node.node.link.linkDesc}</h3>
                              </div>
                            </div>
                              :
                            <div id="nodeContainerTextContainer">
                              <div id="nodeContainerEmbedCover" dangerouslySetInnerHTML={{ __html: marked(node.node.desc || '') }} />
                            </div>
                        }
                    </div>

                    <div
                      id={`${position}PreviewDate`}
                      className="tNodeDate"
                    >
                      <p>{dateString.toString()}</p>
                    </div>
                    <div id={`${position}TokenCount`}>
                      {node.node.tokenCount !== undefined ? node.node.tokenCount : 0} {node.node.tokenCount === 1 || node.node.tokenCount === -1 ? 'point' : 'points'}
                    </div>
                  </div>
                </div>

              </button>
            </NavLink>

          :
            <button
              value={node}
              onClick={(e) => {
                this.props.viewNode(node);
                this.props.handleIsFirstMount(false);
              }}
              className="timelineNodePreviewButton"
              id="previewButton"
            >
              <div className={`nodeContainer${nodeType}`}>
                <div className="nodeContainerBackground1" />
                <div className="nodeContainerBackground2" />
                <div className={`nodeContainer${nodeType}Container`}>
                  <p className="nodeContainerInfo"> {`${node.node.author.username}(${node.node.author.cNodePoints + node.node.author.tNodePoints})`}</p>
                  <div className="nodeContainerTitle">
                    {nodeType === 'Timeline' && node.node.permissions ?
                      <img id="lockedTimelineIcon" alt="locked"src="/public/images/timelineLocked.png" />
              : ''
            }
                    <div > {node.node.title}</div>
                  </div>
                  <div
                    className="timelineNodePreview"
                  >
                    {contentType === 'Image' || nodeType === 'Timeline' ?

                      <img
                        alt="placeholder2"
                        src={node.node.imageFile}
                      />
                :
                    contentType === 'Link' ?
                      <div id="nodeContainerLinkContainer" />
                        :
                      <div id="nodeContainerTextContainer">
                        <div id="nodeContainerEmbedCover" dangerouslySetInnerHTML={{ __html: marked(node.node.desc || '') }} />
                      </div>
                  }
                  </div>

                  <div
                    id={`${position}PreviewDate`}
                    className="tNodeDate"
                  >
                    <p>{dateString.toString()}</p>
                  </div>
                  {this.renderRemoveModal(position, node)}

                  <button
                    id={`${position}${this.props.uploadToggle}NodeEditButton`}
                    className="commonButton"

                    value={node.node.id}
                    onClick={() => {
                  this.props.handleEditClick(node.node);
              }}
                  >
              Edit
                  </button>
                </div>
              </div>

            </button> }
        </div>
      );
    } else {
      return (
        <div>
          { this.props.uploadToggle === 'Hidden' ?
            <NavLink id="nodeContainer" className={this.props.uploadToggle === 'Hidden' ? '' : 'isDisabled'} key={node.node._id} to={`/${nodeRoute}/${node.node._id}`}>

              <button
                value={node}
                onDoubleClick={nodeType === 'Timeline' ?
                 (e) => {
                   window.location.assign(`/n/${node.node._id}/${node.node._id}`);
                   this.props.handleTimelineHistoryIndex(1);
                   this.props.handleTimelineHistory('node', node.node._id);
                 }
                 : ''}
                onClick={(e) => {
                  this.props.viewNode(node);
                  this.props.handleIsFirstMount(false);
                  this.props.handleNodePosition(this.props.index + 1);
                  this.props.handlePermissionsNodeView(false);
                  this.props.editNodeCancel();
                }}
                className="timelineNodePreviewButton"
                id={this.props.viewNodeObj._id === node.node._id ? 'previewButtonSelected' : 'previewButton'}
              >
                <div className={`nodeContainer${nodeType}`}>
                  <div className="nodeContainerBackground1" />
                  <div className="nodeContainerBackground2" />
                  <div className={`nodeContainer${nodeType}Container`}>
                    {this.displayTriButton(node, `n/${this.props.timelineNode._id}`)}
                    <p className="nodeContainerInfo"> {`${node.node.author.username} (${node.node.author.cNodePoints + node.node.author.tNodePoints})`}</p>
                    <div className="nodeContainerTitle">
                      {nodeType === 'Timeline' && node.node.permissions ?
                        <img id="lockedTimelineIcon" alt="locked"src="/public/images/timelineLocked.png" />
                : ''
              }
                      <div> {node.node.title}</div>
                    </div>
                    <div
                      className="timelineNodePreview"
                    >
                      {contentType === 'Image' || nodeType === 'Timeline' ?

                        <img
                          alt="placeholder2"
                          src={node.node.imageFile}
                        />
                  :
                      contentType === 'Link' ?
                        <div id="nodeContainerLinkContainer" />
                          :
                        <div id="nodeContainerTextContainer">
                          <div id="nodeContainerEmbedCover" dangerouslySetInnerHTML={{ __html: marked(node.node.desc || '') }} />
                        </div>
                    }
                    </div>
                    <div
                      id={`${position}PreviewDate`}
                      className="tNodeDate"
                    >
                      <p>{dateString.toString()}</p>
                    </div>
                    <div id={`${position}TokenCount`}>
                      {node.node.tokenCount !== undefined ? node.node.tokenCount : 0} {node.node.tokenCount === 1 || node.node.tokenCount === -1 ? 'point' : 'points'}
                    </div>

                  </div>
                </div>

              </button>
            </NavLink>

          :
            <button
              value={node}
              onClick={(e) => {
                this.props.viewNode(node);
                this.props.handleIsFirstMount(false);
              }}
              className="timelineNodePreviewButton"
              id="previewButton"
            >
              <div className={`nodeContainer${nodeType}`}>
                <div className="nodeContainerBackground1" />
                <div className="nodeContainerBackground2" />
                <div className={`nodeContainer${nodeType}Container`}>
                  <p className="nodeContainerInfo"> {`${node.node.author.username} (${node.node.author.cNodePoints + node.node.author.tNodePoints})`}</p>
                  <div className="nodeContainerTitle">
                    {nodeType === 'Timeline' && node.node.permissions ?
                      <img id="lockedTimelineIcon" alt="locked"src="/public/images/timelineLocked.png" />
              : ''
            }
                    <div > {node.node.title}</div>
                  </div>
                  <div
                    className="timelineNodePreview"
                  >
                    {contentType === 'Image' || nodeType === 'Timeline' ?

                      <img
                        alt="placeholder2"
                        src={node.node.imageFile}
                      />
                :
                    contentType === 'Link' ?
                      <div id="nodeContainerLinkContainer" />
                        :
                      <div id="nodeContainerTextContainer">
                        <div id="nodeContainerEmbedCover" dangerouslySetInnerHTML={{ __html: marked(node.node.desc || '') }} />
                      </div>
                  }
                  </div>
                  <div
                    id={`${position}PreviewDate`}
                    className="tNodeDate"
                  >
                    <p>{dateString.toString()}</p>
                  </div>

                  {this.renderRemoveModal(position, node)}

                  <button
                    id={`${position}${this.props.uploadToggle}NodeEditButton`}
                    className="commonButton"

                    value={node.node.id}
                    onClick={() => {
                  this.props.handleEditClick(node.node);
              }}
                  >
              Edit
                  </button>
                </div>
              </div>

            </button> }


        </div>
      );
    }
  }

  render() {
    return (

      <div>
        {this.renderTimelineNodeContainer(this.props.node, this.props.position)}

      </div>
    );
  }
}

export default NodeContainer;
