import React, { Component } from 'react';
import { connect } from 'react-redux';
import PasswordMask from 'react-password-mask';
import { withRouter } from 'react-router-dom';
import { signupUser, signinUser, clearErrorState } from '../../actions';

import '../../styles/style.scss';


class LandingLoginNodeView extends Component {
  constructor(props) {
    super(props);


    this.state = {
      email: '',
      password: '',
      username: '',
      signUp: true,
      error: '',

    };

    this.signUp = this.signUp.bind(this);
    this.signIn = this.signIn.bind(this);
    this.toggleSignUpIn = this.toggleSignUpIn.bind(this);

    this.onInputChange = this.onInputChange.bind(this);
    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
    this.renderLoginContainer = this.renderLoginContainer.bind(this);
  }
  componentDidMount() {
    this.props.clearErrorState();
  }
  onInputChange(event) {
    const { id } = event.target;
    if (id === 'email') {
      this.setState({ email: event.target.value });
    } else if (id === 'password') {
      this.setState({ password: event.target.value });
    } else if (id === 'username1') {
      this.setState({ username: event.target.value.toLowerCase() });
    }
  }
  // source: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  signUp(event) {
    event.preventDefault();

    if (!(this.state.email !== '' && this.validateEmail(this.state.email))) {
      this.setState({ error: 'a valid email address' });
    } else if (this.state.username === '') {
      this.setState({ error: 'a username' });
    } else if (this.state.password === '') {
      this.setState({ error: 'a password' });
    } else {
      this.props.signupUser(this.state, this.props.history);
    }
  }
  signIn(event) {
    event.preventDefault();
    this.props.signinUser(this.state, this.props.history);
  }

  toggleSignUpIn() {
    this.setState({ signUp: !this.state.signUp });
    this.props.clearErrorState();
  }

  renderLoginContainer() {
    let errorMessage = <div />;
    if (this.state.error) {
      errorMessage = <div> Please provide {this.state.error}. </div>;
    }
    if (!this.props.auth) {
      return (
        this.state.signUp ?
          <form className="SignForm" >
            <label className="signInput" htmlFor="username">Username
              <input autoComplete="off"
                id="username1"
                className="inputField"
                onChange={this.onInputChange}
                value={this.state.username}
              />
            </label>
            <label className="signInput" htmlFor="email">Email
              <input autoComplete="off"
                id="email"
                className="inputField"
                onChange={this.onInputChange}
                value={this.state.email}
              />
            </label>
            <label className="signInput" htmlFor="password">Password
              <PasswordMask
                id="password"
                name="password"
                inputClassName="inputField"
                buttonClassName="passwordShowToggle"
                useVendorStyles={false}
                autoComplete="off"
                value={this.state.password}
                onChange={this.onInputChange}
              />
            </label>
            <button id="signUpInButton" onClick={this.signUp}> Sign Up </button>
            <div id="errorMessageContainer">
              {this.props.error !== '' ? this.props.error : errorMessage }
            </div>
          </form>
          :
          <form className="SignForm" >
            <label className="signInput" htmlFor="email">Email
              <input autoComplete="off"
                id="email"
                className="inputField"
                onChange={this.onInputChange}
                value={this.state.email}
              />
            </label>
            <label className="signInput" htmlFor="password">Password
              <PasswordMask
                id="password"
                name="password"
                inputClassName="inputField"
                buttonClassName="passwordShowToggle"
                useVendorStyles={false}
                autoComplete="off"
                value={this.state.password}
                onChange={this.onInputChange}
              />
            </label>
            <button id="signUpInButton" onClick={this.signIn}> Sign In </button>
            <div id="errorMessageContainer">
              {this.props.error}
            </div>
          </form>
      );
    } else {
      return (
        <div id="landingLoggedInSpace" />
      );
    }
  }

  renderLandingNodeViewContent() {
    return (
      <div id="landingLoginNodeView">
        <div className="contentQueryNodeView">
          <div id="landingLoginNodeViewHeaderContainer" className="feedNodeViewHeader">
            <div id="landingLoginNodeViewHeaderDetail">01</div>
            <div id="landingLoginNodeViewHeader" >Record Atlas</div>

          </div>
          <div id="landingNodeViewLoginContainer">
            {this.renderLoginContainer()}
            {!this.props.auth ?
              <button id="toggleSignUpInButton" onClick={() => { this.toggleSignUpIn(); }}> {this.state.signUp ? 'Sign In' : 'Sign Up'} </button>
              : ''
            }

          </div>
          <div id="landingLoginNodeViewInfoContainer">
            <div id="landingLoginNodeViewInfoContent">
              <div id="headerText">
                Record Atlas is a collection of stories. It is a motion
                album visual essays, digital records and memorabilia.
                It is where experiences find form through narrative.
              </div>
            </div>
          </div>
        </div>
        <div id="feedNodeViewImageContainer">

          <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
          <div id="FeedRectTwo"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
          <div id="FeedRectThree"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
        </div>

      </div>
    );
  }

  render() {
    return (
      <div className="landingNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
    error: state.auth.errorMessage,

  }

);

export default withRouter(connect(mapStateToProps, { signupUser, signinUser, clearErrorState })(LandingLoginNodeView));
