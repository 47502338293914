import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import NavigationBar from './NavigationBar';
import TimelineView from '../components/TimelineView';
import NodeView from '../components/NodeView';
import {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchContentNode,
  viewingNode,
  updateUser,
  fetchUser,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
  handleIsFirstMount,
  updateTimelineNodePermissionsRequests,
  tokenVote,
  editNodeCancel,
  fetchUnAuthTimelineNode,


} from '../actions';

import '../styles/style.scss';

class UserPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      position: 0,
      permissionsNodeView: false,
      queryType: 1,


    };
    this.handleContentDeleteClick = this.handleContentDeleteClick.bind(this);
    this.handleTimelineDeleteClick = this.handleTimelineDeleteClick.bind(this);
    this.viewNode = this.viewNode.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.timelineClickthrough = this.timelineClickthrough.bind(this);
    this.subscribeTimeline = this.subscribeTimeline.bind(this);
    this.unSubscribeTimeline = this.unSubscribeTimeline.bind(this);
    this.handleNodePosition = this.handleNodePosition.bind(this);
    this.handlePermissionsNodeView = this.handlePermissionsNodeView.bind(this);
    this.handleQueryTypeToggle = this.handleQueryTypeToggle.bind(this);
  }

  componentDidMount() {
    this.props.fetchUser(this.props.match.params.userID);
    this.props.fetchSpecificTimelineNodes('Creator', this.props.match.params.userID);
    this.props.handleIsFirstMount(true);
    this.props.handleTimelineHistory('user', this.props.match.params.userID);
  }


  componentWillReceiveProps(props) {
    const tempAlbumTNodeArray = {};
    if (props.timelineNodes !== undefined) {
      tempAlbumTNodeArray.nodes = [];
      if (this.state.queryType === 5) {
        // queryType 5 is for media nodes/ contentNodes
        props.timelineNodes.map((cNode) => {
          return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: cNode }));
        });
      } else if (this.state.queryType === 4) {
        props.timelineNodes.map((tNode) => {
          return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
        });
      } else {
        props.timelineNodes.map((tNode) => {
          if (tNode.album !== undefined && tNode.album) {
            return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
          }
          return tNode._id;
        });
      }
      tempAlbumTNodeArray.title = props.user.username !== undefined ? `${props.user.username} Profile` : 'User Profile';
      tempAlbumTNodeArray._id = 'User Profile';
      this.setState({
        timelineNode: tempAlbumTNodeArray,
      });
    } else {
      this.setState({
        timelineNode: this.props.timelineNode,
      });
    }
  }

  handleEditClick(node) {
    this.props.editNode(node);
  }

  handleContentDeleteClick(event) {
    this.props.deleteContentNode(this.props.contentNode.id, this.props.history);
  }

  handleTimelineDeleteClick(event) {
    console.log('handleTimelineDeleteClick was called on', this.state.timelineNode.title);
  }


  viewNode(node) {
    this.props.viewingNode(node);
  }
  handlePermissionsNodeView(bool) {
    this.setState({ permissionsNodeView: bool });
  }

  subscribeTimeline() {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    if (updatedUser.permissionViewKeys.indexOf(this.props.viewNodeObj._id) === -1) {
      updatedUser.permissionViewKeys.push(this.props.viewNodeObj._id);
      this.props.updateUser(updatedUser._id, updatedUser, false, false, true);
    }
  }

  unSubscribeTimeline(node) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    const permissionsIndex = updatedUser.permissionViewKeys.indexOf(node._id);
    if (permissionsIndex > -1) {
      updatedUser.permissionViewKeys.splice(permissionsIndex, 1);
      this.props.updateUser(updatedUser._id, updatedUser, false, false, true);
    }
  }

  timelineClickthrough(node) {
    if (this.props.auth) {
      this.props.fetchTimelineNode(node._id);
    } else {
      this.props.fetchUnAuthTimelineNode(node._id);
    }
  }

  handleNodePosition(nodePosition) {
    this.setState({ position: nodePosition });
  }
  handleQueryTypeToggle(queryType) {
    this.setState({ queryType });
  }

  render() {
    return (
      <div className="Page">
        <div className="pageGridV" >

          <div className="pageNav">
            <NavigationBar />
          </div>
          <div className="pageGridH" >

            <div className="pageGrid" >
              <TimelineView
                timelineNode={this.state.timelineNode !== undefined ? this.state.timelineNode : this.props.timelineNode}
                handleDeleteClick={this.handleTimelineDeleteClick}
                handleEditClick={this.handleTimelineEditClick}
                viewNode={this.viewNode}
                timelineLine="timelineLine"
                timelineViewBox="timelineViewBox"
                timelineNodesBox="timelineNodes"
                page="Profile"
                uploadToggle="Hidden"
                query
                userId={this.props.match.params.userID}
                handleTimelineHistory={this.props.handleTimelineHistory}
                handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                timelineHistory={this.props.timelineHistory}
                timelineHistoryIndex={this.props.timelineHistoryIndex}
                queryType="user"
                viewNodeObj={this.props.viewNodeObj}
                contentID={this.props.match.params.contentID}
                handleIsFirstMount={this.props.handleIsFirstMount}
                mount={this.props.mount}
                handleNodePosition={this.handleNodePosition}
                nodePosition={this.state.position + 1}
                permissionsNodeView={this.state.permissionsNodeView}
                handlePermissionsNodeView={this.handlePermissionsNodeView}
                queryContributorModeratorType={this.state.queryType}
                user={this.props.user}
                auth={this.props.auth}
                editNodeCancel={this.props.editNodeCancel}

              />
            </div>
            <div className="pageGrid" id="pageGridMargin">
              <NodeView
                handleContentDeleteClick={this.handleContentDeleteClick}
                timelineNodeFeedDefault={this.props.timelineNodeFeedDefault}
                viewNodeObj={this.props.viewNodeObj}
                handleEditClick={this.handleEditClick}
                subscribeTimeline={this.subscribeTimeline}
                unSubscribeTimeline={this.unSubscribeTimeline}
                timelineNode={this.props.timelineNode}
                timelineClickthrough={this.timelineClickthrough}
                userQuery
                queryType="user"
                user={this.props.user !== [] ? this.props.user : false}
                handleTimelineHistory={this.props.handleTimelineHistory}
                handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                mount={this.props.mount}
                auth={this.props.auth}
                viewing={false}
                nodePosition={this.state.position}
                updateTimelineNodePermissionsRequests={this.props.updateTimelineNodePermissionsRequests}
                fetchSpecificTimelineNodes={this.props.fetchSpecificTimelineNodes}
                permissionsNodeView={this.state.permissionsNodeView}
                handlePermissionsNodeView={this.handlePermissionsNodeView}
                handleQueryTypeToggle={this.handleQueryTypeToggle}
                queryContributorModeratorType={this.state.queryType}
                tokenVote={this.props.tokenVote}

              />
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    user: state.user.user,
    isEditing: state.nodes.isEditing,
    node: state.cNodes.node,
    viewNodeObj: state.nodes.viewNodeObj,
    timelineNode: state.tNodes.timelineNode,
    timelineNodes: state.tNodes.timelineNodes,
    timelineHistory: state.tNodes.timelineHistory,
    timelineHistoryIndex: state.tNodes.timelineHistoryIndex,
    mount: state.page.mount,
    auth: state.auth.authenticated,

  }
);

export default withRouter(connect(mapStateToProps, {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchContentNode,
  viewingNode,
  updateUser,
  fetchUser,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
  handleIsFirstMount,
  updateTimelineNodePermissionsRequests,
  tokenVote,
  editNodeCancel,
  fetchUnAuthTimelineNode,


})(UserPage));
