import React from 'react';
import moment from 'moment';
import Toggle from 'react-toggle';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-widgets/dist/css/react-widgets.css';
import Multiselect from 'react-widgets/lib/Multiselect';

import uploadImage from '../actions/s3';
import '../styles/style.scss';

class NodeUpload extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: (props.eNode !== undefined && props.isEditing) ? props.eNode.title : '',
      date: (props.eNode !== undefined && props.isEditing) ? moment(props.eNode.date) : moment(),
      desc: (props.eNode !== undefined && props.isEditing) ? props.eNode.desc : '',
      refsource: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined) ? props.eNode.refsource : '',
      type: (props.eNode !== undefined && props.isEditing) ? (props.eNode.permissions === undefined ? 'Content' : 'Timeline') : props.nodeFromTimeline === 'none' || props.nodeFromTimeline === 'Content' ? 'Content' : 'Timeline',
      permissions: (props.eNode !== undefined && props.isEditing) ? props.eNode.permissions : false,
      contentType: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined) ? props.eNode.contentType : 'Image',
      link: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined) ? props.eNode.link : {},
      linkSource: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined && props.eNode.link !== undefined) ? props.eNode.link.linkSource : '',
      linkDesc: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined && props.eNode.link !== undefined) ? props.eNode.link.linkDesc : '',
      linkUrl: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined && props.eNode.link !== undefined) ? props.eNode.link.linkUrl : '',
      linkHeader: (props.eNode !== undefined && props.isEditing && props.eNode.permissions === undefined && props.eNode.link !== undefined) ? props.eNode.link.linkHeader : '',

      // album means the timeline will be a timelineContainer (shows up on query pages)
      album: (props.eNode !== undefined && props.isEditing) ? props.eNode.album : props.masterSelected,
      isEditing: props.isEditing,
      eNode: props.eNode,
      permissionViewKeys: (props.eNode !== undefined && props.isEditing) ? props.eNode.permissionViewKeys : [],
      permissionEditKeys: (props.eNode !== undefined && props.isEditing) ? props.eNode.permissionEditKeys : [],
      permissionAdminKeys: (props.eNode !== undefined && props.isEditing) ? props.eNode.permissionAdminKeys : [],
      creatorKeys: (props.eNode !== undefined && props.isEditing) ? props.eNode.creatorKeys : [],
      permissionRequests: (props.eNode !== undefined && props.isEditing) ? props.eNode.permissionRequests : [],
      openContributor: (props.eNode !== undefined && props.isEditing) ? props.eNode.openContributor : false,
      imageFile: (props.eNode !== undefined && props.isEditing) ? props.eNode.imageFile : '',
      preview: (props.eNode !== undefined && props.isEditing) ? props.eNode.imageFile : '',
      tagList: (props.eNode !== undefined && props.isEditing && props.eNode.tagList !== undefined) ? props.eNode.tagList : ['Story'],

    };

    this.handleChange = this.handleChange.bind(this);
    this.handlePostToggle = this.handlePostToggle.bind(this);
    this.handleTimelineToggle = this.handleTimelineToggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitHelper = this.handleSubmitHelper.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePermissionsToggle = this.handlePermissionsToggle.bind(this);
    this.handleAlbumToggle = this.handleAlbumToggle.bind(this);
    this.handleOpenContributorToggle = this.handleOpenContributorToggle.bind(this);
    this.onImageUpload = this.onImageUpload.bind(this);
    this.renderPublishButtons = this.renderPublishButtons.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleImageRemove = this.handleImageRemove.bind(this);
    this.renderImageRemove = this.renderImageRemove.bind(this);
    this.handleContentTypeToggle = this.handleContentTypeToggle.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      isEditing: props.isEditing,
      eNode: props.eNode,
      type: (props.nodeFromTimeline === 'none' || props.nodeFromTimeline === 'Content') && !props.masterSelected ? 'Content' : 'Timeline',
      album: props.masterSelected,

    });
    if (props.eNode !== undefined) {
      if (props.isEditing) {
        this.setState({
          title: props.eNode.title,
          date: moment(props.eNode.date),
          desc: props.eNode.desc,
          type: 'Timeline',
          openContributor: props.eNode.openContributor,
          permissions: props.eNode.permissions,
          imageFile: props.eNode.imageFile,
          preview: props.eNode.imageFile,
          tagList: props.eNode.tagList,
        });
        if (props.eNode.permissions === undefined) {
          this.setState({
            refsource: props.eNode.refsource,
            type: 'Content',
            contentType: props.eNode.contentType,
            link: props.eNode.link,
            linkSource: props.eNode.link.linkSource,
            linkDesc: props.eNode.link.linkDesc,
            linkUrl: props.eNode.link.linkUrl,
            linkHeader: props.eNode.link.linkHeader,

          });
        } else {
          this.setState({
            permissionViewKeys: props.eNode.permissionViewKeys,
            permissionEditKeys: props.eNode.permissionEditKeys,
            permissionAdminKeys: props.eNode.permissionAdminKeys,
            creatorKeys: props.eNode.creatorKeys,
            permissionRequests: props.eNode.permissionRequests,
            album: props.eNode.album,
          });
        }
      }
    }
  }


  onImageUpload(event) {
    const imageFile = event.target.files[0];

    // Handle null file
    // Get url of the file and set it to the src of preview
    if (imageFile) {
      this.setState({ preview: window.URL.createObjectURL(imageFile), imageFile });
    }
  }


  handleChange(event) {
    const { id } = event.target;
    if (id === 'title') {
      this.setState({ title: event.target.value });
    } else if (id === 'desc') {
      const value = event.target.value.replace('\\n', '\n');
      this.setState({ desc: value });
    } else if (id === 'refsource') {
      this.setState({ refsource: event.target.value });
    } else if (id === 'linkUpload') {
      this.setState({ linkUrl: event.target.value });
      const linkTemp = {};
      linkTemp.linkUrl = event.target.value;
      linkTemp.linkSource = this.state.linkSource;
      linkTemp.linkDesc = this.state.linkDesc;
      linkTemp.linkHeader = this.state.linkHeader;
      this.setState({
        link: linkTemp,
      });
    } else if (id === 'linkUploadSource') {
      this.setState({ linkSource: event.target.value });
      const linkTemp = {};
      linkTemp.linkUrl = this.state.linkUrl;
      linkTemp.linkSource = event.target.value;
      linkTemp.linkDesc = this.state.linkDesc;
      linkTemp.linkHeader = this.state.linkHeader;

      this.setState({
        link: linkTemp,
      });
    } else if (id === 'linkUploadDesc') {
      this.setState({ linkDesc: event.target.value });
      const linkTemp = {};
      linkTemp.linkUrl = this.state.linkUrl;
      linkTemp.linkSource = this.state.linkSource;
      linkTemp.linkDesc = event.target.value;
      linkTemp.linkHeader = this.state.linkHeader;
      this.setState({
        link: linkTemp,
      });
    } else if (id === 'linkUploadHeader') {
      this.setState({ linkHeader: event.target.value });
      const linkTemp = {};
      linkTemp.linkUrl = this.state.linkUrl;
      linkTemp.linkSource = this.state.linkSource;
      linkTemp.linkDesc = this.state.linkDesc;
      linkTemp.linkHeader = event.target.value;
      this.setState({
        link: linkTemp,
      });
    }
  }
  handleDateChange(date) {
    this.setState({
      date,
    });
  }
  // handleTimelineUpdate

  handleSubmitHelper() {
    let editing = this.props.isEditing;
    if (!this.state.isEditing) {
      editing = this.state.isEditing;
    }

    return new Promise((resolve, reject) => {
      if (!editing) {
        if (this.state.imageFile) {
          uploadImage(this.state.imageFile).then((url) => {
            const urlObject = { url };
            this.props.createNode(Object.assign({}, this.state, urlObject));
            window.URL.revokeObjectURL(this.state.preview);
            resolve();
          }).catch((error) => {
            reject();
          });
        } else {
          this.props.createNode(Object.assign({}, this.state));
          resolve();
        }
      } else if (editing) {
        if (this.state.imageFile) {
          uploadImage(this.state.imageFile).then((url) => {
            if (url !== 'https://recordatlas.s3.amazonaws.com/undefined') {
              this.setState({ imageFile: url });
            }
            this.props.updateNode(Object.assign({}, this.state));
            window.URL.revokeObjectURL(this.state.preview);
            resolve();
          }).catch((error) => {
            reject();
          });
        } else {
          this.props.updateNode(Object.assign({}, this.state));
          resolve();
        }
      }
    });
  }
  handleSubmit(event) {
    this.handleSubmitHelper().then(() => {
      this.clearForm();
    });

    event.preventDefault();
  }

  clearForm() {
    this.setState({
      title: '',
      date: moment(),
      desc: '',
      refsource: '',
      type: 'Content',
      permissions: true,
      isEditing: false,
      contentType: 'Image',
      link: '',
      linkSource: '',
      linkDesc: '',
      linkUrl: '',
      linkHeader: '',
    });

    if (this.state.preview) {
      delete this.state.preview;
    }
    if (this.state.imageFile) {
      delete this.state.imageFile;
    }
  }

  handlePostToggle(event) {
    this.setState({ type: 'Content' });
  }
  handleTimelineToggle(event) {
    this.setState({ type: 'Timeline' });
    this.setState({ date: moment() });
  }

  handlePermissionsToggle(event) {
    this.setState({ permissions: !this.state.permissions });
  }

  handleAlbumToggle(event) {
    this.setState({ album: !this.state.album });
  }
  handleOpenContributorToggle(event) {
    this.setState({ openContributor: !this.state.openContributor });
  }


  handleEditCancel(event) {
    this.props.handleEditCancel();
    this.clearForm();


    event.preventDefault();
  }

  handleImageRemove(event) {
    this.setState({
      preview: '',
      imageFile: '',
    });
    delete this.state.preview;
    delete this.state.imageFile;
    event.preventDefault();
  }
  handleContentTypeToggle(type) {
    this.setState({ contentType: type });
  }

  renderImageRemove() {
    if (this.state.imageFile) {
      return (
        <div>
          <button className="cancelImageUpload" onClick={(event) => { this.handleImageRemove(event); }}> &#x2715;  </button>
        </div>
      );
    }
    return (
      <div />
    );
  }


  renderHeader() {
    if (this.props.isEditing) {
      return (
        <div className="editingHeader">
         Edit | Update
        </div>
      );
    } else if (this.state.type === 'Content') {
      return (
        <div className="UploadToggle">
          <button className="ToggleButton" onClick={this.handlePostToggle} id="primaryBackground">Add Photo/Media</button>
          <button className="ToggleButton" onClick={this.handleTimelineToggle} id="whiteBackground">Create New Timeline</button>
        </div>
      );
    } else {
      return (
        <div className="UploadToggle">
          <button className="ToggleButton" onClick={this.handlePostToggle} id="whiteBackground">Add Photo/Media</button>
          <button className="ToggleButton" onClick={this.handleTimelineToggle} id="primaryBackground">Create New Timeline</button>
        </div>
      );
    }
  }


  renderNodeDiff() {
    if (this.state.type === 'Content') {
      return (
        <div id="contentNodeTypeContainer">
          <button onClick={() => { this.handleContentTypeToggle('Image'); }} className={this.state.contentType === 'Image' ? 'contentNodeTypeToggleButtonSelected' : 'contentNodeTypeToggleButton'}> Image</button>
          <button onClick={() => { this.handleContentTypeToggle('Text'); }} className={this.state.contentType === 'Text' ? 'contentNodeTypeToggleButtonSelected' : 'contentNodeTypeToggleButton'}> Text </button>
          <button onClick={() => { this.handleContentTypeToggle('Link'); }} className={this.state.contentType === 'Link' ? 'contentNodeTypeToggleButtonSelected' : 'contentNodeTypeToggleButton'}> Link </button>
          <button onClick={() => { this.handleContentTypeToggle('Embed'); }} className={this.state.contentType === 'Embed' ? 'contentNodeTypeToggleButtonSelected' : 'contentNodeTypeToggleButton'}> Embed </button>

        </div>
      );
      // return (
      //   <div>
      //     <label htmlFor="refsource">Reference Source </label>
      //     <p>
      //       <input type="text" id="refsource" value={this.state.refsource} onChange={this.handleChange} autoComplete="off" />
      //     </p>
      //   </div>
      // );
    } else {
      return (
        <div>
          <div id="timelineUploadToggleContainer">
            <div className="timelineUploadToggle">
              <p> Private Timeline </p>
              <div id="nodeUploadToggleExplain">
                Private timelines require viewer permission to access. You can grant permissions after creating the timeline.
              </div>
              <Toggle
                defaultChecked={this.state.permissions}
                onChange={this.handlePermissionsToggle}
              />
            </div>

            <div className="timelineUploadToggle">
              <p> Album Timeline  </p>
              <div id="nodeUploadToggleExplain">
                Album timelines are timelines that will show up on the Explore, Feed and Profile pages.
              </div>
              <Toggle
                defaultChecked={this.state.album}
                onChange={this.handleAlbumToggle}
              />
            </div>
            <div className="timelineUploadToggle">
              <p> Open Source</p>
              <div id="nodeUploadToggleExplain">
              Open Souce Timelines allow users to contribute without any granted permissions.
              </div>
              <Toggle
                defaultChecked={this.state.openContributor}
                onChange={this.handleOpenContributorToggle}
              />
            </div>

          </div>
          <div id="uploadDropListContainer">
            <p> Timeline Tags </p>
            <div id="nodeUploadGeneralExplain">
            Tag your timeline so others can find it from the Explore Page.
            </div>
            <div id="uploadDropList">
              <Multiselect
                data={['Story', 'Project', 'History', 'Album', 'Documentation', 'Art']}
                defaultValue={this.state.tagList}
                maxLen={3}
                value={this.state.tagList}
                onChange={tagList => this.setState({ tagList })}

              />
            </div>
          </div>
        </div>
      );
    }
  }

  renderDate() {
    return (
      <div>
        <DatePicker
          dateFormat="YYYY/MM/DD"
          selected={this.state.date}
          onChange={this.handleDateChange}
          todayButton="Today"

        />
      </div>
    );
  }

  renderUploadImage() {
    if (this.state.imageFile) {
      return (
        <img className="previewImage" id="preview" alt="preview" src={this.state.preview} />
      );
    }
    return (
      <div />
    );
  }

  renderPublishButtons() {
    let editing = this.props.isEditing;
    if (!this.state.isEditing) {
      editing = this.state.isEditing;
    }

    if (!editing) {
      return (
        <div >
          {this.props.nodeFromTimeline !== 'none' ?
            <div>
              <button className="editCancelButton" onClick={(e) => { this.props.handleNodeFromTimelineCancel(e); }}>Cancel</button>
              <button className="editPublishButton" onClick={(e) => { this.handleSubmit(e); }}>Publish</button>
            </div>
          :
            <button className="publishButton" onClick={(e) => { this.handleSubmit(e); }}>Publish</button>
          }
        </div>
      );
    } else {
      return (
        <div >
          <button className="editCancelButton" onClick={(e) => { this.handleEditCancel(e); }}>Cancel Changes</button>
          <button className="editPublishButton" onClick={(e) => { this.handleSubmit(e); }}>Publish</button>
        </div>
      );
    }
  }

  renderUploadNode() {
    return (
      <div className="UploadNode">
        {this.renderHeader()}


        <div>

          <div className="NodeBackground">

            <div>
              <div id="currentUploadTimeline">
                Currently Uploading To: {this.props.masterSelected ? 'Portfolio Timeline' : this.props.timelineNode.title}
              </div>
              {this.renderNodeDiff()}

              <label htmlFor="title">{this.state.contentType === 'Link' ? 'Event ' : ''} Title </label>
              <p>
                <input type="text" id="title" value={this.state.title} onChange={this.handleChange} autoComplete="off" />
              </p>
            </div>
            {this.state.type !== 'Content' || this.state.contentType === 'Image' ?
              <div>
                <p>Photo</p>

                <p className="sm" >Click To Upload An Image</p>

                <input className="uploadImage" type="file" name="file" id="file" onChange={this.onImageUpload} autoComplete="off" />
                <label className="dropzone" htmlFor="file">
                  {this.renderUploadImage()}
                  {this.renderImageRemove()}
                  <img alt="upload-icon" className="upload-icon" src="/public/images/photo-icon.png" width="20px" height="20px" />
                </label>
              </div> : ''
          }
            <div>

              <div className="input-container">

                {this.state.contentType === 'Link' ?
                  <div>
                    <div>
                      <p>Link Banner (Optional)</p>
                      <p className="sm" >Click To Upload An Image</p>
                      <div className="linkUploadBanner">

                        <input className="uploadImage" type="file" name="file" id="file" onChange={this.onImageUpload} autoComplete="off" />
                        <label className="dropzone" htmlFor="file">
                          {this.renderUploadImage()}
                          {this.renderImageRemove()}
                          <img alt="upload-icon" className="upload-icon" src="/public/images/photo-icon.png" width="20px" height="20px" />
                        </label>
                      </div>
                    </div>
                    <div id="linkUploadContainer">
                      <label htmlFor="linkUploadSource">Link Source</label>
                      <p>
                        <input id="linkUploadSource" type="text" value={this.state.linkSource} placeholder="The New York Times, The Wall Street Journal, etc..." onChange={this.handleChange} autoComplete="off" />
                      </p>
                      <label htmlFor="linkUpload">Link URL</label>
                      <p>
                        <input id="linkUpload" type="text" value={this.state.linkUrl} placeholder="Link Url https://~" onChange={this.handleChange} autoComplete="off" />
                      </p>
                      <label htmlFor="linkUploadHeader">Link Header</label>
                      <p>
                        <input id="linkUploadHeader" type="text" value={this.state.linkHeader} placeholder="Link Header" onChange={this.handleChange} autoComplete="off" />
                      </p>
                      <label htmlFor="linkUploadDesc">Link Summary</label>
                      <p>
                        <textarea id="linkUploadDesc" type="text" value={this.state.linkDesc} placeholder="Provide a Summary about the link" onChange={this.handleChange} />
                      </p>
                    </div>
                  </div>
                  :
                  <div>
                    <label htmlFor="desc"> {this.state.contentType === 'Embed' ? 'Embeded Code' : 'Description'}</label>
                    <p>
                      <textarea id="desc" value={this.state.desc} onChange={this.handleChange} />
                    </p>
                  </div>
                }
                <div>
                  <label htmlFor="date">Date </label>
                  <div id="nodeUploadGeneralExplain">
                    Timelines will automatically order chronologically by the date you select. Date Created will show up seperately.
                  </div>
                  <p>
                    {this.renderDate()}
                  </p>
                </div>

                <p />

                {this.renderPublishButtons()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  render() {
    return (
      this.renderUploadNode()
    );
  }
}


export default NodeUpload;
