import axios from 'axios';

// keys for actiontypes
export const ActionTypes = {
  CREATE_TIMELINE_NODE: 'CREATE_TIMELINE_NODE',
  FETCH_TIMELINE_NODES: 'FETCH_TIMELINE_NODES',
  FETCH_TIMELINE_NODE: 'FETCH_TIMELINE_NODE',
  FETCH_SPECIFIC_TIMELINE_NODES: 'FETCH_SPECIFIC_TIMELINE_NODES',
  FETCH_TIMELINE_NODES_EXPLORE_FEED: 'FETCH_TIMELINE_NODES_EXPLORE_FEED',

  UPDATE_TIMELINE_NODE: 'UPDATE_TIMELINE_NODE',
  DELETE_TIMELINE_NODE: 'DELETE_TIMELINE_NODE',

  CREATE_CONTENT_NODE: 'CREATE_CONTENT_NODE',
  FETCH_CONTENT_NODE: 'FETCH_CONTENT_NODE',
  UPDATE_CONTENT_NODE: 'UPDATE_CONTENT_NODE',
  DELETE_CONTENT_NODE: 'DELETE_CONTENT_NODE',

  EDIT_NODE: 'EDIT_NODE',
  EDIT_NODE_CANCEL: 'EDIT_NODE_CANCEL',

  VIEWING_NODE: 'VIEWING_NODE',

  CREATE_COMMENT: 'AUTH_COMMENT',
  FETCH_COMMENTS: 'FETCH_COMMENTS',
  FETCH_COMMENTSWITHCHILDREN: 'FETCH_COMMENTSWITHCHILDREN',
  FETCH_COMMENTSWITHCHILDRENINDEX: 'FETCH_COMMENTSWITHCHILDRENINDEX',

  FETCH_COMMENT: 'FETCH_COMMENT',

  UPDATE_COMMENT: 'UPDATE_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',

  // Auth
  AUTH_USER: 'AUTH_USER',
  DEAUTH_USER: 'DEAUTH_USER',
  AUTH_ERROR: 'AUTH_ERROR',
  CLEAR_ERROR: 'CLEAR_ERROR',

  // UserSchema
  FIND_USERS: 'FIND_USERS',
  FETCH_USER: 'FETCH_USER',
  UPDATE_USER: 'UPDATE_USER',
  FETCH_USER_POINTS: 'FETCH_USER_POINTS',

  // fetch users for permissionsView
  FETCH_USERS_REQUEST_TYPE: 'FETCH_USERS_REQUEST_TYPE',
  FETCH_USERS_ADMIN_TYPE: 'FETCH_USERS_ADMIN_TYPE',
  FETCH_USERS_CREATOR_TYPE: 'FETCH_USERS_CREATOR_TYPE',
  FETCH_USERS_EDIT_TYPE: 'FETCH_USERS_EDIT_TYPE',
  FETCH_USERS_VIEW_TYPE: 'FETCH_USERS_VIEW_TYPE',

  // navigate Timeline History

  HANDLE_TIMELINE_HISTORY: 'HANDLE_TIMELINE_HISTORY',
  HANDLE_TIMELINE_HISTORY_INDEX: 'HANDLE_TIMELINE_HISTORY_INDEX',

  // handle queryPage is mounted

  HANDLE_IS_FIRST_MOUNTED: 'HANDLE_IS_FIRST_MOUNTED',
};

export const ROOT_URL = 'https://recordatlas.herokuapp.com/api';
// export const ROOT_URL = 'http://localhost:9090/api';

const API_KEY = '?key=r_atlas';


export function fetchUser(id) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/users/${id}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatch({ type: ActionTypes.FETCH_USER, payload: response.data });
        return response;
      }).catch((error) => {
        console.log('fetch User failed');
      });
  };
}

export function fetchUsers(userArray, userArrayType) {
  return (dispatch) => {
    axios.put(`${ROOT_URL}/users`, userArray)
      .then((response) => {
        if (userArrayType === 'Request') {
          dispatch({ type: ActionTypes.FETCH_USERS_REQUEST_TYPE, payload: response.data });
        } else if (userArrayType === 'Admin') {
          dispatch({ type: ActionTypes.FETCH_USERS_ADMIN_TYPE, payload: response.data });
        } else if (userArrayType === 'Edit') {
          dispatch({ type: ActionTypes.FETCH_USERS_EDIT_TYPE, payload: response.data });
        } else if (userArrayType === 'View') {
          dispatch({ type: ActionTypes.FETCH_USERS_VIEW_TYPE, payload: response.data });
        } else if (userArrayType === 'Creator') {
          dispatch({ type: ActionTypes.FETCH_USERS_CREATOR_TYPE, payload: response.data });
        }
      }).catch((error) => {
        console.log('fetch Users failed');
      });
  };
}

// Fetches timelines that User has edit permissionsKey.

export function fetchSpecificTimelineNodes(queryType, userID) {
  // axios.get(`${ROOT_URL}/tNodes/${queryType}${API_KEY}`).then((response) => {
  return (dispatch) => {
    let tempUserID = 'localUser';
    if (userID !== undefined) {
      tempUserID = userID;
    }
    axios.get(`${ROOT_URL}/tNodes/query/${queryType}/${tempUserID}${API_KEY}`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      dispatch({ type: ActionTypes.FETCH_SPECIFIC_TIMELINE_NODES, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch specific timeline nodes');
    });
  };
}

export function updateUser(userId, user, creatingTimelineNode, subscribingFeed, subscribing) {
  // const user = JSON.parse(localStorage.getItem('user'));
  const fields = {
    _id: user._id,
    email: user.email,
    username: user.username,
    password: user.password,
    permissionViewKeys: user.permissionViewKeys,
    permissionEditKeys: user.permissionEditKeys,
    permissionAdminKeys: user.permissionAdminKeys,
    creatorKeys: user.creatorKeys,
    creatorCNodes: user.creatorCNodes,
    creatorTNodes: user.creatorTNodes,
    cNodePoints: user.cNodePoints,
    tNodePoints: user.tNodePoints,
  };

  return (dispatch) => {
    axios.put(`${ROOT_URL}/users/${userId}`, fields, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        if (creatingTimelineNode) {
          fetchSpecificTimelineNodes('Creator')(dispatch);
          localStorage.setItem('user', JSON.stringify(fields));
        } else if (subscribingFeed) {
          fetchSpecificTimelineNodes('View')(dispatch);
          localStorage.setItem('user', JSON.stringify(fields));
        } else if (subscribing) {
          localStorage.setItem('user', JSON.stringify(fields));
        }
        dispatch({
          type: ActionTypes.UPDATE_USER,
          payload: response.data,
        });
      }).catch((error) => {
        console.error('updateUser failed');
      });
  };
}

export function fetchTimelineNodes() {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/tNodes${API_KEY}`).then((response) => {
      dispatch({ type: ActionTypes.FETCH_TIMELINE_NODES, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch timeline nodes');
    });
  };
}

export function fetchTimelineNodesByTag(tag) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/tNodes/tag/${tag}${API_KEY}`).then((response) => {
      dispatch({ type: ActionTypes.FETCH_TIMELINE_NODES, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch timeline nodes by tag');
    });
  };
}

export function fetchTimelineNodesByTagFeed(tag) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/tNodes/Feed/tag/${tag}${API_KEY}`).then((response) => {
      dispatch({ type: ActionTypes.FETCH_TIMELINE_NODES_EXPLORE_FEED, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch timeline nodes by tag');
    });
  };
}
export function fetchTimelineNode(id) { /* axios get */
  return (dispatch) => {
    axios.get(`${ROOT_URL}/tNodes/${id}${API_KEY}/`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      dispatch({ type: ActionTypes.FETCH_TIMELINE_NODE, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch timeline node ', id);
    });
  };
}

export function fetchUnAuthTimelineNode(id) { /* axios get */
  return (dispatch) => {
    axios.get(`${ROOT_URL}/unAuth/tNodes/${id}${API_KEY}/`).then((response) => {
      dispatch({ type: ActionTypes.FETCH_TIMELINE_NODE, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch unauthed timeline node ', id);
    });
  };
}

export function deleteTimelineNode(id, history, timelineId) { /* axios delete */
  return (dispatch) => {
    axios.delete(`${ROOT_URL}/tNodes/${id}${API_KEY}/`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      fetchTimelineNode(timelineId)(dispatch);
    }).catch((error) => {
      console.log('Failure to delete timeline node');
    });
  };
}

export function updateTimelineNode(id, tNode, dispatchObj, timelineId, newNode, queryType) { /* axios put */
  return (dispatch) => {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    const tNodeNew = tNode;
    tNodeNew.recentContributor = updatedUser.username;
    axios.put(`${ROOT_URL}/tNodes/${id}${API_KEY}/`, tNodeNew, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      if (queryType === 4) {
        fetchSpecificTimelineNodes('creatorTNodes')(dispatch);
      } else if (queryType === 5) {
        fetchSpecificTimelineNodes('creatorCNodes')(dispatch);
      } else if (queryType === undefined) {
        fetchSpecificTimelineNodes('Creator')(dispatch);
      }
      if (timelineId !== undefined) {
        fetchTimelineNode(timelineId)(dispatch);
      }
      let payload = response.data.newTNode;
      if (newNode !== undefined) {
        payload = newNode;
      }
      dispatch({ type: ActionTypes.VIEWING_NODE, payload });
    }).catch((error) => {
      console.log('Failure to update timeline node');
    });
  };
}


export function viewingNode(node) {
  return (dispatch) => {
    // history.push('/profile');
    if (node !== undefined) {
      dispatch({ type: ActionTypes.VIEWING_NODE, payload: node.node });
    }
  };
}


export function editNode(node) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.EDIT_NODE, payload: node });
  };
}

export function editNodeCancel(node) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.EDIT_NODE_CANCEL, payload: null });
  };
}
export function updateTimelineNodePermissionsRequests(id, tNode, userID) { /* axios put */
  let permissionRequestsTemp = [];
  let indexPermissionRequests = -1;
  const indexEdit = tNode.permissionEditKeys.indexOf(userID);
  const indexView = tNode.permissionViewKeys.indexOf(userID);
  const indexAdmin = tNode.permissionAdminKeys.indexOf(userID);
  if (tNode.permissionRequests !== undefined) {
    permissionRequestsTemp = tNode.permissionRequests;
    indexPermissionRequests = tNode.permissionRequests.indexOf(userID);
  }

  if (indexPermissionRequests === -1) {
    if (indexEdit === -1 && indexView === -1 && indexAdmin === -1) {
      permissionRequestsTemp.push(userID);
    }
  }
  const tNodeUpdated = tNode;
  tNodeUpdated.permissionRequests = permissionRequestsTemp;

  return (dispatch) => {
    axios.put(`${ROOT_URL}/tNodes/requests/${id}${API_KEY}/`, tNode, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      dispatch({ type: ActionTypes.VIEWING_NODE, payload: response.data.newTNode });
    }).catch((error) => {
      console.log('Failure to update timelinePermissionsRequests');
    });
  };
}


export function updateTimelineNodePermissions(id, tNode, keyType, userID, user) { /* axios put */
  const indexEdit = tNode.permissionEditKeys.indexOf(userID);
  const indexView = tNode.permissionViewKeys.indexOf(userID);
  const indexAdmin = tNode.permissionAdminKeys.indexOf(userID);

  const permissionsEditKeysTemp = tNode.permissionEditKeys;
  const permissionsAdminKeysTemp = tNode.permissionAdminKeys;
  const permissionsViewKeysTemp = tNode.permissionViewKeys;

  const userTemp = user;

  const indexUserEdit = userTemp.permissionEditKeys.indexOf(tNode._id);
  const indexUserView = userTemp.permissionViewKeys.indexOf(tNode._id);
  const indexUserAdmin = userTemp.permissionAdminKeys.indexOf(tNode._id);


  let permissionRequestsTemp = [];
  if (tNode.permissionRequests !== undefined) {
    permissionRequestsTemp = tNode.permissionRequests;
  }
  return (dispatch) => {
    if (keyType === 'Edit') {
      // remove from all other keyLists and add to selected keyList (prevents duplicates) for Timeline Permissions
      if (indexView !== -1) {
        permissionsViewKeysTemp.splice(indexView, 1);
      }
      if (indexAdmin !== -1) {
        permissionsAdminKeysTemp.splice(indexAdmin, 1);
      }
      if (indexEdit === -1) {
        permissionsEditKeysTemp.push(userID);
        if (tNode.permissionRequests !== undefined && tNode.permissionRequests.indexOf(userID) !== -1) {
          permissionRequestsTemp.splice(indexAdmin, 1);
        }
      }

      // remove from all other keyLists and add to selected keyList (prevents duplicates) for User Permissions

      if (indexUserView !== -1) {
        userTemp.permissionViewKeys.splice(indexUserView, 1);
      }
      if (indexUserAdmin !== -1) {
        userTemp.permissionAdminKeys.splice(indexUserAdmin, 1);
      }
      if (indexUserEdit === -1) {
        userTemp.permissionEditKeys.push(tNode._id);
        updateUser(userID, userTemp, false)(dispatch);
      }
    } else if (keyType === 'View') {
      if (indexEdit !== -1) {
        permissionsEditKeysTemp.splice(indexEdit, 1);
      }
      if (indexAdmin !== -1) {
        permissionsAdminKeysTemp.splice(indexAdmin, 1);
      }
      if (indexView === -1) {
        permissionsViewKeysTemp.push(userID);
        if (tNode.permissionRequests !== undefined && tNode.permissionRequests.indexOf(userID) !== -1) {
          permissionRequestsTemp.splice(indexAdmin, 1);
        }
      }

      // remove from all other keyLists and add to selected keyList (prevents duplicates) for User Permissions

      if (indexUserEdit !== -1) {
        userTemp.permissionEditKeys.splice(indexUserEdit, 1);
      }
      if (indexUserAdmin !== -1) {
        userTemp.permissionAdminKeys.splice(indexUserAdmin, 1);
      }
      if (indexUserView === -1) {
        userTemp.permissionViewKeys.push(tNode._id);
        updateUser(userID, userTemp, false)(dispatch);
      }
    } else if (keyType === 'Admin') {
      if (indexEdit !== -1) {
        permissionsEditKeysTemp.splice(indexEdit, 1);
      }
      if (indexView !== -1) {
        permissionsViewKeysTemp.splice(indexView, 1);
      }
      if (indexAdmin === -1) {
        permissionsAdminKeysTemp.push(userID);
        if (tNode.permissionRequests !== undefined && tNode.permissionRequests.indexOf(userID) !== -1) {
          permissionRequestsTemp.splice(indexAdmin, 1);
        }
      }
      // remove from all other keyLists and add to selected keyList (prevents duplicates) for User Permissions

      if (indexUserEdit !== -1) {
        userTemp.permissionEditKeys.splice(indexUserEdit, 1);
      }
      if (indexUserView !== -1) {
        userTemp.permissionViewKeys.splice(indexUserView, 1);
      }
      if (indexUserAdmin === -1) {
        userTemp.permissionAdminKeys.push(tNode._id);
        updateUser(userID, userTemp, false)(dispatch);
      }
    }

    const tNodeUpdated = tNode;
    tNodeUpdated.permissionEditKeys = permissionsEditKeysTemp;
    tNodeUpdated.permissionAdminKeys = permissionsAdminKeysTemp;
    tNodeUpdated.permissionViewKeys = permissionsViewKeysTemp;
    tNodeUpdated.permissionRequests = permissionRequestsTemp;

    axios.put(`${ROOT_URL}/tNodes/permissions/${id}${API_KEY}/`, tNodeUpdated, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      dispatch({ type: ActionTypes.VIEWING_NODE, payload: response.data.newTNode });
    }).catch((error) => {
      console.log('Failure to update timeline node permissions');
    });
  };
}

export function updateContentNode(id, cNode, timelineId) { /* axios put */
  const cNodeTemp = cNode;
  if (cNodeTemp.contentType === undefined) {
    cNodeTemp.contentType = 'Image';
  }

  return (dispatch) => {
    axios.put(`${ROOT_URL}/cNodes/${id}${API_KEY}/`, cNodeTemp, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      dispatch({ type: ActionTypes.UPDATE_CONTENT_NODE, payload: response.data });
      dispatch({ type: ActionTypes.VIEWING_NODE, payload: response.data });

      fetchTimelineNode(timelineId)(dispatch);
    }).catch((error) => {
      console.log('Failure to update content node');
    });
  };
}

export function createContentNode(cNode, history, selectedTimelinesObj, updateTimelineId) { /* axios post */
  return (dispatch) => {
    const user = localStorage.getItem('user');
    axios.post(`${ROOT_URL}/cNodes${API_KEY}`, cNode, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        const updatedUser = JSON.parse(user);
        updatedUser.creatorCNodes.push(response.data._id);
        updateUser(updatedUser._id, updatedUser, true)(dispatch);
        selectedTimelinesObj.forEach((timelineObj) => {
          const temp = [];
          let timeline = timelineObj;
          if (timelineObj.nodes.length > 0 && timelineObj.nodes[0].node !== undefined) {
            timelineObj.nodes.forEach((node) => {
              temp.push(node.node._id);
            });
            timeline.nodes = temp;
          } else {
            timeline = timelineObj;
          }

          timeline.nodes.push(response.data._id);
          updateTimelineNode(timeline._id, timeline, null, updateTimelineId, response.data)(dispatch);
        });
        dispatch({ type: ActionTypes.VIEWING_NODE, payload: response.data });
      }).catch((error) => {
        console.log('Failure to create content node');
      });
  };
}

export function createTimelineNode(tNode, history, selectedTimelinesObj, updateTimelineId, queryType) { /* axios post */
  return (dispatch) => {
    const permissionsNode = tNode;
    permissionsNode.permissionViewKeys = [];
    permissionsNode.permissionEditKeys = [];
    permissionsNode.permissionAdminKeys = [];
    // user added to creatorKey on backend
    permissionsNode.creatorKeys = [];
    permissionsNode.permissionRequests = [];
    const user = localStorage.getItem('user');
    axios.post(`${ROOT_URL}/tNodes${API_KEY}`, permissionsNode, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        const updatedUser = JSON.parse(user);
        updatedUser.creatorKeys.push(response.data._id);
        updatedUser.creatorTNodes.push(response.data._id);

        updateUser(updatedUser._id, updatedUser, true)(dispatch);
        selectedTimelinesObj.forEach((timelineObj, index) => {
          const temp = [];
          let timeline = timelineObj;
          if (timelineObj.nodes !== undefined) {
            if (timelineObj.nodes.length > 0 && timelineObj.nodes[0].node !== undefined) {
              timelineObj.nodes.forEach((node) => {
                temp.push(node.node._id);
              });
              timeline.nodes = temp;
            } else {
              fetchTimelineNode(updateTimelineId)(dispatch);
              timeline = timelineObj;
            }
            timeline.nodes.push(response.data._id);
            updateTimelineNode(timeline._id, timeline, null, updateTimelineId, response.data)(dispatch);
          }
        });
        if (queryType === 2) {
          fetchSpecificTimelineNodes('Admin')(dispatch);
        } else if (queryType === 3) {
          fetchSpecificTimelineNodes('Edit')(dispatch);
        }
        dispatch({ type: ActionTypes.VIEWING_NODE, payload: response.data });
      }).catch((error) => {
        console.log('Failure to create timeline node');
      });
  };
}

export function fetchContentNode(id) { /* axios get */
  return (dispatch) => {
    axios.get(`${ROOT_URL}/cNodes/${id}${API_KEY}/`).then((response) => {
      dispatch({ type: ActionTypes.FETCH_CONTENT_NODE, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch content node');
    });
  };
}

export function deleteContentNode(id, history, timelineId) { /* axios delete */
  return (dispatch) => {
    axios.delete(`${ROOT_URL}/cNodes/${id}${API_KEY}/`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      fetchTimelineNode(timelineId)(dispatch);
    }).catch((error) => {
      console.log('Failure to delete content node');
    });
  };
}


export function fetchCommentsWithChildren(commentID, dispatchObj, index) {
  if (dispatchObj !== null) {
    axios.get(`${ROOT_URL}/comments/${commentID}`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        if (index !== null) {
          const temp = response;
          temp.index = index;
          dispatchObj({ type: ActionTypes.FETCH_COMMENTSWITHCHILDRENINDEX, payload: temp });
        }
        dispatchObj({ type: ActionTypes.FETCH_COMMENTSWITHCHILDREN, payload: response.data });
      }).catch((error) => {
        console.log('Unable to fetch commentswithchildren');
      });
  } else {
    return (dispatch) => {
      axios.get(`${ROOT_URL}/comments/${commentID}`, { headers: { authorization: localStorage.getItem('token') } })
        .then((response) => {
          if (index !== null) {
            const temp = response;
            temp.index = index;
            dispatch({ type: ActionTypes.FETCH_COMMENTSWITHCHILDRENINDEX, payload: temp });
          }
          dispatch({ type: ActionTypes.FETCH_COMMENTSWITHCHILDREN, payload: response.data });
        }).catch((error) => {
          console.log('Unable to fetch commentswithchildren');
        });
    };
  }
  return commentID;
}


export function fetchComments(nodeID, dispatchObj, nodeType) {
  if (dispatchObj !== null) {
    axios.get(`${ROOT_URL}/${nodeType}/${nodeID}/comments`, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        dispatchObj({ type: ActionTypes.FETCH_COMMENTS, payload: response.data });
      }).catch((error) => {
        console.log('Unable to fetch comments');
      });
  } else {
    return (dispatch) => {
      axios.get(`${ROOT_URL}/${nodeType}/${nodeID}/comments`, { headers: { authorization: localStorage.getItem('token') } })
        .then((response) => {
          dispatch({ type: ActionTypes.FETCH_COMMENTS, payload: response.data });
        }).catch((error) => {
          console.log('Unable to fetch comments');
        });
    };
  }
  return nodeID;
}

export function updateComment(commentID, comment, nodeID, commentParentID, dispatchObj, index, nodeType) {
  if (dispatchObj !== null) {
    axios.put(`${ROOT_URL}/comments/${commentID}`, comment, { headers: { authorization: localStorage.getItem('token') } })
      .then((response) => {
        fetchComments(nodeID, dispatchObj, nodeType);
        if (commentParentID !== null) {
          fetchCommentsWithChildren(commentParentID, dispatchObj, index);
        }
        dispatchObj({ type: ActionTypes.UPDATE_COMMENT, payload: comment });
      }).catch((error) => {
        console.log('Unable to update comment');
      });
  } else {
    return (dispatch) => {
      axios.put(`${ROOT_URL}/comments/${commentID}`, comment, { headers: { authorization: localStorage.getItem('token') } })
        .then((response) => {
          fetchCommentsWithChildren(commentParentID, dispatch, index);
          fetchComments(nodeID, dispatch, nodeType);

          dispatch({ type: ActionTypes.UPDATE_COMMENT, payload: response.data });
        }).catch((error) => {
          console.log('Unable to update comment');
        });
    };
  }
  return commentID;
}

export function createComment(comment, commentParent, nodeID, index, nodeType) {
  return (dispatch) => {
    if (commentParent !== null) {
      axios.post(`${ROOT_URL}/comments/${commentParent}`, comment, { headers: { authorization: localStorage.getItem('token') } })
        .then((response) => {
          const temp = commentParent;
          temp.commentArray.push(response.data);
          updateComment(commentParent._id, temp, nodeID, commentParent._id, dispatch, index, nodeType);
          // updateComment(commentParent._id, temp, nodeID, commentParent._id, null, index)(dispatch);
        }).catch((error) => {
          console.error('Error: function createComment did not execute successfully.');
        });
    } else {
      axios.post(`${ROOT_URL}/${nodeType}/${nodeID}/comments`, comment, { headers: { authorization: localStorage.getItem('token') } })
        .then((response) => {
          fetchComments(nodeID, dispatch, nodeType);
        }).catch((error) => {
          console.error('Error: function createComment did not execute successfully.');
        });
    }
  };
}

export function deleteComment(commentID, comment, nodeID, commentParentID, index, nodeType) {
  return (dispatch) => {
    if (commentParentID !== null) {
      axios.delete(`${ROOT_URL}/comments/${commentID}`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
        const temp = comment;
        for (let i = (temp.commentArray.length - 1); i >= 0; i -= 1) {
          if (temp.commentArray[i] === null || temp.commentArray[i]._id === commentID) {
            temp.commentArray.splice(i, 1);
          }
        }
        updateComment(commentParentID, temp, nodeID, commentParentID, dispatch, index, nodeType);
        dispatch({
          type: ActionTypes.DELETE_COMMENT,
          payload: response.data,
        });
      }).catch((error) => {
        console.error('Error: function deleteComment did not execute successfully.');
      });
    } else {
      axios.delete(`${ROOT_URL}/comments/${commentID}`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
        fetchComments(nodeID, dispatch, nodeType);
        dispatch({
          type: ActionTypes.DELETE_COMMENT,
          payload: response.data,
        });
      }).catch((error) => {
        console.error('Error: function deleteComment did not execute successfully.');
      });
    }
  };
}

export function fetchComment(commentID) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/comments/${commentID}`)
      .then((response) => {
        dispatch({ type: ActionTypes.FETCH_COMMENT, payload: response.data });
      }).catch((error) => {
        console.log('Unable to fetch comment');
      });
  };
}


// trigger to deauth if there is error
// can also use in your error reducer if you have one to display an error message
export function authError(error) {
  return {
    type: ActionTypes.AUTH_ERROR,
    message: error,
  };
}

export function signinUser({ email, password }, history) {
  const user = { email, password };
  return (dispatch) => {
    axios.post(`${ROOT_URL}/signin`, user)
      .then((response) => {
        dispatch({ type: ActionTypes.AUTH_USER });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        history.push('/Explore');
      }).catch((error) => {
        dispatch({
          type: ActionTypes.AUTH_ERROR,
          payload: 'Invalid email and password combination.',
        });
      });
  };
}

export function signupUser({ email, password, username }, history) {
  const user = { email, password, username };
  return (dispatch) => {
    axios.post(`${ROOT_URL}/signup`, user)
      .then((response) => {
        dispatch({ type: ActionTypes.AUTH_USER, payload: response.data.user });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.newUser));
        history.push('/Explore');
      }).catch((error) => {
        dispatch({
          type: ActionTypes.AUTH_ERROR,
          payload: 'Please provide a unique email and username.',
        });
      });
  };
}


// deletes token from localstorage
// and deauths
export function signoutUser(history) {
  return (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    dispatch({ type: ActionTypes.DEAUTH_USER });
    history.push('/');
  };
}

export function handleTimelineHistory(queryType, timelinePath, timelineId) {
  return (dispatch) => {
    let tempRoute;
    if (queryType === 'user') {
      tempRoute = `/u/${timelinePath}`;
    } else if (queryType === 'node') {
      // fetchTimelineNode(timelinePath)(dispatch);
      // fetchContentNode(timelinePath)(dispatch);
      tempRoute = `/n/${timelinePath}/${timelinePath}`;
    } else if (queryType === 'Profile' || queryType === 'Feed') {
      if (timelinePath === undefined) {
        tempRoute = `/q/${queryType}`;
      } else {
        tempRoute = `/q/${queryType}/${timelinePath}`;
      }
    } else if (queryType === 'Explore') {
      tempRoute = '/Explore';
    }
    dispatch({ type: ActionTypes.HANDLE_TIMELINE_HISTORY, payload: tempRoute });
  };
}

export function handleTimelineHistoryIndex(timelinePathIndex, reset) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.HANDLE_TIMELINE_HISTORY_INDEX, payload: { timelinePathIndex, reset } });
  };
}


export function findTNodes(search, tag) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/tNodes/tagSearch/${tag}/search?query=${search}/`).then((response) => {
      dispatch({ type: ActionTypes.FETCH_TIMELINE_NODES, payload: response.data });
    }).catch((error) => {
      console.log('Error in findTNodes: Search Failed');
    });
  };
}

export function findUsers(search) {
  return (dispatch) => {
    axios.get(`${ROOT_URL}/users/search?query=${search}`).then((response) => {
      dispatch({ type: ActionTypes.FIND_USERS, payload: response.data });
    }).catch((error) => {
      console.log('Error in findUsers: Search Failed');
    });
  };
}

export function handleIsFirstMount(bool) {
  return (dispatch) => {
    dispatch({ type: ActionTypes.HANDLE_IS_FIRST_MOUNTED, payload: bool });
  };
}

export function clearErrorState() {
  return { type: ActionTypes.CLEAR_ERROR, payload: '' };
}


// specialized route without Permissions to update Timeline Tokens
export function updateTimelineNodeToken(id, tNode, timelineId) { /* axios put */
  return (dispatch) => {
    axios.put(`${ROOT_URL}/tNodes/tokens/${id}${API_KEY}/`, tNode, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      fetchTimelineNode(timelineId)(dispatch);
      dispatch({ type: ActionTypes.VIEWING_NODE, payload: response.data.newTNode });
    }).catch((error) => {
      console.log('Failure to update timeline node Token');
    });
  };
}

// allows local user to upVote or downVote a timeline
// updates the timeline TokenCount and pushes or splices localUser id from upToken and downToken arrays

export function tokenVote(voteBool, node, nodeType, timelineId) {
  const tempNode = node;
  const userTemp = localStorage.getItem('user');
  const localUser = JSON.parse(userTemp);
  if (tempNode.tokenCount === undefined) {
    tempNode.tokenCount = 0;
    tempNode.upToken = [];
    tempNode.downToken = [];
  }
  if (voteBool) {
    if (tempNode.upToken.indexOf(localUser._id) === -1) {
      tempNode.upToken.push(localUser._id);
      if (tempNode.downToken.indexOf(localUser._id) > -1) {
        tempNode.downToken.splice(tempNode.downToken.indexOf(localUser._id), 1);
      }
    }
  } else if (tempNode.downToken.indexOf(localUser._id) === -1) {
    tempNode.downToken.push(localUser._id);
    if (tempNode.upToken.indexOf(localUser._id) > -1) {
      tempNode.upToken.splice(tempNode.upToken.indexOf(localUser._id), 1);
    }
  }
  tempNode.tokenCount = tempNode.upToken.length - tempNode.downToken.length;
  return (dispatch) => {
    if (nodeType === 'Timeline') {
      updateTimelineNodeToken(node._id, tempNode, timelineId)(dispatch);
    } else if (nodeType === 'Content') {
      updateContentNode(node._id, tempNode, timelineId)(dispatch);
    }
  };
}


// get user's total points for created nodes in two counts: content Nodes and timeline Nodes.
export function fetchUserPoints() { /* axios put */
  return (dispatch) => {
    axios.get(`${ROOT_URL}/users/points/${API_KEY}/`, { headers: { authorization: localStorage.getItem('token') } }).then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data.user));

      dispatch({ type: ActionTypes.FETCH_USER_POINTS, payload: response.data });
    }).catch((error) => {
      console.log('Failure to fetch User Points');
    });
  };
}
