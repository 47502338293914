import React from 'react';
import '../../styles/style.scss';


class UserNodeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queryType: props.queryContributorModeratorType,
    };
    this.renderUserInformation = this.renderUserInformation.bind(this);
  }


  renderUserInformation() {
    if (this.props.user !== false && this.props.user.creatorKeys !== undefined) {
      const { user } = this.props;
      return (
        <div className="profileQueryNodeView">
          <div id="profileSectionNavigation">
            <button onClick={() => { this.props.handleQueryTypeToggle(1); this.setState({ queryType: 1 }); this.props.fetchSpecificTimelineNodes('Creator', user.id); }} id={this.state.queryType === 1 ? 'profileNavigationButtonSelected' : 'profileNavigationButton'}>
              <div id="buttonDetail">{user.creatorKeys.length}</div> Profile Timelines
            </button>
            <button onClick={() => { this.props.handleQueryTypeToggle(4); this.setState({ queryType: 4 }); this.props.fetchSpecificTimelineNodes('creatorTNodes', user.id); }} id={this.state.queryType === 4 ? 'profileNavigationButtonSelected' : 'profileNavigationButton'}>
              <div id="buttonDetail">{user.creatorTNodes.length}</div> Created Timelines
            </button>
            <button onClick={() => { this.props.handleQueryTypeToggle(5); this.setState({ queryType: 5 }); this.props.fetchSpecificTimelineNodes('creatorCNodes', user.id); }} id={this.state.queryType === 5 ? 'profileNavigationButtonSelected' : 'profileNavigationButton'}>
              <div id="buttonDetail">{user.creatorCNodes.length}</div> Created Media
            </button>
            <button onClick={() => { this.props.handleQueryTypeToggle(2); this.setState({ queryType: 2 }); this.props.fetchSpecificTimelineNodes('Admin', user.id); }} id={this.state.queryType === 2 ? 'profileNavigationButtonSelected' : 'profileNavigationButton'}>
              <div id="buttonDetail">{user.permissionAdminKeys.length}</div> Moderator Timelines
            </button>
            <button onClick={() => { this.props.handleQueryTypeToggle(3); this.setState({ queryType: 3 }); this.props.fetchSpecificTimelineNodes('Edit', user.id); }} id={this.state.queryType === 3 ? 'profileNavigationButtonSelected' : 'profileNavigationButton'}>
              <div id="buttonDetail">{user.permissionEditKeys.length}</div> Contributor Timelines
            </button>
          </div>
          <div className="contentQueryNodeView">
            <div id="profileNodeViewHeaderContainer" className="profileNodeViewHeader">
              <div id="profileNodeViewHeaderDetail">03</div>
              <div id="profileNodeViewHeader">{`${user.username}`}</div>
              <div>{ `(Story Points: ${user.tNodePoints}, Media Points: ${user.cNodePoints})`}</div>

            </div>
            <div id="profileNodeViewInfoContainer">
              <div id="profileNodeViewInfoImage">
                <img alt="logo-icon" src="/public/images/logoYellow.png" />
              </div>
              <div id="profileNodeViewInfoContent">

                <div>{user.creatorKeys.length} {user.creatorKeys.length === 1 ? 'Timeline' : 'Timelines'} Created</div>
                <div>{user.permissionViewKeys.length} {user.permissionViewKeys.length === 1 ? 'Timeline' : 'Timelines'} Followed</div>
              </div>

            </div>
          </div>

        </div>
      );
    }
    return 1;
  }


  render() {
    return (
      <div className="QueryNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        { this.renderUserInformation()}
      </div>
    );
  }
}

export default UserNodeView;
