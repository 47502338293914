import { ActionTypes } from '../actions';

const initialState = {
  comments: [],
  comment: {},
  commentChildren: [],
  commentChildrenIndex: [],

};

const CommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COMMENTS:
      return { ...state, comments: action.payload };
    case ActionTypes.FETCH_COMMENT:
      return { ...state, comment: action.payload };
    case ActionTypes.FETCH_COMMENTSWITHCHILDREN:
      return { ...state, commentChildren: action.payload };
    case ActionTypes.FETCH_COMMENTSWITHCHILDRENINDEX:
    {
      const temp = state.commentChildrenIndex;
      temp[action.payload.index] = action.payload.data;
      return Object.assign({}, state, { commentChildrenIndex: temp });
    }
    default:
      return state;
  }
};

export default CommentsReducer;
