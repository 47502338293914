import { ActionTypes } from '../actions';

const initialState = {
  timelineNodes: [],
  timelineNode: {},
  timelineHistory: [],
  timelineHistoryIndex: 0,
  timelineNodeExploreFeed: [],
};

const TimelineNodeReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_TIMELINE_NODES:
      return Object.assign({}, state, { timelineNodes: action.payload });
    case ActionTypes.FETCH_TIMELINE_NODE:
      return Object.assign({}, state, { timelineNode: action.payload });
    case ActionTypes.FETCH_TIMELINE_NODES_EXPLORE_FEED:
      return Object.assign({}, state, { timelineNodeExploreFeed: action.payload });
    case ActionTypes.CREATE_TIMELINE_NODE:
      return Object.assign({}, state, { timelineNodes: action.payload.all });
    case ActionTypes.DELETE_TIMELINE_NODE:
      return Object.assign({}, state, { timelineNodes: action.payload, timelineNode: {} });
    case ActionTypes.UPDATE_TIMELINE_NODE:
      return Object.assign({}, state, { timelineNode: action.payload });
    case ActionTypes.FETCH_SPECIFIC_TIMELINE_NODES:
      return { ...state, timelineNodes: action.payload };
    case ActionTypes.HANDLE_TIMELINE_HISTORY:
    {
      const index = state.timelineHistory.indexOf(action.payload);
      let temp = state.timelineHistory;

      if (index === -1) {
        if (state.timelineHistoryIndex !== temp.length) {
          const temp2 = temp;
          temp = temp2.slice(0, state.timelineHistoryIndex);
        }
        temp.push(action.payload);
      }
      // this.props.viewNode({ node: this.props.timelineNode });
      // this.setState({ nodePosition: 0 });
      // this.props.handleNodePosition(0);

      return Object.assign({}, state, { timelineHistory: temp });
    }
    case ActionTypes.HANDLE_TIMELINE_HISTORY_INDEX:
    {
      let temp = state.timelineHistoryIndex;

      if (temp >= 0 && action.payload.reset === undefined) {
        temp += action.payload.timelinePathIndex;
      } else {
        return { ...state, timelineHistoryIndex: 0 };
      }

      return { ...state, timelineHistoryIndex: temp };
    }
    default:
      return state;
  }
};

export default TimelineNodeReducers;
