import React from 'react';
import { NavLink } from 'react-router-dom';
import marked from 'marked';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import Modal from 'react-modal';

import 'react-toastify/dist/ReactToastify.css';

import CommentSection from '../containers/commentSection';
import ProfileNodeView from '../components/QueryNodeViews/ProfileNodeView';
import FeedNodeView from '../components/QueryNodeViews/FeedNodeView';
import UserNodeView from '../components/QueryNodeViews/UserNodeView';
import ExploreNodeView from '../components/QueryNodeViews/ExploreNodeView';

import LandingLoginNodeView from '../components/LandingNodeViews/LandingLoginNodeView';
import LandingStoryNodeView from '../components/LandingNodeViews/LandingStoryNodeView';
import LandingCreateNodeView from '../components/LandingNodeViews/LandingCreateNodeView';
import LandingPermissionsNodeView from '../components/LandingNodeViews/LandingPermissionsNodeView';
import LandingVisualEssayNodeView from '../components/LandingNodeViews/LandingVisualEssayNodeView';
import LandingExploreNodeView from '../components/LandingNodeViews/LandingExploreNodeView';
import PermissionsNodeView from '../components/PermissionsNodeViews/PermissionsNodeView';

import '../styles/style.scss';

class ContentView extends React.Component {
  constructor() {
    super();
    this.state = {
      imageFile: '',
      title: 'Record Atlas Login | Sign Up',
      date: '',
      desc: '',
      permissions: '',
      link: {},
      contentType: 'Image',
    };
    this.renderNodeInfo = this.renderNodeInfo.bind(this);
    this.renderNodeViewQuery = this.renderNodeViewQuery.bind(this);
    this.renderRemoveModal = this.renderRemoveModal.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.viewNodeObj !== null && props.viewNodeObj !== undefined && props.viewNodeObj !== {}) {
      if (props.viewNodeObj.imageFile) {
        this.setState({
          imageFile: props.viewNodeObj.imageFile,
        });
      } else {
        this.setState({
          imageFile: '',
        });
      }
      let dateString, monthString;
      if (props.viewNodeObj.date !== undefined) {
        const date = new Date(props.viewNodeObj.date.toString());
        const month = [];
        month[0] = 'Jan';
        month[1] = 'Feb';
        month[2] = 'Mar';
        month[3] = 'Apr';
        month[4] = 'May';
        month[5] = 'Jun';
        month[6] = 'Jul';
        month[7] = 'Aug';
        month[8] = 'Sep';
        month[9] = 'Oct';
        month[10] = 'Nov';
        month[11] = 'Dec';
        monthString = month[date.getMonth()];
        dateString = `${date.getFullYear()} ${monthString} ${date.getDate()} `;
        this.setState({ date: dateString.toString() });
      }

      this.setState({
        title: props.viewNodeObj.title,
        desc: props.viewNodeObj.desc,
        permissions: props.viewNodeObj.permissions,
        link: props.viewNodeObj.link,
        contentType: props.viewNodeObj.contentType,

      });
      if (props.viewNodeObj.author !== undefined) {
        this.setState({
          authorID: props.viewNodeObj.author._id,
        });
      }
    }
    if (props.queryType === 'Profile' && (props.mount || props.nodePosition === 0)) {
      this.setState({ title: 'User Profile' });
      this.setState({ date: 'User Profile' });
    } else if (props.queryType === 'Feed' && (props.mount || props.nodePosition === 0)) {
      this.setState({ title: 'User Feed' });
      this.setState({ date: 'User Feed' });
    } else if (props.queryType === 'Explore' && (props.mount || props.nodePosition === 0)) {
      this.setState({ title: 'Explore' });
      this.setState({ date: 'Explore' });
    } else if (props.userQuery && (props.mount || props.nodePosition === 0)) {
      this.setState({ title: 'User Profile' });
      this.setState({ date: 'User Profile' });
    } else if (props.queryType === 'Landing-1') {
      this.setState({ title: 'Record Atlas Login | Sign Up' });
      this.setState({ date: '' });
    } else if (props.queryType === 'Landing-2') {
      this.setState({ title: 'Story' });
    } else if (props.queryType === 'Landing-3') {
      this.setState({ title: 'Create And Organize' });
    } else if (props.queryType === 'Landing-4') {
      this.setState({ title: 'Permissions' });
    } else if (props.queryType === 'Landing-5') {
      this.setState({ title: 'Visual Essays' });
    } else if (props.queryType === 'Landing-6') {
      this.setState({ title: 'Explore' });
    } else if (props.queryType === 'node' && (!props.mount && props.nodePosition === 0)) {
      this.setState({
        imageFile: props.timelineNode.imageFile,
        title: props.timelineNode.title,
        desc: props.timelineNode.desc,
        permissions: props.timelineNode.permissions,
        link: props.viewNodeObj.link,

      });
      if (props.viewNodeObj.author !== undefined) {
        this.setState({
          authorID: props.viewNodeObj.author._id,
        });
      }
    }
  }

  handleSubmit(event) {
    this.event.preventDefault();
  }
  // toastId = null;
  //
  // notify = () => {
  //   if (!toast.isActive(this.toastId)) {
  //     this.toastId = toast('Please Login Or Sign Up To Continue', {
  //       className: 'toast-primary-background',
  //       bodyClassName: 'toast-font-size',
  //       progressClassName: 'toast-progress-bar',
  //     });
  //   }
  // }

  notify = () => {
    toast('Please Sign Up or Login To Access This Feature', {
      className: css({
        background: '#e7ae00;',
      }),
      bodyClassName: css({
        fontSize: '13px',
        color: 'white',
      }),
      progressClassName: css({
        background: '#f6bd10;',
        height: '10px',
      }),
    });
  };

  handleOpenModal(node, position) {
    this.setState({
      showModal: true,
    });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  renderRemoveModal(node) {
    return (
      <div>
        <button
          className="subscribeTimelineButton"
          onClick={() => {
              this.handleOpenModal(node);
            }}
        >
            Remove
        </button>

        <button
          id="modalHiddenButton"
        >
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={() => {
              this.handleCloseModal();
            }}

            className="Modal"
            overlayClassName="Overlay"
            ariaHideApp={false}
          >
            <div id="removeModalHeader">  Remove this timeline?</div>
            <p id="removeModalText">
                Are you sure you want to remove {node !== undefined ? node.title : ''}?
            </p>
            <div id="removeModalButtonContainer">
              <button className="modalButton"
                onClick={() => this.handleCloseModal()}
              >Cancel
              </button>
              <button
                className="modalButton"
                id="removeModalButton"
                onClick={() => {
                this.props.handleRemoveClick(node);
                this.handleCloseModal();
              }}
              >
                Remove
              </button>
            </div>
          </Modal>
        </button>
      </div>
    );
  }

  renderNodeDifferences() {
    // unauthed case

    if (!this.props.auth) {
      if (this.state.permissions === true || this.state.permissions === false) {
        return (

          <div className="timelineNodeViewButtonContainer">
            <div id="nodeViewTokenVoteContainer">
              <button
                onClick={this.notify}
              > <img className="voteArrow" alt="nav-Arrow" src="/public/images/nav-Arrow-Black.png" />
              </button>
              {this.props.viewNodeObj.tokenCount !== undefined ? this.props.viewNodeObj.tokenCount : 0}

              <button
                onClick={this.notify}
              > <img className="voteArrow" id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow-Black.png" />
              </button>

            </div>
            {this.props.viewing ?
              <button
                id="timelineClickthroughViewing"
              >
                Viewing
              </button>
          :
              <NavLink to={`/n/${this.props.viewNodeObj._id}/${this.props.viewNodeObj._id}`}
                className="timelineClickthrough"
                onClick={() => {
                  this.props.timelineClickthrough(this.props.viewNodeObj);
                  this.props.handleTimelineHistoryIndex(1);
                  this.props.handleTimelineHistory('node', this.props.viewNodeObj._id);
                 }}
              >
                  View
              </NavLink>
        }
            <button
              className="subscribeTimelineButton"
              value={this.props.viewNodeObj}
              onClick={this.notify}
            >
            Subscribe
            </button>

            <button className="subscribeTimelineButton"
              onClick={this.notify}
            >
              Permissions
            </button>

            <div className="commonButtonNodeContainerHidden" />
            {this.renderNodeInfo(true)}

          </div>
        );
      }
      return (
        <div id="cNodeContentContainer">
          <div id="nodeViewTokenVoteContainer">
            <button
              onClick={this.notify}
            > <img className="voteArrow" alt="nav-Arrow" src="/public/images/nav-Arrow-Black.png" />
            </button>
            {this.props.viewNodeObj.tokenCount !== undefined ? this.props.viewNodeObj.tokenCount : 0}

            <button
              onClick={this.notify}
            > <img className="voteArrow" id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow-Black.png" />
            </button>
          </div>
          <div id="cNodeContentContainerSub">

            <div className="commonButtonNodeContainerHidden" />
            {this.renderNodeInfo(true)}

          </div>
        </div>
      );

      // authed case
    } else {
      const user = localStorage.getItem('user');
      const updatedUser = JSON.parse(user);
      let permissionsIndex = false;
      if (updatedUser.permissionAdminKeys.indexOf(this.props.viewNodeObj._id) > -1 ||
          updatedUser.creatorKeys.indexOf(this.props.viewNodeObj._id) > -1) {
        permissionsIndex = true;
      }
      if (this.props.viewNodeObj.author !== undefined && (this.props.viewNodeObj.author._id === updatedUser._id)) {
        permissionsIndex = true;
      }
      if ((this.state.permissions === true || this.state.permissions === false) && this.props.viewNodeObj.creatorKeys !== undefined) {
        let following = false;
        if (updatedUser.permissionViewKeys.indexOf(this.props.viewNodeObj._id) !== -1) {
          following = true;
        }

        let permissionsGranted = false;
        if (this.props.viewNodeObj.permissionAdminKeys.indexOf(updatedUser._id) !== -1 || this.props.viewNodeObj.creatorKeys.indexOf(updatedUser._id) !== -1
          || this.props.viewNodeObj.permissionViewKeys.indexOf(updatedUser._id) !== -1 || this.props.viewNodeObj.permissionEditKeys.indexOf(updatedUser._id) !== -1
        ) {
          permissionsGranted = true;
        }
        let permissionsUpdate = false;
        if (this.props.viewNodeObj.permissionAdminKeys.indexOf(updatedUser._id) !== -1 || this.props.viewNodeObj.creatorKeys.indexOf(updatedUser._id) !== -1) {
          permissionsUpdate = true;
        }

        let permissionsRequested = false;
        if (this.props.viewNodeObj.permissionRequests !== undefined && this.props.viewNodeObj.permissionRequests.indexOf(updatedUser._id) !== -1) {
          permissionsRequested = true;
        }


        return (
          <div className="timelineNodeViewButtonContainer">
            <div id="nodeViewTokenVoteContainer">
              <button
                onClick={() => { this.props.tokenVote(true, this.props.viewNodeObj, 'Timeline', this.props.timelineNode._id); }}
              > <img className="voteArrow" alt="nav-Arrow" src={this.props.viewNodeObj.upToken !== undefined && this.props.viewNodeObj.upToken.indexOf(updatedUser._id) > -1 ? '/public/images/nav-Arrow-Yellow.png' : '/public/images/nav-Arrow-Black.png'} />
              </button>
              {this.props.viewNodeObj.tokenCount !== undefined ? this.props.viewNodeObj.tokenCount : 0}

              <button
                onClick={() => { this.props.tokenVote(false, this.props.viewNodeObj, 'Timeline', this.props.timelineNode._id); }}
              > <img className="voteArrow" id="arrowLeft" alt="nav-Arrow" src={this.props.viewNodeObj.downToken !== undefined && this.props.viewNodeObj.downToken.indexOf(updatedUser._id) > -1 ? '/public/images/nav-Arrow-Yellow.png' : '/public/images/nav-Arrow-Black.png'} />
              </button>

            </div>
            {this.props.viewing ?
              <button
                id="timelineClickthroughViewing"
              >
          Viewing
              </button>
          :
              <NavLink to={`/n/${this.props.viewNodeObj._id}/${this.props.viewNodeObj._id}`}
                className="timelineClickthrough"
                onClick={() => {
              this.props.timelineClickthrough(this.props.viewNodeObj);
              this.props.handleTimelineHistoryIndex(1);
              this.props.handleTimelineHistory('node', this.props.viewNodeObj._id);
             }}
              >
              View
              </NavLink>
        }

            {following ?
              <button
                className="subscribeTimelineButton"
                id="buttonSubscribed"
                value={this.props.viewNodeObj}
                onClick={() => { this.props.unSubscribeTimeline(this.props.viewNodeObj); }}
              >
              &#10003; Subscribed
              </button>
             :
              <button
                className="subscribeTimelineButton"
                value={this.props.viewNodeObj}
                onClick={() => { this.props.subscribeTimeline(this.props.viewNodeObj._id); }}
              >
            Subscribe
              </button>
            }

            {permissionsGranted || !this.props.viewNodeObj.permissions ?

          this.props.permissionsNodeView ?
            <button className="subscribeTimelineButton"
              onClick={() => { this.props.handlePermissionsNodeView(false); }}
            >
            Close

            </button>
          :
            <button className="subscribeTimelineButton"
              onClick={() => { this.props.handlePermissionsNodeView(true); }}
            >
            Permissions

            </button>
         : ''}

            {!this.props.viewNodeObj.permissions || permissionsUpdate || permissionsGranted ? '' :
          permissionsRequested ?
            <button className="subscribeTimelineButton">
          Requested
            </button>
          :
            <button className="subscribeTimelineButton"
              onClick={() => { this.props.updateTimelineNodePermissionsRequests(this.props.viewNodeObj._id, this.props.viewNodeObj, updatedUser._id); }}
            >
          Request Permissions
            </button>
        }

            {(permissionsIndex && (this.props.queryType === 'Profile' || this.props.queryType === 'node') && !this.props.mount) ?
              <div className="commonButtonNodeContainer">
                <button
                  className="subscribeTimelineButton"
                  value={this.props.viewNodeObj}
                  onClick={() => { this.props.handleEditClick(this.props.viewNodeObj); }}
                >
                        Edit
                </button>
                {this.renderRemoveModal(this.props.viewNodeObj)}

              </div>
          :
              <div className="commonButtonNodeContainerHidden" />}
            {this.renderNodeInfo(true)}

          </div>
        );
      }
      return (
        <div id="cNodeContentContainer">
          <div id="nodeViewTokenVoteContainer">
            <button
              onClick={() => { this.props.tokenVote(true, this.props.viewNodeObj, 'Content', this.props.timelineNode._id); }}
            > <img className="voteArrow" alt="nav-Arrow" src={this.props.viewNodeObj.upToken !== undefined && this.props.viewNodeObj.upToken.indexOf(updatedUser._id) > -1 ? '/public/images/nav-Arrow-Yellow.png' : '/public/images/nav-Arrow-Black.png'} />
            </button>
            {this.props.viewNodeObj.tokenCount !== undefined ? this.props.viewNodeObj.tokenCount : 0}

            <button
              onClick={() => { this.props.tokenVote(false, this.props.viewNodeObj, 'Content', this.props.timelineNode._id); }}
            > <img className="voteArrow" id="arrowLeft" alt="nav-Arrow" src={this.props.viewNodeObj.downToken !== undefined && this.props.viewNodeObj.downToken.indexOf(updatedUser._id) > -1 ? '/public/images/nav-Arrow-Yellow.png' : '/public/images/nav-Arrow-Black.png'} />
            </button>
          </div>
          <div id="cNodeContentContainerSub">
            {(permissionsIndex && (this.props.queryType === 'Profile' || this.props.queryType === 'node') && !this.props.mount) ?
              <div className="commonButtonNodeContainer">
                <button
                  className="subscribeTimelineButton"
                  value={this.props.viewNodeObj}
                  onClick={() => { this.props.handleEditClick(this.props.viewNodeObj); }}
                >
                      Edit
                </button>
                {this.renderRemoveModal(this.props.viewNodeObj)}

              </div>
        :
              <div className="commonButtonNodeContainerHidden" />}
            {this.renderNodeInfo(true)}

          </div>
        </div>
      );
    }
  }

  renderNodeInfo(query) {
    return (
      <div>
        <div className="commonButtonNodeContainerHidden" />
        {query ?
          <div className="nodeInfoContainer">

            {this.state.authorID !== undefined && !this.props.mount ?
              <NavLink id="nodeViewUserNameLink"to={`/u/${this.state.authorID}`} >
                <p >{this.props.viewNodeObj.author !== undefined ? `${this.props.viewNodeObj.author.username} (${this.props.viewNodeObj.author.cNodePoints + this.props.viewNodeObj.author.tNodePoints})` : '' }</p>
              </NavLink> :
              <p>Record Atlas</p>
            }

            <p>{this.state.date}</p>
          </div>
          : ''
        }


      </div>
    );
  }

  renderNodeViewQuery() {
    if (this.props.queryType === 'Profile' && (this.props.mount || this.props.nodePosition === 0)) {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <ProfileNodeView
                handleQueryTypeToggle={this.props.handleQueryTypeToggle}
                queryContributorModeratorType={this.props.queryContributorModeratorType}
                fetchSpecificTimelineNodes={this.props.fetchSpecificTimelineNodes}
                auth={this.props.auth}
              />
            </div>
            {this.renderNodeInfo(true)}
            <div>
              <div className="nodeDescription" >
              Rather than a single story, each individual is made up of a portfolio of stories, and each story is made up of a series of events and experiences. By allowing each individual to have multiple timelines, one timeline for each of their stories, the specific topic becomes the fabric that ties the nodes of content together, upholding continuity to ultimately form narrative.

              The portfolio timeline is the overarching timeline of an individual with all of a user’s timelines.
              </div>
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Feed' && (this.props.mount || this.props.nodePosition === 0)) {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <FeedNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            A collection of all the stories you follow.
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Explore' && (this.props.mount || this.props.nodePosition === 0)) {
      return (
        <div id="exploreContentContainer">
          <ExploreNodeView
            closedContributorTNodeArray={this.props.closedContributorTNodeArray}
            openContributorTNodeArray={this.props.openContributorTNodeArray}
            viewNodeObj={this.props.viewNodeObj}
            viewing={this.props.viewing}
            timelineClickthrough={this.props.timelineClickthrough}
            handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
            handleTimelineHistory={this.props.handleTimelineHistory}
            tokenVote={this.props.tokenVote}
            exploreSubType={this.props.exploreSubType}
            tempAlbumTNodeArray={this.props.tempAlbumTNodeArray}
          />
          {this.renderNodeInfo(true)}

          <div className="nodeDescription" >
            Record Atlas is a public knowledge project that shares the stories from different people and groups around the world. Navigate and explore Record Atlas for the stories that interest you. Explore the curious to inspire the wandering mind.
          </div>
        </div>
      );
    } else if (this.props.queryType === 'Landing-1') {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <LandingLoginNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Record Atlas believes in the notion of Homo Narrans -
            humankind as storyteller, implying that culture in general
          and the fabric of meaning that constitutes a single human
          existence is the story we tell about ourselves.
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Landing-2') {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <LandingStoryNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Record Atlas believes in the notion of Homo Narrans -
            humankind as storyteller, implying that culture in general
          and the fabric of meaning that constitutes a single human
          existence is the story we tell about ourselves.
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Landing-3') {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <LandingCreateNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Record Atlas believes in the notion of Homo Narrans -
            humankind as storyteller, implying that culture in general
          and the fabric of meaning that constitutes a single human
          existence is the story we tell about ourselves.
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Landing-4') {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <LandingPermissionsNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Record Atlas believes in the notion of Homo Narrans -
            humankind as storyteller, implying that culture in general
          and the fabric of meaning that constitutes a single human
          existence is the story we tell about ourselves.
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Landing-5') {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <LandingVisualEssayNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Record Atlas believes in the notion of Homo Narrans -
            humankind as storyteller, implying that culture in general
          and the fabric of meaning that constitutes a single human
          existence is the story we tell about ourselves.
            </div>
          </div>

        </div>

      );
    } else if (this.props.queryType === 'Landing-6') {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <LandingExploreNodeView />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Record Atlas believes in the notion of Homo Narrans -
            humankind as storyteller, implying that culture in general
          and the fabric of meaning that constitutes a single human
          existence is the story we tell about ourselves.
            </div>
          </div>

        </div>

      );
    } else if (this.props.userQuery && (this.props.mount || this.props.nodePosition === 0)) {
      return (
        <div>
          <div id="contentContainer">
            <div>
              <UserNodeView
                user={this.props.user}
                handleQueryTypeToggle={this.props.handleQueryTypeToggle}
                queryContributorModeratorType={this.props.queryContributorModeratorType}
                fetchSpecificTimelineNodes={this.props.fetchSpecificTimelineNodes}
              />
            </div>
            {this.renderNodeInfo(true)}

            <div className="nodeDescription" >
            Rather than a single story, each individual is made up of a portfolio of stories, and each story is made up of a series of events and experiences. By allowing each individual to have multiple timelines, one timeline for each of their stories, the specific topic becomes the fabric that ties the nodes of content together, upholding continuity to ultimately form narrative.

            The portfolio timeline is the overarching timeline of an individual with all of a user’s timelines.
            </div>
          </div>

        </div>

      );
    } else if (this.props.permissionsNodeView) {
      return (
        <div>
          <div id="contentContainer">
            {this.renderNodeDifferences()}

            <PermissionsNodeView
              handlePermissionsNodeView={this.props.handlePermissionsNodeView}
              viewNodeObj={this.props.viewNodeObj}
            />
          </div>
          {this.renderNodeInfo(false)}

          <CommentSection nodeType={this.state.permissions !== '' || this.state.permissions !== undefined ? 'tNodes' : 'cNodes'} />

        </div>
      );
    } else {
      return (
        <div>
          <div id="contentContainer">
            <div>
              {this.renderNodeDifferences()}

              {this.state.permissions === true || this.state.permissions === false || this.state.contentType === 'Image' || this.state.contentType === undefined ?
                <p><img alt="contentViewImage" className="contentViewImage" src={this.state.imageFile === '' || this.state.imageFile === undefined ? '/public/images/imageDefault.jpg' : this.state.imageFile} /></p>
            : ''
          }
              {this.state.contentType === 'Link' ?
                <div>
                  <h1 id="linkEventHeader">{this.state.title}</h1>
                  <div className="linkBanner">
                    <img alt="contentViewImage" className="contentViewImage" src={this.state.imageFile === '' || this.state.imageFile === undefined ? '/public/images/imageDefault.jpg' : this.state.imageFile} />
                  </div>
                  <div id="linkContainer">
                    <div id="linkTextContent">
                      <h3 id="linkSource">{this.state.link.linkSource}</h3>
                      <h2 id="linkHeader">{this.state.link.linkHeader}</h2>
                      <h3 id="linkDesc"> {this.state.link.linkDesc}</h3>
                      <a className="linkUrl" href={this.state.link.linkUrl} target="_blank" rel="noopener noreferrer"> {this.state.link.linkUrl} </a>
                    </div>
                    <a href={this.state.link.linkUrl} target="_blank" rel="noopener noreferrer">
                      <div id="linkClickthrough">
                        <img alt="link-icon" src="/public/images/link.png" />
                        <div>
                          <img alt="external-icon" src="/public/images/external-icon.png" />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
               : '' }

            </div>
          </div>
          <div>
            <div className="nodeDescriptionAlt" dangerouslySetInnerHTML={{ __html: marked(this.state.desc || '') }} />
          </div>
          <CommentSection nodeType={this.state.permissions !== '' || this.state.permissions !== undefined ? 'tNodes' : 'cNodes'} />
        </div>
      );
    }
  }

  render() {
    return (
      <div className={this.props.queryType === 'Explore' ? 'exploreViewBox' : 'contentViewBox'}>
        {this.props.queryType === 'Explore' ? '' :
        <div id="nodeViewMargin" />
      }

        <div className="pageGrid">
          {this.props.queryType === 'Explore' ? '' :
          <div id="contentTitle">
            <p>{this.state.title}</p>
          </div>
          }
          <div id={this.props.queryType === 'Explore' ? 'eNodeViewContainer' : 'nodeViewContainer'}>
            {this.renderNodeViewQuery()}
          </div>

        </div>
      </div>
    );
  }
}


export default ContentView;
