import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// redux
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import reducers from './reducers';

// import components
import './styles/style.scss';
// import FeedPage from './containers/FeedPage';
import UploadPage from './containers/UploadPage';
import LandingPage from './containers/LandingPage';

import QueryPage from './containers/QueryPage';
import NodePage from './containers/NodePage';
import UserPage from './containers/UserPage';
import ExplorePage from './containers/ExplorePage';

import { ActionTypes } from './actions';
import requireAuth from './containers/requireAuth';


// this creates the store with the reducers, and does some other stuff to initialize devtools
const store = createStore(reducers, {}, compose(
  applyMiddleware(thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f,
));

const token = localStorage.getItem('token');
if (token) {
  store.dispatch({ type: ActionTypes.AUTH_USER });
}


// <Route path="/Profile" component={requireAuth(QueryPage)} />
// <Route path="/Feed" component={requireAuth(FeedPage)} />

const App = (props) => {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/Upload" component={requireAuth(UploadPage)} />
          <Route exact path="/n/:timelineID/:contentID" component={(NodePage)} />
          <Route path="/q/:query/" component={requireAuth(QueryPage)} />
          <Route exact path="/q/:query/:contentID" component={requireAuth(QueryPage)} />
          <Route path="/u/:userID" component={requireAuth(UserPage)} />
          <Route exact path="/u/:userID/:contentID" component={requireAuth(UserPage)} />
          <Route exact path="/Explore" component={(ExplorePage)} />

          <Route exact path="/" component={LandingPage} />

          <Route render={() => (<div>page not found </div>)} />
        </Switch>
      </div>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('app'),
);
