import { ActionTypes } from '../actions';

const initialState = {
  all: [],
  node: {},
};

const ContentNodeReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CONTENT_NODE:
      return Object.assign({}, state, { node: action.payload });
    case ActionTypes.SET_EDIT_ID:
      return Object.assign({}, state, { editID: action.payload });
    default:
      return state;
  }
};

export default ContentNodeReducers;
