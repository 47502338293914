import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signupUser, signinUser } from '../../actions';

import '../../styles/style.scss';


class landingPermissionsNodeView extends Component {
  constructor(props) {
    super(props);


    this.state = {
    };

    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
  }


  renderLandingNodeViewContent() {
    console.log(this.state);
    return (
      <div id="landingPermissionsNodeView">
        <div className="contentQueryNodeView">
          <div id="landingPermissionsNodeViewHeaderContainer" className="feedNodeViewHeader">
            <div id="landingPermissionsNodeViewHeaderDetail">04</div>
            <div id="landingPermissionsNodeViewHeader">Permissions</div>

          </div>

          <div id="landingPermissionsNodeViewInfoContainer">
            <div id="landingPermissionsNodeViewInfoContent">
              <div id="headerText">
              Our stories often include other people - people we want to write stories with,
              share stories with and tell stories with.

              </div>
              <div id="headerText">
              For each individual timeline, you can set different access
              permissions. You can invite other users to become editors,
              and multiple people can contribute to a single timeline. If
              a timeline is private, you can change your view permissions
              and specify the audience you want to share with.

              </div>

            </div>
          </div>
        </div>
        <div id="landingPermissionsNodeViewImageContainer">

          <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>

        </div>

      </div>
    );
  }

  render() {
    return (
      <div className="landingNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
  }

);

export default withRouter(connect(mapStateToProps, { signupUser, signinUser })(landingPermissionsNodeView));
