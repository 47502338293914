import React from 'react';
import '../styles/style.scss';


class NodeUploadDirectory extends React.Component {
  constructor() {
    super();
    this.state = {
      buttonClasses: {},
    };
    this.renderSelectButton = this.renderSelectButton.bind(this);
    this.buttonStylesClick = this.buttonStylesClick.bind(this);
  }

  componentWillMount() {
    /*eslint-disable */

    const temp = this.props.timelineNodes.map((timelineNode) => {
      return (
        timelineNode.id: 'nodeUploadDirectoryTimelineSelect'
      );
    });
    /* eslint-enable */

    this.setState({
      buttonClasses: temp,
    });
  }

  componentWillReceiveProps(props) {
    const temp = {};

    for (const key in props.timelineNodes) {
      if (true) {
        const index = props.selectedTimelines.indexOf(props.timelineNodes[key].id);
        if (index === -1) {
          temp[props.timelineNodes[key].id] = 'nodeUploadDirectoryTimelineSelect';
        } else {
          temp[props.timelineNodes[key].id] = 'nodeUploadDirectoryTimelineSelected';
        }
      }
    }
    this.setState({
      buttonClasses: temp,
    });
  }


  buttonStylesClick(timelineNodeId) {
    const temp = this.state.buttonClasses;
    if (temp[timelineNodeId] === 'nodeUploadDirectoryTimelineSelect') {
      temp[timelineNodeId] = 'nodeUploadDirectoryTimelineSelected';
    } else {
      temp[timelineNodeId] = 'nodeUploadDirectoryTimelineSelect';
    }
    this.setState({
      buttonClasses: temp,
    });
  }

  // className={(this.state.buttonClasses[timelineNode.id]) && !this.props.masterViewSelected ? this.state.buttonClasses[timelineNode.id] : 'nodeUploadDirectoryTimelineSelect'}
  renderSelectButton(timelineNode, index) {
    return (
      <div>
        <div id="nodeDirectoryTimelimeButtonContainer">
          <button
            className={this.props.timelineNode._id === timelineNode.id && !this.props.masterViewSelected ? 'nodeDirectoryViewSelected' : 'nodeDirectoryView'}
            onClick={(e) => {
              this.props.handleSelectClick(timelineNode);
              this.buttonStylesClick(timelineNode.id);
              this.props.handleViewClick(timelineNode.id);
              this.props.handleMasterView(false);
           }}
          >
            <img alt="viewingIcon" src="/public/images/directoryViewing.png" />
          </button>

          <button
            className={this.props.timelineNode._id === timelineNode.id && !this.props.masterViewSelected ? 'nodeUploadDirectoryTimelineSelected' : 'nodeUploadDirectoryTimelineSelect'}
            value={timelineNode.id}
            onClick={(e) => {
              this.props.handleSelectClick(timelineNode);
              this.buttonStylesClick(timelineNode.id);
              this.props.handleViewClick(timelineNode.id);
              this.props.handleMasterView(false);
             }}
          >
        Select
          </button>

          <div id="nodeDirectoryTimelimeTitle">
            {timelineNode.permissions ?
              <img id="lockedTimelineIcon" alt="locked"src="/public/images/timelineLocked.png" />
              : ''
            }
            {timelineNode.title}
          </div>
        </div>
      </div>
    );
  }


  render() {
    let albumNodes, regularNodes;

    if (this.props.timelineNodeAlbum !== undefined && this.props.timelineNodeAlbum.nodes !== undefined) {
      albumNodes = this.props.timelineNodeAlbum.nodes;
    }
    if (this.props.timelineNodeRegular !== undefined && this.props.timelineNodeRegular.nodes !== undefined) {
      regularNodes = this.props.timelineNodeRegular.nodes;
    }
    return (
      <div className="uploadDirectoryBox">
        <div className="uploadDirectoryHeader" >
          <p className="leftMargin10">Select One Timeline To Upload To</p>
        </div>
        <div className="uploadDirectoryBackground">
          {this.props.queryType === 1 ?
            <div id="masterDirectoryTimelimeButtonContainer">
              <button
                className={this.props.masterViewSelected ? 'nodeDirectoryViewSelected' : 'nodeDirectoryView'}
                onClick={(e) => { this.props.handleMasterView(true); }}
              >
                <img alt="viewingIcon" src="/public/images/directoryViewing.png" />
              </button>
              <button
                className={this.props.masterSelected ? 'nodeUploadDirectoryTimelineSelected' : 'nodeUploadDirectoryTimelineSelect'}
                onClick={(e) => {
                this.props.handleMasterSelect();
                  }}
              >
                Select
              </button>
              <div id="nodeDirectoryTimelimeTitle">
                Portfolio Timeline
              </div>
            </div>
               :
            <div id="masterDirectoryTimelimeButtonAlternateContainer">
              {this.props.queryType === 2 ? 'Moderator Timelines' : ''}
              {this.props.queryType === 3 ? 'Contributor Timelines' : ''}
            </div>
             }

          <div id="nodeDirectoryHeaderExplain">
            {this.props.queryType === 1 ? 'Your Portfolio Timeline is a collection of all of your album timelines' : ''}
            {this.props.queryType === 2 ? 'Moderator Timelines are timelines that you have been granted Moderator permission' : ''}
            {this.props.queryType === 3 ? 'Contributor Timelines are timelines that you have been granted Contributor permission' : ''}
          </div>

          <div id="masterTNodeChildrenContainer">
          Album Timelines
            <p id="nodeDirectoryExplain">Album timelines are timelines that will show up on the Explore, Feed and Profile pages</p>
            {albumNodes !== undefined && albumNodes.length > 0 ? albumNodes.map((timelineNode, index) => {
            return (
              <div key={timelineNode._id} className="leftMargin10">
                {this.renderSelectButton(timelineNode.node, index)}

              </div>
            );
          }) :
            <div id="nodeDirectoryNoTimelinesText">
              <p >You currently have no {this.props.queryType === 2 ? 'Moderator' : ''}{this.props.queryType === 3 ? 'Contributor' : ''} album timelines</p>
              {this.props.queryType === 1 ? <p >To create an album timeline, make sure that the portfolio timeline is selected above and create a timeline</p> : ''}
              {this.props.queryType === 2 ? <p >To upload to a Moderator timeline, another user must invite you to become a Moderator of their timeline</p> : ''}
              {this.props.queryType === 3 ? <p > To upload to a Contributor timeline, another user must invite you to become a Contributor of their timeline</p> : ''}
            </div> }

          Sub-Timelines
            <p id="nodeDirectoryExplain">Sub-Timelines are timelines nested under other timelines and will not show up on the Explore, Feed and Profile pages.</p>


            {regularNodes !== undefined && regularNodes.length > 0 ? regularNodes.map((timelineNode, index) => {
            return (
              <div key={timelineNode._id} className="leftMargin10">
                {this.renderSelectButton(timelineNode.node, index)}

              </div>
            );
          }) :
            <div id="nodeDirectoryNoTimelinesText">
              <p >You currently have no {this.props.queryType === 2 ? 'Moderator' : ''}{this.props.queryType === 3 ? 'Contributor' : ''} sub-timelines</p>
              {this.props.queryType === 1 ? <p >To create a sub-timeline, make sure to select a timeline other than the portfolio timeline and create your timeline</p> : ''}
            </div>}
          </div>

        </div>
      </div>
    );
  }
}


export default NodeUploadDirectory;
