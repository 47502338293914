import { ActionTypes } from '../actions';

const initialState = {
  isEditing: false,
  eNode: {},
  selectedTNodesUpdateID: '',
  viewNodeObj: {},

};

const NodeReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.EDIT_NODE:
      return Object.assign({}, state, { isEditing: true, eNode: action.payload });
    case ActionTypes.EDIT_NODE_CANCEL:
      return Object.assign({}, state, { isEditing: false, eNode: {} });
    case ActionTypes.CREATE_TIMELINE_NODE:
      return Object.assign({}, state, { selectedTNodesUpdateID: action.payload.newTNode._id });
    case ActionTypes.CREATE_CONTENT_NODE:
      return Object.assign({}, state, { selectedTNodesUpdateID: action.payload._id });
    case ActionTypes.FETCH_CONTENT_NODE:
      return Object.assign({}, state, { viewNodeObj: action.payload });
    case ActionTypes.VIEWING_NODE:
    {
      if (action.payload !== undefined) {
        return Object.assign({}, state, { viewNodeObj: action.payload });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
};

export default NodeReducers;
