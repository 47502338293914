import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signupUser, signinUser } from '../../actions';

import '../../styles/style.scss';


class landingCreateNodeView extends Component {
  constructor(props) {
    super(props);


    this.state = {
    };

    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
  }


  renderLandingNodeViewContent() {
    console.log(this.state);
    return (
      <div id="landingCreateNodeView">
        <div className="contentQueryNodeView">
          <div id="landingCreateNodeViewHeaderContainer" className="feedNodeViewHeader">
            <div id="landingCreateNodeViewHeaderDetail">03</div>
            <div id="landingCreateNodeViewHeader">Create And Nest Timelines</div>

          </div>

          <div id="landingCreateNodeViewInfoContainer">
            <div id="landingCreateNodeViewInfoContent">
              <div id="headerText">
                Rather than a single story, each individual
                is made up of a portfolio of stories, and
                each story is made up of a series of events
                and experiences.
              </div>
              <div id="headerText">
              By allowing each individual to have multiple timelines/
              feeds, one timeline for each of their stories, the specific topic
              becomes the fabric that ties the nodes of content together.
              </div>
              <div id="headerText">
              In addition to chronology, topic becomes the most important factor
              in the organization of knowledge.
              </div>
            </div>
          </div>
        </div>
        <div id="landingCreateNodeViewImageContainer">

          <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
          <div id="FeedRectTwo"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
          <div id="FeedRectThree"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
        </div>

      </div>
    );
  }

  render() {
    return (
      <div className="landingNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
  }

);

export default withRouter(connect(mapStateToProps, { signupUser, signinUser })(landingCreateNodeView));
