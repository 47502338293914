import { combineReducers } from 'redux';

import NodeReducers from './NodeReducers';
import ContentNodeReducers from './ContentNodeReducers';
import TimelineNodeReducers from './TimelineNodeReducers';
import AuthReducer from './AuthReducer';
import CommentsReducer from './CommentsReducer';
import UserReducer from './UserReducer';
import PageReducer from './PageReducer';


const rootReducer = combineReducers({
  nodes: NodeReducers,
  cNodes: ContentNodeReducers,
  tNodes: TimelineNodeReducers,
  auth: AuthReducer,
  comments: CommentsReducer,
  user: UserReducer,
  page: PageReducer,
});

export default rootReducer;
