import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchComments, updateComment, createComment, deleteComment } from '../actions';
import Comment from './Comment';
import '../styles/style.scss';

class CommentSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: '',
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.createComment = this.createComment.bind(this);
  }

  componentDidMount() {
    this.props.fetchComments(this.props.viewNodeObj._id, null, this.props.nodeType);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.viewNodeObj._id !== this.props.viewNodeObj._id) {
      this.props.fetchComments(nextProps.viewNodeObj._id, null, nextProps.nodeType);
    }
  }

  onInputChange(event) {
    const { id } = event.target;
    if (id === 'content') {
      this.setState({ content: event.target.value });
    }
  }

  createComment() {
    // this.props.createSubmission(Object.assign({}, this.state), this.props.viewNodeObj._id, this.props.history);
    this.props.createComment(Object.assign({}, this.state), null, this.props.viewNodeObj._id, null, this.props.nodeType);
    // this.props.fetchComments(this.props.match.params.questionID);
    this.setState({ content: '' });
  }

  render() {
    return (

      <div id="commentSection">
        <div>

          {this.props.comments !== undefined ? this.props.comments.map((comment, index) => {
         return (
           <div key={comment._id}>
             <Comment nodeType={this.props.nodeType} comment={comment} index={index} id="comment" />
           </div>
           );
        })
      :
         ''
      }


          <div id="mainSubmit" >
            <img className="icon"src="/public/images/userProfilePlaceholder.png" alt="more-icon" />
            <input autoComplete="off" id="content" onChange={this.onInputChange} value={this.state.content} placeholder="Write a comment ..." />
            <button className="submitButton" onClick={this.createComment}> Submit Comment </button>

          </div>
        </div>

      </div>

    );
  }
}


const mapStateToProps = state => (
  {
    comments: state.comments.comments,
    viewNodeObj: state.nodes.viewNodeObj,

  }
);


export default withRouter(connect(mapStateToProps, {
  fetchComments, updateComment, createComment, deleteComment,
})(CommentSection));
