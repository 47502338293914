import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

export default function (ComposedComponent) {
  class RequireAuth extends Component {
    // your various component lifecycle methods
    componentWillMount() {
      if (!this.props.authenticated) this.props.history.push('/');
    }

    componentWillUpdate(nextProps) {
      if (!nextProps.authenticated) this.props.history.push('/');
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  // mapStateToProps
  const mapStateToProps = state => (
    {
      authenticated: state.auth.authenticated,
    }
  );

  return withRouter(connect(mapStateToProps, null)(RequireAuth));
}
