import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signupUser, signinUser } from '../../actions';

import '../../styles/style.scss';


class landingVisualEssayNodeView extends Component {
  constructor(props) {
    super(props);


    this.state = {
    };

    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
  }


  renderLandingNodeViewContent() {
    console.log(this.state);
    return (
      <div id="landingVisualEssayNodeView">
        <div className="contentQueryNodeView">
          <div id="landingVisualEssayNodeViewHeaderContainer" className="feedNodeViewHeader">
            <div id="landingVisualEssayNodeViewHeaderDetail">05</div>
            <div id="landingVisualEssayNodeViewHeader">
              <p>Visual Essays</p>
              <p>Digital Records</p>
              <p>Memorabilia</p>

            </div>
          </div>

          <div id="landingVisualEssayNodeViewInfoContainer">
            <div id="landingVisualEssayNodeViewInfoContent">
              <div id="headerText">
                Document stories privately or publicly with friends by sharing
                a timeline/ feed.

              </div>
              <div id="headerText">
            Organize information by creating structured histories and help tell the
            stories that inspire you.

              </div>
              <div id="headerText">
                Create community around shared interests. A hundred individuals can contribute
                to a crowdsourced ledger about a specific social movement or idea.

              </div>
            </div>
          </div>
        </div>
        <div id="landingVisualEssayNodeViewImageContainer">

          <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>

        </div>

      </div>
    );
  }

  render() {
    return (
      <div className="landingNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
  }

);

export default withRouter(connect(mapStateToProps, { signupUser, signinUser })(landingVisualEssayNodeView));
