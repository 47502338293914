import React from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import moment from 'moment';

import NavigationBar from './NavigationBar';
import NodeView from '../components/NodeView';
import {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchTimelineNodes,

  fetchContentNode,
  updateUser,
  viewingNode,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  fetchTimelineNodesByTag,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
  handleIsFirstMount,
  updateTimelineNodePermissionsRequests,
  createContentNode,
  createTimelineNode,
  updateTimelineNode,
  updateContentNode,
  tokenVote,
  editNodeCancel,
  fetchTimelineNodesByTagFeed,


} from '../actions';

import '../styles/style.scss';


class ExplorePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exploreId: 1,
    };

    this.viewNode = this.viewNode.bind(this);
    this.timelineClickthrough = this.timelineClickthrough.bind(this);
    this.renderExploreTimeline = this.renderExploreTimeline.bind(this);
    this.renderExploreTimelineHeader = this.renderExploreTimelineHeader.bind(this);
    this.renderExploreTimelineNodes = this.renderExploreTimelineNodes.bind(this);
    this.toggleExploreId = this.toggleExploreId.bind(this);
    this.handleExploreNavigation = this.handleExploreNavigation.bind(this);


    this.viewNode = this.viewNode.bind(this);
    this.subscribeTimeline = this.subscribeTimeline.bind(this);
    this.unSubscribeTimeline = this.unSubscribeTimeline.bind(this);
    this.handlePermissionsNodeView = this.handlePermissionsNodeView.bind(this);
  }
  componentDidMount() {
    this.props.handleIsFirstMount(true);
    this.props.fetchTimelineNodes();
    this.props.fetchTimelineNodesByTagFeed('Story');

    this.props.handleTimelineHistory('Explore');
  }

  componentWillReceiveProps(props) {
    const tempAlbumTNode = {};
    const tempOpenContributorTNodeArray = [];
    const tempClosedContributorTNodeArray = [];
    if (props.timelineNodes !== undefined) {
      tempAlbumTNode.nodes = [];
      const tempAlbumTNodeArray = [];
      props.timelineNodes.map((tNode) => {
        // if (tNode.album !== undefined && tNode.album && !tNode.permissions) {
        if (!tNode.permissions && tNode.album !== undefined && tNode.album) {
          if (tNode.openContributor !== undefined && tNode.openContributor) {
            tempOpenContributorTNodeArray.push(tNode);
          } else {
            tempClosedContributorTNodeArray.push(tNode);
          }
          tempAlbumTNodeArray.push(tNode);
          return tempAlbumTNode.nodes.push(Object.assign({}, { node: tNode }));
        }
        return tNode._id;
      });
      tempAlbumTNode.title = 'Explore';
      tempAlbumTNode._id = 'Explore';

      this.setState({
        timelineNode: tempAlbumTNode,
        tempAlbumTNodeArray,
        tempOpenContributorTNodeArray,
        tempClosedContributorTNodeArray,
      });
    } else {
      this.setState({
        timelineNode: this.props.timelineNode,
      });
    }
  }

  handlePermissionsNodeView(bool) {
    this.setState({ permissionsNodeView: bool });
  }
  viewNode(node) {
    this.props.viewingNode(node);
  }

  subscribeTimeline(nodeId) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    updatedUser.permissionViewKeys.push(nodeId);
    this.props.updateUser(updatedUser._id, updatedUser, false, false, true);
  }
  unSubscribeTimeline(node) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    const permissionsIndex = updatedUser.permissionViewKeys.indexOf(node._id);
    if (permissionsIndex > -1) {
      updatedUser.permissionViewKeys.splice(permissionsIndex, 1);
      this.props.updateUser(updatedUser._id, updatedUser, false);
    }

    this.props.updateUser(updatedUser._id, updatedUser, false, false, true);
  }

  timelineClickthrough(node) {
    this.props.fetchTimelineNode(node._id);
  }

  handleExploreNavigation(navUp) {
    const tagList = ['Story', 'Project', 'History', 'Album', 'Documentation', 'Art'];

    if (this.state.exploreId > 0 && this.state.exploreId <= 6) {
      if (navUp && this.state.exploreId < 6) {
        this.props.fetchTimelineNodesByTag(tagList[this.state.exploreId]);
        this.setState({ exploreId: this.state.exploreId + 1 });
      } else if (!navUp && this.state.exploreId > 1) {
        this.props.fetchTimelineNodesByTag(tagList[this.state.exploreId - 2]);
        this.setState({ exploreId: this.state.exploreId - 1 });
      }
    }
  }

  toggleExploreId(Id) {
    this.setState({ exploreId: Id });
  }
  renderExploreTimelineHeader() {
    return (
      <div className="timelineHeader">
        <div className="timelineTitleQuery">
          <p>
            Explore Record Atlas
          </p>
        </div>
        <div className="timelineHeaderLine" />
        <div id="navigateButtonTimelineContainer">
          {this.state.exploreId === 1 ?
            <button
              className="navigateButtonTimeline"
              id="arrowDisabled"
            ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
          :
            <button
              className="navigateButtonTimeline"
              onClick={() => { this.handleExploreNavigation(false); }}
            ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
          }
          {this.state.exploreId === 6 ?
            <button
              className="navigateButtonTimeline"
              id="arrowDisabled"
            ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
            :
            <button
              className="navigateButtonTimeline"
              onClick={() => { this.handleExploreNavigation(true); }}
            ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
            </button>
          }
        </div>
        <div id="nodePositionNavNumber">
          {this.state.exploreId} of 6
        </div>

      </div>
    );
  }
  renderExploreTimelineNodes() {
    console.log('hello', this.props.timelineNodeExploreFeed);
    const tagList = ['Stories', 'Projects', 'Histories', 'Albums', 'Documentation', 'Art'];

    return (
      <div className="timelineNodesExplore">

        <div id="timelineNodesExplore" className="timelineNodesContainer">
          <div id="timelineNodeExploreTopicContainer">
            <div id="exploreTimelineRootConnectorContainer">
              <div className="exploreTimelineRootNodeConnectorCircle" />
              <div className="exploreTimelineRootNodeConnectorDotLine" />
              <div className="exploreTimelineRootNodeConnectorDotLine2" />
            </div>
            <button
              value={tagList[0]}
              className="timelineNodePreviewButtonExplore"
              id={this.state.exploreId === 1 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => { this.toggleExploreId(1); this.props.fetchTimelineNodes(); this.props.fetchTimelineNodesByTagFeed('Story'); }}
            >
              <div className="nodeContainerContent">
                <div className="nodeContainerTitleExploreTopic">
                  <div >{tagList[0]}</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="exploreTimelineImage"
                    src="/public/images/exploreNodeContainerImages/explorePageStories.png"
                  />
                </div>

              </div>

            </button>

          </div>

          <div id="timelineNodeExploreTopicContainer">
            <div id="exploreTimelineRootConnectorContainer">
              <div className="exploreTimelineRootNodeConnectorCircle" />
              <div className="exploreTimelineRootNodeConnectorDotLine" />
              <div className="exploreTimelineRootNodeConnectorDotLine2" />
            </div>
            <button
              value={tagList[1]}
              className="timelineNodePreviewButtonExplore"
              id={this.state.exploreId === 2 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => { this.toggleExploreId(2); this.props.fetchTimelineNodesByTag('Project'); this.props.fetchTimelineNodesByTagFeed('Project'); }}

            >
              <div className="nodeContainerContent">
                <div className="nodeContainerTitleExploreTopic">
                  <div >{tagList[1]}</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="exploreTimelineImage"
                    src="/public/images/exploreNodeContainerImages/explorePageProject.png"
                  />
                </div>

              </div>
            </button>

          </div>
          <div id="timelineNodeExploreTopicContainer">
            <div id="exploreTimelineRootConnectorContainer">
              <div className="exploreTimelineRootNodeConnectorCircle" />
              <div className="exploreTimelineRootNodeConnectorDotLine" />
              <div className="exploreTimelineRootNodeConnectorDotLine2" />
            </div>
            <button
              value={tagList[2]}
              className="timelineNodePreviewButtonExplore"
              id={this.state.exploreId === 3 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => { this.toggleExploreId(3); this.props.fetchTimelineNodesByTag('History'); this.props.fetchTimelineNodesByTagFeed('History'); }}

            >
              <div className="nodeContainerContent">
                <div className="nodeContainerTitleExploreTopic">
                  <div >{tagList[2]}</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="exploreTimelineImage"
                    src="/public/images/exploreNodeContainerImages/explorePageHistory.png"
                  />
                </div>
              </div>

            </button>

          </div>
          <div id="timelineNodeExploreTopicContainer">
            <div id="exploreTimelineRootConnectorContainer">
              <div className="exploreTimelineRootNodeConnectorCircle" />
              <div className="exploreTimelineRootNodeConnectorDotLine" />
              <div className="exploreTimelineRootNodeConnectorDotLine2" />
            </div>
            <button
              value={tagList[3]}
              className="timelineNodePreviewButtonExplore"
              id={this.state.exploreId === 4 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => { this.toggleExploreId(4); this.props.fetchTimelineNodesByTag('Album'); this.props.fetchTimelineNodesByTagFeed('Album'); }}
            >
              <div className="nodeContainerContent">
                <div className="nodeContainerTitleExploreTopic">
                  <div >{tagList[3]}</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="exploreTimelineImage"
                    src="/public/images/exploreNodeContainerImages/explorePageAlbum.png"
                  />
                </div>

              </div>
            </button>

          </div>
          <div id="timelineNodeExploreTopicContainer">
            <div id="exploreTimelineRootConnectorContainer">
              <div className="exploreTimelineRootNodeConnectorCircle" />
              <div className="exploreTimelineRootNodeConnectorDotLine" />
              <div className="exploreTimelineRootNodeConnectorDotLine2" />
            </div>
            <button
              value={tagList[4]}
              className="timelineNodePreviewButtonExplore"
              id={this.state.exploreId === 5 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => { this.toggleExploreId(5); this.props.fetchTimelineNodesByTag('Documentation'); this.props.fetchTimelineNodesByTagFeed('Documentation'); }}

            >
              <div className="nodeContainerContent">
                <div className="nodeContainerTitleExploreTopic">
                  <div >{tagList[4]}</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="exploreTimelineImage"
                    src="/public/images/exploreNodeContainerImages/explorePageDocumentation.png"
                  />
                </div>


              </div>

            </button>

          </div>
          <div id="timelineNodeExploreTopicContainer">
            <div id="exploreTimelineRootConnectorContainer">
              <div className="exploreTimelineRootNodeConnectorCircle" />
              <div className="exploreTimelineRootNodeConnectorDotLine" />
              <div className="exploreTimelineRootNodeConnectorDotLine2" />
            </div>
            <button
              value={tagList[5]}
              className="timelineNodePreviewButtonExplore"
              id={this.state.exploreId === 6 ? 'previewButtonSelected' : 'previewButton'}
              onClick={() => { this.toggleExploreId(6); this.props.fetchTimelineNodesByTag('Art'); this.props.fetchTimelineNodesByTagFeed('Art'); }}
            >
              <div className="nodeContainerContent">
                <div className="nodeContainerTitleExploreTopic">
                  <div>{tagList[5]}</div>
                </div>
                <div
                  className="timelineNodePreview"
                >
                  <img
                    alt="exploreTimelineImage"
                    src="/public/images/exploreNodeContainerImages/explorePageArt.png"
                  />
                </div>
              </div>
            </button>
          </div>

        </div>
        <div id="exploreFeedContainer" className="timelineNodesContainer">
          <div id="exploreFeedHeader">
          New in {tagList[this.state.exploreId - 1]}
          </div>
          {this.props.timelineNodeExploreFeed !== undefined ?
            this.props.timelineNodeExploreFeed.map((node, index) => {
            return (
              <NavLink to={`/n/${node._id}/${node._id}`} >
                <button
                  id="exploreFeedNode"
                  value={node}
                  onClick={() => {
              this.timelineClickthrough(node);
              this.props.handleTimelineHistoryIndex(1);
              this.props.handleTimelineHistory('node', node._id);
            }}
                >
                  <div id="exploreFeedNodeNum">
                  0{index + 1}
                  </div>
                  <div id="exploreFeedNodeContent">
                    <div id="exploreFeedNodeHeader">
                      <img alt="logo-icon" src={node.imageFile} />
                      <div>{node.title}</div>
                    </div>
                    <div id="exploreFeedNodeDesc">
                      {node.desc}
                    </div>
                    <div id="exploreFeedNodeInfo">

                      {`${moment(node.updatedAt).fromNow()} by ${node.recentContributor !== undefined ? node.recentContributor : node.author.username}`}
                    </div>
                  </div>

                </button>
              </NavLink>
            );
          }) : ''}
        </div>
      </div>

    );
  }
  // <div className="triangleDown" />
  // {this.renderExploreTimelineHeader()}

  renderExploreTimeline() {
    return (
      <div className="timelineViewBox" id="exploreTimelineViewBox">
        <div id="timelineRootExplore">
          <div className="timelineLineRectangle" />
          <div className="timelineLine" id="timelineLineExplore" />
          <div className="timelineLineRectangle" />
        </div>
        {this.renderExploreTimelineNodes()}

      </div>
    );
  }

  render() {
    return (
      <div className="Page">
        <div className="pageGridV" >

          <div className="pageNav">
            <NavigationBar />
          </div>
          <div className="pageGridH" >
            <div className="pageGrid" id="pageGridExploreTopics" >
              {this.renderExploreTimeline() }
            </div>
            <div className="pageGrid" id="pageGridExplore">
              <NodeView
                timelineNodeFeedDefault={this.props.timelineNodeFeedDefault}
                viewNodeObj={this.props.viewNodeObj}
                subscribeTimeline={this.subscribeTimeline}
                unSubscribeTimeline={this.unSubscribeTimeline}
                timelineNode={this.state.timelineNode !== undefined ? this.state.timelineNode : this.props.timelineNode}
                timelineClickthrough={this.timelineClickthrough}
                queryType="Explore"
                exploreSubType={this.state.exploreId}
                handleTimelineHistory={this.props.handleTimelineHistory}
                handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                mount={this.props.mount}
                auth={this.props.auth}
                viewing={false}
                nodePosition={this.state.position}
                updateTimelineNodePermissionsRequests={this.props.updateTimelineNodePermissionsRequests}
                fetchSpecificTimelineNodes={this.props.fetchSpecificTimelineNodes}
                permissionsNodeView={this.state.permissionsNodeView}
                handlePermissionsNodeView={this.handlePermissionsNodeView}
                queryContributorModeratorType={this.state.queryType}
                openContributorTNodeArray={this.state.tempOpenContributorTNodeArray}
                closedContributorTNodeArray={this.state.tempClosedContributorTNodeArray}
                tokenVote={this.props.tokenVote}
                viewNode={this.props.viewingNode}
                tempAlbumTNodeArray={this.state.tempAlbumTNodeArray}
              />

            </div>
          </div>
        </div>


      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    isEditing: state.nodes.isEditing,
    node: state.cNodes.node,
    viewNodeObj: state.nodes.viewNodeObj,
    timelineNode: state.tNodes.timelineNode,
    timelineNodes: state.tNodes.timelineNodes,
    timelineHistory: state.tNodes.timelineHistory,
    timelineHistoryIndex: state.tNodes.timelineHistoryIndex,
    mount: state.page.mount,
    auth: state.auth.authenticated,
    user: state.user.user,
    eNode: state.nodes.eNode,
    timelineNodeExploreFeed: state.tNodes.timelineNodeExploreFeed,
  }
);

export default withRouter(connect(mapStateToProps, {
  deleteContentNode,
  editNode,
  fetchTimelineNode,
  fetchTimelineNodes,

  fetchContentNode,
  updateUser,
  viewingNode,
  timelineClickthrough,
  fetchSpecificTimelineNodes,
  fetchTimelineNodesByTag,
  handleTimelineHistory,
  handleTimelineHistoryIndex,
  handleIsFirstMount,
  updateTimelineNodePermissionsRequests,
  createContentNode,
  createTimelineNode,
  updateTimelineNode,
  updateContentNode,
  tokenVote,
  editNodeCancel,
  fetchTimelineNodesByTagFeed,
})(ExplorePage));
