import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Link, Element } from 'react-scroll';

import NavigationBar from './NavigationBar';
import TimelineView from '../components/TimelineView';
import NodeUpload from '../components/NodeUpload';
import NodeUploadDirectory from '../components/NodeUploadDirectory';
import {
  createContentNode,
  createTimelineNode,
  updateTimelineNode,
  updateContentNode,
  fetchContentNode,
  fetchTimelineNode,
  fetchTimelineNodes,
  fetchSpecificTimelineNodes,
  deleteTimelineNode,
  deleteContentNode,
  editNode,
  editNodeCancel,
  viewingNode,
  updateUser,
  handleIsFirstMount,


} from '../actions';
import '../styles/style.scss';

class UploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTimelines: [],
      selectedTimelinesObj: [],
      nodeFromTimeline: 'none',
      masterViewSelected: true,
      masterSelected: true,
      queryType: 1,
    };
    this.createNode = this.createNode.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleRemoveClick = this.handleRemoveClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditCancel = this.handleEditCancel.bind(this);
    this.updateNode = this.updateNode.bind(this);
    this.handleSelectClick = this.handleSelectClick.bind(this);
    this.viewNode = this.viewNode.bind(this);
    this.createNodeFromTimeline = this.createNodeFromTimeline.bind(this);
    this.handleNodeFromTimelineCancel = this.handleNodeFromTimelineCancel.bind(this);
    this.handleMasterView = this.handleMasterView.bind(this);
    this.handleMasterSelect = this.handleMasterSelect.bind(this);
    this.handleQueryTypeToggle = this.handleQueryTypeToggle.bind(this);
  }

  componentDidMount() {
    this.props.fetchSpecificTimelineNodes('Creator');
  }

  // selectedTNodesUpdateID: props.selectedTNodesUpdateID,

  componentWillReceiveProps(props) {
    this.setState({
      eNode: props.eNode,
    });
    const tempAlbumTNodeArray = {};
    const tempRegularTNodeArray = {};

    if (props.timelineNodes !== undefined) {
      tempRegularTNodeArray.nodes = [];
      tempAlbumTNodeArray.nodes = [];


      props.timelineNodes.map((tNode) => {
        if (tNode.album !== undefined && tNode.album) {
          return tempAlbumTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
        } else {
          return tempRegularTNodeArray.nodes.push(Object.assign({}, { node: tNode }));
        }
      });
      if (this.state.queryType === 1) {
        tempRegularTNodeArray.title = 'Portfolio Timeline Regular';
        tempRegularTNodeArray._id = 'Portfolio Timeline Regular';
        tempAlbumTNodeArray.title = 'Portfolio Timeline';
        tempAlbumTNodeArray._id = 'Portfolio Timeline ';
      } else if (this.state.queryType === 2) {
        tempAlbumTNodeArray.title = 'Moderator Timelines';
        tempAlbumTNodeArray._id = 'Moderator Timelines ';
      } else if (this.state.queryType === 3) {
        tempAlbumTNodeArray.title = 'Contributor Timelines';
        tempAlbumTNodeArray._id = 'Contributor Timelines';
      }


      this.setState({
        timelineNode: tempAlbumTNodeArray,
        timelineNodeRegular: tempRegularTNodeArray,
      });
    } else {
      this.setState({
        timelineNode: props.timelineNode,
      });
    }
  }

  createNode(node) {
    if (node.type === 'Timeline') {
      this.props.createTimelineNode(node, this.props.history, this.state.selectedTimelinesObj, this.props.timelineNode._id, this.state.queryType);
    } else {
      this.props.createContentNode(node, this.props.history, this.state.selectedTimelinesObj, this.props.timelineNode._id);
    }
    this.setState({ nodeFromTimeline: 'none' });
  }

  handleViewClick(timelineViewId) {
    this.props.fetchTimelineNode(timelineViewId, this.props.history);
  }

  handleSelectClick(timelineNode) {
    // const tempObj = this.state.selectedTimelinesObj;
    // const temp = this.state.selectedTimelines;
    // const index = this.state.selectedTimelines.indexOf(timelineNode._id);
    //
    // if (index === -1) {
    //   temp.push(timelineNode._id);
    //   tempObj.push(timelineNode);
    // } else {
    //   temp.splice(index, 1);
    //   tempObj.splice(index, 1);
    // }

    const tempObj = [];
    const temp = [];
    if (!this.state.masterViewSelected) {
      temp.push(timelineNode._id);
      tempObj.push(timelineNode);
    }

    this.setState({
      selectedTimelines: temp,
      selectedTimelinesObj: tempObj,
      masterViewSelected: false,
      masterSelected: false,
    });
  }


  handleDeleteClick(node) {
    if (node.permissions !== undefined) {
      this.props.deleteTimelineNode(node._id, this.props.history, this.props.timelineNode._id);
    } else {
      this.props.deleteContentNode(node._id, this.props.history, this.props.timelineNode._id);
    }
  }

  handleRemoveClick(node) {
    const user = localStorage.getItem('user');
    const updatedUser = JSON.parse(user);
    let permissionsIndex;
    permissionsIndex = updatedUser.creatorKeys.indexOf(node._id);

    if (this.state.queryType === 1) {
      permissionsIndex = updatedUser.creatorKeys.indexOf(node._id);
      if (permissionsIndex > -1) {
        updatedUser.creatorKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }

      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, true);
        this.props.fetchSpecificTimelineNodes('Creator');
      }
    } else if (this.state.queryType === 2) {
      permissionsIndex = updatedUser.permissionAdminKeys.indexOf(node._id);
      if (permissionsIndex > -1) {
        updatedUser.permissionAdminKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }

      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, true);
      }
    } else if (this.state.queryType === 3) {
      permissionsIndex = updatedUser.permissionEditKeys.indexOf(node._id);
      if (permissionsIndex > -1) {
        updatedUser.permissionEditKeys.splice(permissionsIndex, 1);
        this.props.updateUser(updatedUser._id, updatedUser, true);
      }
      const tempNodes = [];
      const tempTimeline = this.props.timelineNode;
      if (this.props.timelineNode.nodes !== undefined) {
        this.props.timelineNode.nodes.map((nodeObj) => {
          return tempNodes.push(nodeObj.node._id);
        });
        tempNodes.map((id, index) => {
          if (id === node._id) {
            tempNodes.splice(index, 1);
          }
          return id;
        });
        tempTimeline.nodes = tempNodes;
        this.props.updateTimelineNode(this.props.timelineNode._id, tempTimeline, null, this.props.timelineNode._id, true);
      }
    }
  }


  handleEditClick(node) {
    this.props.editNode(node);
  }

  handleEditCancel() {
    this.props.editNodeCancel();
  }

  updateNode(node) {
    if (this.state.eNode.permissions === true || this.state.eNode.permissions === false) {
      this.props.updateTimelineNode(this.props.eNode._id, node, null, this.props.timelineNode._id);
    } else {
      this.props.updateContentNode(this.props.eNode._id, node, this.props.timelineNode._id);
    }
    if (this.state.queryType === 1) {
      this.props.fetchSpecificTimelineNodes('Creator');
    } else if (this.state.queryType === 2) {
      this.props.fetchSpecificTimelineNodes('Admin');
    } else if (this.state.queryType === 3) {
      this.props.fetchSpecificTimelineNodes('Edit');
    }
    this.handleEditCancel();
  }


  viewNode(node) {
    this.props.viewingNode(node);
  }

  createNodeFromTimeline(nodeType) {
    if (nodeType === 'Timeline') {
      this.setState({ nodeFromTimeline: 'Timeline' });
    } else if (nodeType === 'Content') {
      this.setState({ nodeFromTimeline: 'Content' });
    }

    const tempObj = [];
    const temp = [];
    if (!this.state.masterViewSelected) {
      temp.push(this.props.timelineNode._id);
      tempObj.push(this.props.timelineNode);
    }

    this.setState({
      selectedTimelines: temp,
      selectedTimelinesObj: tempObj,
    });
  }

  handleNodeFromTimelineCancel() {
    this.setState({ nodeFromTimeline: 'none' });
  }

  handleMasterView(bool) {
    if (bool !== undefined) {
      this.setState({ masterViewSelected: bool, masterSelected: bool });
      if (!bool) {
        this.setState({ nodeFromTimeline: 'none' });
      }
    } else {
      this.setState({ masterViewSelected: !this.state.masterViewSelected, masterSelected: !this.state.masterSelected });
    }
  }

  handleMasterSelect() {
    this.setState({ masterSelected: !this.state.masterSelected, masterViewSelected: !this.state.masterViewSelected });
  }
  handleQueryTypeToggle(queryType) {
    this.setState({ queryType });
    // const temp = this.state.timelineNode;
    // // if (this.state.queryType === 1) {
    // //   temp.title = 'Portfolio Timeline';
    // //   temp._id = 'Portfolio Timeline ';
    // // } else if (this.state.queryType === 2) {
    // //   temp.title = 'Moderator Timeline';
    // //   temp._id = 'Moderator Timeline ';
    // // } else if (this.state.queryType === 3) {
    // //   temp.title = 'Contributor Timeline';
    // //   temp._id = 'Contributor Timeline ';
    // // }
    //
    // this.setState({
    //   timelineNode: temp,
    // });
    this.handleMasterView(true);
  }
  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    return (
      <div className="Page">
        <div className="pageGridV" >

          <div className="pageNav">
            <NavigationBar />
          </div>
          <div className="ContentNodeBackground">

            <div className="ContentNode">
              <div id="uploadSection">
                {this.state.nodeFromTimeline === 'none' && this.props.auth ?

                  <div id="uploadSectionNavigation">
                    <button onClick={() => { this.handleQueryTypeToggle(1); this.props.fetchSpecificTimelineNodes('Creator'); }} id={this.state.queryType === 1 ? 'uploadSectionNavigationButtonSelected' : 'uploadSectionNavigationButton'}>
                      <div id="buttonDetail">{user.creatorKeys.length}</div> Created Timelines
                    </button>
                    <button onClick={() => { this.handleQueryTypeToggle(2); this.props.fetchSpecificTimelineNodes('Admin'); }} id={this.state.queryType === 2 ? 'uploadSectionNavigationButtonSelected' : 'uploadSectionNavigationButton'}>
                      <div id="buttonDetail">{user.permissionAdminKeys.length}</div> Moderator Timelines
                    </button>
                    <button onClick={() => { this.handleQueryTypeToggle(3); this.props.fetchSpecificTimelineNodes('Edit'); }} id={this.state.queryType === 3 ? 'uploadSectionNavigationButtonSelected' : 'uploadSectionNavigationButton'}>
                      <div id="buttonDetail">{user.permissionEditKeys.length}</div> Contributor Timelines
                    </button>
                  </div> : <div id="uploadSectionNavigation" />}
                <div id="uploadSectionCreate" >
                  {!this.props.isEditing && this.state.nodeFromTimeline === 'none' ?
                    <NodeUploadDirectory
                      timelineNodes={this.props.timelineNodes}
                      handleViewClick={this.handleViewClick}
                      selectedTimelines={this.state.selectedTimelines}
                      handleSelectClick={this.handleSelectClick}
                      fetchSpecificTimelineNodes={this.props.fetchSpecificTimelineNodes}
                      handleMasterView={this.handleMasterView}
                      handleMasterSelect={this.handleMasterSelect}
                      masterSelected={this.state.masterSelected}
                      masterViewSelected={this.state.masterViewSelected}
                      timelineNode={this.props.timelineNode}
                      timelineNodeAlbum={this.state.timelineNode !== undefined ? this.state.timelineNode : undefined}
                      timelineNodeRegular={this.state.timelineNodeRegular}
                      queryType={this.state.queryType}
                    /> : ''}

                  <NodeUpload
                    createNode={this.createNode}
                    isEditing={this.props.isEditing}
                    handleEditCancel={this.handleEditCancel}
                    updateNode={this.updateNode}
                    eNode={this.props.eNode}
                    nodeFromTimeline={this.state.nodeFromTimeline}
                    handleNodeFromTimelineCancel={this.handleNodeFromTimelineCancel}
                    masterSelected={this.state.masterSelected}
                    timelineNode={this.props.timelineNode}

                  />

                </div>

              </div>
              <div >
                <TimelineView
                  timelineNode={this.state.timelineNode !== undefined && this.state.masterViewSelected ? this.state.timelineNode : this.props.timelineNode}
                  handleDeleteClick={this.handleDeleteClick}
                  handleEditClick={this.handleEditClick}
                  handleRemoveClick={this.handleRemoveClick}
                  timelineLine="timelineLineUpload"
                  timelineViewBox="uploadTimelineViewbox"
                  timelineNodesBox="timelineNodesUpload"
                  page="Upload"
                  uploadToggle="Display"
                  editNode={this.props.editNode}
                  viewNode={this.viewNode}
                  createNodeFromTimeline={this.createNodeFromTimeline}
                  nodeFromTimeline={this.state.nodeFromTimeline}
                  masterViewSelected={this.state.masterViewSelected}
                  handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                  timelineHistory={this.props.timelineHistory}
                  timelineHistoryIndex={this.props.timelineHistoryIndex}
                  handleIsFirstMount={this.props.handleIsFirstMount}
                  nodePosition={1}
                  queryContributorModeratorType={this.state.queryType}
                  auth={this.props.auth}


                />
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

const mapStateToProps = state => (
  {
    timelineNodes: state.tNodes.timelineNodes,
    isEditing: state.nodes.isEditing,
    node: state.cNodes.node,
    eNode: state.nodes.eNode,
    timelineNode: state.tNodes.timelineNode,
    selectedTNodesUpdateID: state.nodes.selectedTNodesUpdateID,
    timelineHistory: state.tNodes.timelineHistory,
    timelineHistoryIndex: state.tNodes.timelineHistoryIndex,
    auth: state.auth.authenticated,

  }
);

export default withRouter(connect(mapStateToProps, {
  createContentNode,
  createTimelineNode,
  fetchContentNode,
  updateTimelineNode,
  updateContentNode,
  fetchTimelineNode,
  deleteTimelineNode,
  deleteContentNode,
  fetchTimelineNodes,
  fetchSpecificTimelineNodes,
  editNode,
  editNodeCancel,
  viewingNode,
  updateUser,
  handleIsFirstMount,


})(UploadPage));
