import React from 'react';
import { NavLink } from 'react-router-dom';
import Modal from 'react-modal';

import NodeContainer from './NodeContainer';
import '../styles/style.scss';


class TimelineView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      indexRight: 0,
      indexLeft: 0,
      nodePosition: props.nodePosition - 1,
      showModal: false,
      access: props.uploadToggle !== 'Hidden' || props.query,
      modalNode: {},
      contributorAccess: false,
      // show: false,
    };
    this.renderTimelineNodeInfo = this.renderTimelineNodeInfo.bind(this);
    this.renderTimelineDescription = this.renderTimelineDescription.bind(this);
    this.renderTimelineHeader = this.renderTimelineHeader.bind(this);
    this.renderTimelineNodes = this.renderTimelineNodes.bind(this);
    this.renderNewNodeContainer = this.renderNewNodeContainer.bind(this);
    this.handleNodesNavigation = this.handleNodesNavigation.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.clearIndex = this.clearIndex.bind(this);
    this.renderRemoveModal = this.renderRemoveModal.bind(this);
    this.renderEmptyNodeList = this.renderEmptyNodeList.bind(this);
    this.renderAccessDenied = this.renderAccessDenied.bind(this);
    this.renderEmptyContributorModeratorList = this.renderEmptyContributorModeratorList.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      indexRight: 0,
      indexLeft: 0,
      nodePosition: props.nodePosition - 1,

    });
    if (props.mount) {
      this.setState({
        nodePosition: 0,
      });
    }
    if (props.timelineNode.access) {
      this.setState({
        access: true,
      });
    }
    // this.props.viewNodeObj.openContributor

    if (props.auth) {
      const user = localStorage.getItem('user');
      const updatedUser = JSON.parse(user);
      if (updatedUser.permissionEditKeys.indexOf(props.timelineNode._id) > -1 ||
          updatedUser.permissionAdminKeys.indexOf(props.timelineNode._id) > -1 ||
          updatedUser.creatorKeys.indexOf(props.timelineNode._id) > -1 ||
          props.timelineNode.openContributor) {
        this.setState({ contributorAccess: true });
      } else {
        this.setState({ contributorAccess: false });
      }
    }
  }

  clearIndex() {
    this.setState({
      indexRight: 0,
      indexLeft: 0,
    });
  }

  handleNodesNavigation(navUp) {
    const maxLen = this.props.timelineNode.nodes !== undefined ? this.props.timelineNode.nodes.length : '';
    if (this.state.nodePosition >= 0 && this.state.nodePosition <= maxLen) {
      if (navUp && this.state.nodePosition < maxLen) {
        this.setState({ nodePosition: this.state.nodePosition + 1 });
      } else if (!navUp && this.state.nodePosition > 0) {
        if (this.state.nodePosition === 1 && !this.props.query) {
          this.props.viewNode({ node: this.props.timelineNode });
        }
        this.setState({ nodePosition: this.state.nodePosition - 1 });
      }
    }
  }

  handleOpenModal(node, position) {
    this.setState({
      showModal: true,
      modalNode: node,
    });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  renderRemoveModal(node) {
    return (
      <div>
        <button
          className="commonButton"
          onClick={() => {
            this.clearIndex();
            this.handleOpenModal(node);
          }}
        >
          Remove
        </button>

        <button
          id="modalHiddenButton"
          onClick={() => {
            this.clearIndex();
        }}
        >
          <Modal
            isOpen={this.state.showModal}
            onRequestClose={() => {
            this.handleCloseModal();
          }}

            className="Modal"
            overlayClassName="Overlay"
            ariaHideApp={false}
          >
            <div id="removeModalHeader">  Remove this timeline?</div>
            <p id="removeModalText">
              Are you sure you want to remove {node !== undefined ? node.title : ''}?
            </p>
            <div id="removeModalButtonContainer">
              <button className="modalButton"
                onClick={() => this.handleCloseModal()}
              >Cancel
              </button>
              <button
                className="modalButton"
                id="removeModalButton"
                onClick={() => {
              this.props.handleRemoveClick(node);
              this.clearIndex();
              this.handleCloseModal();
            }}
              >
              Remove
              </button>
            </div>
          </Modal>
        </button>
      </div>
    );
  }

  renderTimelineHeader() {
    const timelineHistoryIndexUp = this.props.timelineHistoryIndex + 1;
    const timelineHistoryIndexDown = this.props.timelineHistoryIndex - 1;
    let nodeRoute = `${this.props.timelineNode._id}`;
    if (this.props.query) {
      if (this.props.queryType === 'Feed' || this.props.queryType === 'Profile') {
        nodeRoute = `q/${this.props.queryType}`;
      } else if (this.props.queryType === 'Explore') {
        nodeRoute = 'Explore/';
      } else {
        nodeRoute = `u/${this.props.userId}`;
      }
    } else {
      nodeRoute = `n/${this.props.timelineNode._id}`;
    }

    let navUpNode, navUpNodeID, navDownNode, navDownNodeID;

    if (this.props.timelineNode.nodes !== undefined) {
      navUpNode = this.props.timelineNode.nodes[this.state.nodePosition > 0 ? this.state.nodePosition : 0];
      navDownNode = this.props.timelineNode.nodes[this.state.nodePosition - 2];
    }

    if (navUpNode !== undefined && navUpNode.node !== undefined) {
      navUpNodeID = navUpNode.node._id;
    }
    if (navDownNode !== undefined && navDownNode.node !== undefined) {
      navDownNodeID = navDownNode.node._id;
    } else if (this.state.nodePosition === 1) {
      navDownNodeID = this.props.timelineNode._id;
    }
    return (
      <div className="timelineHeader">
        <div >
          {!this.props.query && this.props.uploadToggle === 'Hidden' ?
            <button className="timelineTitle"
              onClick={() => {
              this.props.viewNode({ node: this.props.timelineNode });
              this.setState({ nodePosition: 0 });
              this.props.handleNodePosition(0);
           }}
            >
              <NavLink to={`${this.props.timelineNode._id}`} >

                <p>{this.props.timelineNode.title}</p>
              </NavLink>
            </button>
          : <div className="timelineTitleQuery"><p> {this.props.timelineNode.title}</p> </div>
          }
        </div>
        <div className="timelineHeaderLine" />
        {this.props.uploadToggle === 'Hidden' ?
          <div>
            <div id="navigateHistoryButtonTimelineContainer">
              {this.props.timelineHistoryIndex === 0 ?
                <button
                  className="navigateButtonTimeline"
                  id="arrowDisabled"
                ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                </button>
            :
                <NavLink to={`${this.props.timelineHistory[`${timelineHistoryIndexDown}`]}`} >
                  <button
                    className="navigateButtonTimeline"
                    onClick={() => {
                   this.props.handleTimelineHistoryIndex(-1);
                   this.props.handleTimelineHistory(this.props.queryType, this.props.viewNodeObj._id);
                  }}
                  ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                  </button>
                </NavLink>
          }
              { this.props.timelineHistoryIndex === this.props.timelineHistory.length - 1 ?
                <button
                  className="navigateButtonTimeline"
                  id="arrowDisabled"
                ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                </button>
            :
                <NavLink to={`${this.props.timelineHistory[`${timelineHistoryIndexUp}`]}`} >
                  <button
                    className="navigateButtonTimeline"
                    onClick={() => { this.props.handleTimelineHistoryIndex(1); }}
                  ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                  </button>
                </NavLink>
          }

            </div>
            <div id="navigateButtonTimelineContainer">
              {this.state.nodePosition === 0 ?
                <button
                  className="navigateButtonTimeline"
                  id="arrowDisabled"
                ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                </button>
            :
                <NavLink to={`/${nodeRoute}/${navDownNodeID}`}>
                  <button
                    className="navigateButtonTimeline"
                    onClick={() => {
                    this.props.handleNodePosition(this.state.nodePosition - 1);
                    this.handleNodesNavigation(false);
                    this.props.handleIsFirstMount(false);
                    this.props.viewNode(this.props.timelineNode.nodes[this.state.nodePosition - 2]);
                   }}
                  ><img id="arrowLeft" alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                  </button>
                </NavLink>
            }
              {this.state.nodePosition === (this.props.timelineNode.nodes !== undefined ? this.props.timelineNode.nodes.length : '') ?
                <button
                  className="navigateButtonTimeline"
                  id="arrowDisabled"
                ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                </button>
              :
                <NavLink to={`/${nodeRoute}/${navUpNodeID}`}>
                  <button
                    className="navigateButtonTimeline"
                    onClick={() => {
                    this.props.handleNodePosition(this.state.nodePosition > 0 ? this.state.nodePosition + 1 : 1);
                     this.handleNodesNavigation(true);
                     this.props.handleIsFirstMount(false);
                     this.props.viewNode(this.props.timelineNode.nodes[this.state.nodePosition > 0 ? this.state.nodePosition : 0]);
                    }}
                  ><img alt="nav-Arrow" src="/public/images/nav-Arrow.png" />
                  </button>
                </NavLink>
            }

            </div>
            <div id="nodePositionNavNumber">
              {this.state.nodePosition} of {this.props.timelineNode.nodes !== undefined ? this.props.timelineNode.nodes.length : ''}
            </div>

          </div>
      : ''
    }

        { ((this.props.uploadToggle !== 'Hidden') && (!this.props.masterViewSelected)) ?
          <div className="commonButtonTimelineContainer">
            <button
              className="commonButton"
              value={this.props.timelineNode}
              onClick={() => { this.props.handleEditClick(this.props.timelineNode); }}
            >
            Edit
            </button>
            {this.renderRemoveModal(this.props.timelineNode)}
          </div>
         :
        ''}

      </div>
    );
  }
  // {this.renderTimelineNodeInfo()}


  renderTimelineNodeInfo() {
    return (
      <div className="timelineInfoContainer">
        <p
          className="nodeAuthor"
        >
          {this.props.timelineNode.name}
        </p>
        <p
          className="nodeDate"
        >
          {this.props.timelineNode.date}
        </p>
        <button
          className="commonButton"
          id="commonButtonLong"
        >
          Permissions
        </button>
      </div>
    );
  }

  // this.props.viewNodeObj.openContributor
  // let permissionsIndex = false;
  //
  // if (this.props.auth) {
  //   const user = localStorage.getItem('user');
  //   const updatedUser = JSON.parse(user);
  //   if (updatedUser.permissionAdminKeys.indexOf(this.props.viewNodeObj._id) > -1 ||
  //       updatedUser.creatorKeys.indexOf(this.props.viewNodeObj._id) > -1) {
  //     permissionsIndex = true;
  //   }
  //   if (this.props.viewNodeObj.author !== undefined && (this.props.viewNodeObj.author._id === updatedUser._id)) {
  //     permissionsIndex = true;
  //   }
  // }
  renderNewNodeContainer(hiddenBool) {
    if (!this.props.auth) {
      return (
        <div id="previewButtonNewNode" className="nodeContainerContent">
          <NavLink to="/"
            className="newNodeContainerButton"
            id="masterSelected"
          >
          Sign Up To Contribute
          </NavLink>
        </div>
      );
    } else
    if (hiddenBool && this.props.queryType === 'node') {
      return (
        this.state.contributorAccess ?

          <div id="previewButtonNewNode" className="nodeContainerContent">
            <button
              onClick={(e) => { this.props.createNodeFromNodePageTimeline('Timeline'); }}
              className="newNodeContainerButton"
            >
          Create Sub-Timeline
            </button>
            <button
              onClick={(e) => { this.props.createNodeFromNodePageTimeline('Content'); }}
              className="newNodeContainerButton"
            >
            Add Photos&middot;Links&middot;Text
            </button>
          </div>
          : ''
      );
    } else if (hiddenBool && this.props.queryType === 'Profile' && this.props.queryContributorModeratorType === 1) {
      return (
        // <div id="previewButtonNewNode" className="nodeContainerContent">
        //   <NavLink to="/upload"
        //     className="newNodeContainerButton"
        //     id="masterSelected"
        //   >
        // Create New Timeline
        //   </NavLink>
        // </div>
        <div id="previewButtonNewNode" className="nodeContainerContent">
          <button
            onClick={(e) => { this.props.createNodeFromProfileTimeline('Timeline'); }}
            id="masterSelected"
            className="newNodeContainerButton"
          >
        Create New Timeline
          </button>
        </div>
      );
    } else if (!hiddenBool && this.props.queryContributorModeratorType === 1) {
      return (
        <div id="previewButtonNewNode" className="nodeContainerContent">
          <button
            onClick={(e) => { this.props.createNodeFromTimeline('Timeline'); }}
            id={this.props.masterViewSelected ? 'masterSelected' : ''}

            className="newNodeContainerButton"
          >
        Create {this.props.masterViewSelected ? 'New ' : 'Sub-'}Timeline
          </button>
          {!this.props.masterViewSelected ?
            <button
              onClick={(e) => { this.props.createNodeFromTimeline('Content'); }}
              className="newNodeContainerButton"
            >
            Add Photos&middot;Links&middot;Text
            </button>
          :
          ''
        }
        </div>
      );
    } else {
      return '';
    }
  }

  renderTimelineNodes() {
    if (this.props.timelineNode.nodes === undefined) {
      return (<div className="timelineTitle" />);
    } else {
      const { nodes } = this.props.timelineNode;
      // https://stackoverflow.com/questions/10123953/sort-javascript-object-array-by-date
      nodes.sort((a, b) => {
        if (a.node !== undefined && b.node !== undefined) {
          const c = new Date(a.node.date);
          const d = new Date(b.node.date);
          if (c - d !== 0) {
            return c - d;
          } else {
            const x = new Date(a.node.createdAt);
            const y = new Date(b.node.createdAt);
            return x - y;
          }
        }
        return a;
      });
      return (
        <div className={this.props.timelineNodesBox}>

          <div id="timelineNodesLeft">
            {nodes.map((node, index) => {
            this.state.indexLeft += 1;
              if (this.state.indexLeft % 2 !== 0) {
                return (
                  <div key={this.state.indexLeft}>
                    <NodeContainer
                      node={node}
                      position="left"
                      handleRemoveClick={this.props.handleRemoveClick}
                      timelineNode={this.props.timelineNode}
                      handleEditClick={this.props.handleEditClick}
                      viewNode={this.props.viewNode}
                      page={this.props.page}
                      uploadToggle={this.props.uploadToggle}
                      query={this.props.query}
                      queryType={this.props.queryType}
                      userId={this.props.userId}
                      contentID={this.props.contentID}
                      handleIsFirstMount={this.props.handleIsFirstMount}
                      index={index}
                      handleNodePosition={this.props.handleNodePosition}
                      showModal={this.state.showModal}
                      handleCloseModal={this.handleCloseModal}
                      handleOpenModal={this.handleOpenModal}
                      clearIndex={this.clearIndex}
                      modalNode={this.state.modalNode}
                      handlePermissionsNodeView={this.props.handlePermissionsNodeView}
                      viewNodeObj={this.props.viewNodeObj}
                      editNodeCancel={this.props.editNodeCancel}
                      handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                      handleTimelineHistory={this.props.handleTimelineHistory}

                    />
                    <div className="leftTimelineRootNodeConnectorCircle" />
                    <div className="leftTimelineRootNodeConnectorDotLine" />
                    <div className="leftTimelineRootNodeConnectorDotLine2" />
                  </div>);
               } else {
                return (<div key={this.state.indexLeft} />);
               }
            })}
            {(this.state.indexLeft % 2 === 0) ?
              <div>
                {this.renderNewNodeContainer(this.props.uploadToggle === 'Hidden')}
                <div className="leftTimelineRootNodeConnectorCircle" />
                <div className="leftTimelineRootNodeConnectorDotLine" />
                <div className="leftTimelineRootNodeConnectorDotLine2" />
              </div> :
              ''
            }
          </div>
          <div id="timelineNodesRight">

            {nodes.map((node, index) => {
              this.state.indexRight += 1;

              if (this.state.indexRight % 2 === 0) {
                return (
                  <div key={this.state.indexRight}>
                    <NodeContainer
                      node={node}
                      position="right"
                      handleRemoveClick={this.props.handleRemoveClick}
                      handleEditClick={this.props.handleEditClick}
                      timelineNode={this.props.timelineNode}
                      viewNode={this.props.viewNode}
                      page={this.props.page}
                      uploadToggle={this.props.uploadToggle}
                      query={this.props.query}
                      queryType={this.props.queryType}
                      userId={this.props.userId}
                      contentID={this.props.contentID}
                      handleIsFirstMount={this.props.handleIsFirstMount}
                      index={index}
                      handleNodePosition={this.props.handleNodePosition}
                      showModal={this.state.showModal}
                      handleCloseModal={this.handleCloseModal}
                      handleOpenModal={this.handleOpenModal}
                      clearIndex={this.clearIndex}
                      modalNode={this.state.modalNode}
                      handlePermissionsNodeView={this.props.handlePermissionsNodeView}
                      viewNodeObj={this.props.viewNodeObj}
                      editNodeCancel={this.props.editNodeCancel}
                      handleTimelineHistoryIndex={this.props.handleTimelineHistoryIndex}
                      handleTimelineHistory={this.props.handleTimelineHistory}
                    />
                    <div className="rightTimelineRootNodeConnectorCircle" />
                    <div className="rightTimelineRootNodeConnectorDotLine" />
                    <div className="rightTimelineRootNodeConnectorDotLine2" />

                  </div>);
               } else {
               return (<div key={this.state.indexRight} />);
              }
            })}

            {(this.state.indexRight % 2 !== 0) ?
              <div>
                {this.renderNewNodeContainer(this.props.uploadToggle === 'Hidden')}
                <div className="rightTimelineRootNodeConnectorCircle" />
                <div className="rightTimelineRootNodeConnectorDotLine" />
                <div className="rightTimelineRootNodeConnectorDotLine2" />
              </div>
              :
              ''
            }

          </div>

        </div>
      );
    }
  }

  renderEmptyContributorModeratorList(queryType, userBool, userObj) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (userBool && userObj.creatorKeys !== undefined) {
      user = userObj;
    }
    let empty = false;
    let type = '';
    if (queryType === 1) {
      if (user.creatorKeys.length === 0) {
        empty = true;
        type = 'Creator';
      }
    } else if (queryType === 2) {
      if (user.permissionAdminKeys.length === 0) {
        empty = true;
        type = 'Moderator';
      }
    } else if (queryType === 3) {
      if (user.permissionEditKeys.length === 0) {
        empty = true;
        type = 'Contributor';
      }
    }

    return (
      empty ?
        <div id="timelineNodesAlternateContainer">
          <div className="line-containerQueryNodeView">
            <div className="lineQueryNodeView" />
            <div className="lineQueryNodeView" />
            <div className="lineQueryNodeView" />
            <div className="lineQueryNodeView" />
            <div className="lineQueryNodeView" />
            <div className="lineQueryNodeView" />
            <div className="lineQueryNodeView" />
          </div>
          <div id="timelineNodesAlternateContainerHeader"> {userBool ? `Currently, this user not have any ${type} timelines` : `Currently, you do not have any ${type} timelines` } </div>
          {userBool ? '' :
          type === 'Creator' ?
            <div className="onBoardUploadContainer" >
              <p>Create new timelines here!</p>
              <button
                onClick={(e) => { this.props.createNodeFromProfileTimeline('Timeline'); }}
                className="onBoardUploadButton"
              >
                Upload | Create
              </button>
            </div>
             : `Here, you will see all the timelines that other users have granted you ${type} permission.`
        }
        </div>
        : ''

    );
  }

  renderEmptyNodeList(queryType) {
    return (
      <div id="timelineNodesAlternateContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        {queryType === 'Feed' ?
          <div className="onBoardUploadContainer">
            <div id="timelineNodesAlternateContainerHeader"> Currently, you are not subscribed to any timelines. </div>
            <p>Explore new timelines here!</p>
            <NavLink to="/Explore" className="onBoardUploadButton" > Explore </NavLink>
          </div>
         :
          <div className="onBoardUploadContainer">
            <div id="timelineNodesAlternateContainerHeader"> Empty Timeline </div>
          This timeline does not have any content right now.
            {this.state.contributorAccess ?
              <div className="onBoardCreateButtonContainer">
                <button
                  onClick={(e) => { this.props.createNodeFromNodePageTimeline('Timeline'); }}
                  className="onBoardCreateButton"
                >
                 Create Sub-Timeline
                </button>
                <button
                  onClick={(e) => { this.props.createNodeFromNodePageTimeline('Content'); }}
                  className="onBoardCreateButton"
                >
                Add Photos, Links, Text
                </button>
              </div>
            : ''
          }
          </div>

    }
      </div>

    );
  }


  renderAccessDenied() {
    console.log(this.state.access);
    return (
      <div id="timelineNodesAlternateContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        <div id="timelineNodesAlternateContainerHeader"> Permissions View Denied </div>
        Currently, you do not have permission to view this timeline. You can request access by clicking Request Permissions on the right.
      </div>
    );
  }
  renderTimelineDescription() {
    if (this.props.timelineViewBox === 'uploadTimelineViewbox') {
      return (
        <div className="timelineDescription">
          <p className="timelineDescriptionHeader">
        Description
          </p>
          <div className="timelineDescriptionContent">

            <p>{this.props.timelineNode.desc}</p>

          </div>
        </div>
      );
    }
    return (<div />);
  }

  render() {
    return (

      <div className={this.props.timelineViewBox}>
        <div className="timelineRoot">
          <div className="timelineLineRectangle" />
          <div className={this.props.timelineLine} />
          <div className="timelineLineRectangle" />
        </div>
        <div className="triangleDown" />
        {this.renderTimelineHeader()}
        {this.state.access ?
          this.props.timelineNode.nodes !== undefined && this.props.timelineNode.nodes.length === 0 && this.props.uploadToggle === 'Hidden' ? this.renderEmptyNodeList(this.props.queryType) : this.renderTimelineNodes()

        :
          this.renderAccessDenied()
        }
        {this.props.auth && (this.props.queryType === 'Profile' || (this.props.uploadToggle !== 'Hidden' && this.props.queryContributorModeratorType !== 1)) ? this.renderEmptyContributorModeratorList(this.props.queryContributorModeratorType) : ''}
        {this.props.auth && (this.props.queryType === 'user') ? this.renderEmptyContributorModeratorList(this.props.queryContributorModeratorType, true, this.props.user) : ''}
      </div>
    );
  }
}
// {this.renderTimelineDescription()}

// <div className="timelineFooterContainer">
//   <div className="timelineLineFooterRectangle" />
//   <div className="triangleUp" />
// </div>


export default TimelineView;
