import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signupUser, signinUser } from '../../actions';

import '../../styles/style.scss';


class landingExploreNodeView extends Component {
  constructor(props) {
    super(props);


    this.state = {
    };

    this.renderLandingNodeViewContent = this.renderLandingNodeViewContent.bind(this);
  }


  renderLandingNodeViewContent() {
    console.log(this.state);
    return (
      <div id="landingExploreNodeView">
        <div className="contentQueryNodeView">
          <div id="landingExploreNodeViewHeaderContainer" className="feedNodeViewHeader">
            <div id="landingExploreNodeViewHeaderDetail">06</div>
            <div id="landingExploreNodeViewHeader">Explore</div>

          </div>

          <div id="landingExploreNodeViewInfoContainer">
            <div id="landingExploreNodeViewInfoContent">
              <div id="headerText">
The goal of the platform is to accumulate a continuous and diverse set of stories. Record Atlas will
become a large lattice of mental models that individuals can navigate, explore and also add to.
The platform will become a public knowledge project that shares the stories from different people around the world.

It is a tool for personal documentation designed to be flexible for different use cases.

The fundamental value of the timeline module is organize knowledge by chronology and by topic.

              </div>
            </div>
          </div>
        </div>
        <div id="landingExploreNodeViewImageContainer">

          <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>

        </div>

      </div>
    );
  }

  render() {
    return (
      <div className="landingNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
          <div className="lineQueryNodeViewDark" />
        </div>
        { this.renderLandingNodeViewContent()}
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth.authenticated,
  }

);

export default withRouter(connect(mapStateToProps, { signupUser, signinUser })(landingExploreNodeView));
