import React from 'react';
import '../../styles/style.scss';


class FeedNodeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    };
    this.renderUserInformation = this.renderUserInformation.bind(this);
  }

  renderUserInformation() {
    console.log(this.state);
    const user = JSON.parse(localStorage.getItem('user'));
    if (user !== undefined && user !== null) {
      return (
        <div id="feedQueryNodeView">
          <div className="contentQueryNodeView">
            <div id="queryNodeViewHeaderContainer" className="feedNodeViewHeader">
              <div id="queryNodeViewHeaderDetail">02</div>
              <div id="queryNodeViewHeader" >Feed</div>

            </div>
            <div id="feedNodeViewInfoContainer">
              <div id="feedNodeViewInfoContent">
                <div id="headerText">
                A collection of all the stories you follow.
                </div>

              </div>

            </div>
          </div>
          <div id="feedNodeViewImageContainer">

            <div id="FeedRectOne"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
            <div id="FeedRectTwo"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
            <div id="FeedRectThree"><img alt="gin-rect" src="/public/images/ginRectangle.png" /></div>
            <div id="text">
              <div>Following {user.permissionViewKeys.length} {user.permissionViewKeys.length === 1 ? 'Timeline' : 'Timelines'}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  render() {
    return (
      <div className="QueryNodeViewContainer">
        <div className="line-containerQueryNodeView">
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
          <div className="lineQueryNodeView" />
        </div>
        { this.renderUserInformation()}
      </div>
    );
  }
}

export default FeedNodeView;
